import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Banners = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <section className='banners-content'>
        {/* //banner twelve */}
        <div className='banner-twelve'>
          <div className='container-fluid'>
            <div className='group-banners'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-lg-3'>
                  <figure className='banner-image' style={{ zIndex: 0 }}>
                    <div className='captions'>
                      <Link to='/shop-page1'>
                        <img
                          className='img-fluid'
                          src='assets/images/banners/banner_2.jpg'
                          alt='Banner'
                        />
                        <div
                          className='banner-caption left-caption'
                          style={{ zIndex: 1 }}
                        >
                          <h2>
                            {t('Vibrant Earrings.1')}
                            <small>{t('Great Deals.1')}</small>
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </figure>
                  <figure
                    className='banner-image mt-image'
                    style={{ zIndex: 0 }}
                  >
                    <div className='captions'>
                      <Link to='/shop-page1'>
                        <img
                          className='img-fluid'
                          src='assets/images/banners/banner_5.jpg'
                          alt='Banner'
                        />
                        <div
                          className='banner-caption left-caption'
                          style={{ zIndex: 1 }}
                        >
                          <h2>
                            {t('Jewelry Gifts.1')}
                            <small>{t('Best Match.1')}</small>
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </figure>
                </div>
                <div className='col-12 col-sm-12 col-lg-6'>
                  <figure className='banner-image' style={{ zIndex: 0 }}>
                    <div className='captions'>
                      <img
                        className='img-fluid '
                        src='assets/images/banners/banner_1.jpg'
                        alt='Banner'
                      />
                      <div
                        className='banner-caption left-caption'
                        style={{ zIndex: 1 }}
                      >
                        <h2>
                          {t('Latest Designs of Pendant.1')}
                          <small>{t('Designer Jewelry.1')}</small>
                        </h2>
                        <Link
                          to='/shop-page1'
                          className='btn btn-secondary swipe-to-top'
                        >
                          {t('Shop Now.1')}
                        </Link>
                      </div>
                    </div>
                  </figure>
                </div>
                <div className='col-12 col-sm-12 col-lg-3'>
                  <figure className='banner-image' style={{ zIndex: 0 }}>
                    <div className='captions'>
                      <Link to='/shop-page1'>
                        <img
                          className='img-fluid'
                          src='assets/images/banners/banner_3.jpg'
                          alt='Banner'
                        />
                        <div
                          className='banner-caption left-caption'
                          style={{ zIndex: 1 }}
                        >
                          <h2>
                            {t('Band Collection.1')}
                            <small>{t('With Pearls.1')}</small>
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </figure>
                  <figure
                    className='banner-image mb0-image mt-image'
                    style={{ zIndex: 0 }}
                  >
                    <div className='captions'>
                      <Link to='/shop-page1'>
                        <img
                          className='img-fluid'
                          src='assets/images/banners/banner_4.jpg'
                          alt='Banner'
                        />
                        <div
                          className='banner-caption left-caption'
                          style={{ zIndex: 1 }}
                        >
                          <h2>
                            {t('Diamond Rings.1')}
                            <small>{t('A Precious Feeling.1')}</small>
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Banners;
