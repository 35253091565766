import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AllProduct_Home2 } from '../../../data_api/products';
import { useSelector, useDispatch } from 'react-redux';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart
} from '../../../actions/cart';
import { useAlert } from 'react-alert';
import Slider from 'react-slick';

const Special_Offer = props => {
  const { t } = useTranslation();

  // for show alert
  const alert = useAlert();
  // for dispatch action
  const dispatch = useDispatch();
  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);

  // get all product from redux store
  const products = useSelector(state => state.cart.products);
  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      dispatch(
        increaseItemQuantity(productIndex, product, (itemQty = itemQty + 1))
      );
    } else {
      dispatch(addItemToCart(product));
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    dispatch(addToCart());
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,

    arrows: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Fragment>
      <section className='pro-content pro-tr-content'>
        <div className='container'>
          <div className='products-area'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-12'>
                <div className='pro-heading-title'>
                  <h2>{t('Special Offers.1')}</h2>
                  <p>
                    {t(
                      'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 p-0'>
                <Slider className='carousel' {...settings}>
                  {AllProduct_Home2.product_data !== undefined &&
                  AllProduct_Home2.product_data.length > 0
                    ? AllProduct_Home2.product_data.map((product, index) => {
                        return (
                          <div className='slide' key={index}>
                            <div className='product aos-init aos-animate'>
                              <article>
                                <div className='pro-thumb '>
                                  <Link to='/product-page1'>
                                    <span className='pro-image'>
                                      <img
                                        className='img-fluid'
                                        src={product.products_image}
                                        alt='Product'
                                      />
                                    </span>
                                    <span className='pro-image-hover swipe-to-top'>
                                      <img
                                        className='img-fluid'
                                        src={product.images[0]}
                                        alt='Product'
                                      />
                                    </span>
                                  </Link>
                                  <div className='countdown pro-timer'>
                                    <span className='days' />
                                    <span className='hours' />
                                    <span className='mintues' />
                                    <span className='seconds' />
                                  </div>
                                </div>
                                <div className='pro-description'>
                                  <span className='pro-info'>
                                    {t(
                                      `${product.categories[0].categories_name}.1`
                                    )}
                                  </span>
                                  <h2 className='pro-title'>
                                    <Link to='/product-page1'>
                                      {t(`${product.products_name}.1`)}
                                    </Link>
                                  </h2>
                                  <div className='pro-btns'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary swipe-to-top'
                                      onClick={() =>
                                        addAndUpdatenTheCart(product)
                                      }
                                    >
                                      {t('Add to Cart.1')}
                                    </button>
                                    <div className='pro-price'>
                                      <ins>
                                        {props.currencyDetails.symbolLeft}
                                        {(
                                          product.products_price *
                                          props.currencyDetails.value
                                        ).toFixed(2)}
                                      </ins>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>
                        );
                      })
                    : 'null'}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Special_Offer;
