import React, { Fragment, useEffect, useState } from 'react';
import MainHeaderTwo from '../../../layouts/header/main-header/header-two';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MainSlider from '../../../components/sliders/main_slider';
import Services from '../../../components/services';
import Products from '../../../components/products/home2_products';
import FullScreenbanner from '../../../components/products/fullScreen_banner';
import SpecialOffer from '../../../components/sliders/specialOffer_slider';
import PapularCategoriesSlider from '../../../components/sliders/papular_categories_slider';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import Footer2 from '../../../layouts/footer/footer-two';
import ProductModel from '../../../components/products/product_model';
import { currency } from '../../../components/utils/currency_array';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { sliderTwo } from '../../../data_api/slider';
import { PATH_2, PATH_3 } from '../../../data_api/fullscreen-banners';

const Home_2 = () => {
  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //header style Two */}
      <MainHeaderTwo
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Revolution Layer Slider */}
      <MainSlider slider={sliderTwo} />
      {/* servies component */}
      <Services />
      {/* Popular Products */}
      <Products currencyDetails={currencyDetail} />
      {/* full screen banner offer */}
      <FullScreenbanner image={PATH_2} />
      {/* Special Offer */}
      <SpecialOffer currencyDetails={currencyDetail} />
      {/* full screen banner offer */}
      <FullScreenbanner image={PATH_3} />
      {/* Categories */}
      <PapularCategoriesSlider currencyDetails={currencyDetail} />
      {/* Product Modal */}
      <ProductModel currencyDetails={currencyDetail} />
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //Footer Style One */}
      <Footer2 />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Home_2;
