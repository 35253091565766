export const sliderOne = {
  text1: 'Frenzi Sale on Jewelry',
  text2: 'Every Big Girl Needs',
  text3: 'Attractive Jewelry',
  text4: 'Make your dreams true',
  text5: 'Be Vibrant with',
  text6: 'Matchless Jewelry',
  textShopNow: 'Shop Now',
  text7: 'Wedding season ornaments',
  text8: 'Choose The Best',
  text9: 'Jewelry Collection',
  image1: 'assets/images/slick_carousel_demo/Slider_01_01.jpg',
  image2: 'assets/images/slick_carousel_demo/Slider_01_02.jpg',
  image3: 'assets/images/slick_carousel_demo/Slider_01_03.jpg'
};

export const sliderTwo = {
  text1: 'LED-backlit Display',
  text2: 'Update Yourself by',
  text3: 'Latest Technology',
  text4: 'Fine weather all year round',
  text5: 'Rectify Your Feeling',
  text6: 'With Surroundings',
  textShopNow: 'Shop Now',
  text7: 'Meet New Generation Phones',
  text8: 'See Through A Huge',
  text9: 'Mobile Collection',
  image1: 'assets/images/slick_carousel_demo_2/Slider_01_01.jpg',
  image2: 'assets/images/slick_carousel_demo_2/Slider_01_02.jpg',
  image3: 'assets/images/slick_carousel_demo_2/Slider_01_03.jpg'
};

export const sliderThree = {
  text1: 'Collection of Glasses',
  text2: 'Have A Touch Of',
  text3: 'Class In Your Beauty',
  text4: 'A Heap Of Fashion Apparels',
  text5: "Women's Dresses",
  text6: 'The Ones You Love',
  textShopNow: 'Shop Now',
  text7: 'From The House Of Brands',
  text8: 'Check New Arrival',
  text9: 'Of Jeans Pent',
  image1: 'assets/images/slick_carousel_demo_3/Slider_01_01.jpg',
  image2: 'assets/images/slick_carousel_demo_3/Slider_01_02.jpg',
  image3: 'assets/images/slick_carousel_demo_3/Slider_01_03.jpg'
};
