import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  removeItemFromCart
} from '../../../actions/cart';
import {
  ModalProductdecreaseItemQuantity,
  ModalProductincreaseItemQuantity
} from '../../../actions/modalProduct';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Product_Model = props => {
  const { t } = useTranslation();

  const product = useSelector(state => state.cart.ModalProducts);

  // for show alert
  const alert = useAlert();

  // get cart product from the store
  const cartProduct = useSelector(state => state.cart.products);

  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    console.log('itemQty is', itemQty);
    cartProduct.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      props.increaseItemQuantity(productIndex, product, itemQty + 1);
      props.ModalProductincreaseItemQuantity(
        productIndex,
        product,
        itemQty + 1
      );
    } else {
      props.addItemToCart(product);
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage
    props.addToCart();
  };
  const [formData, setFormData] = useState({
    quantity: 0,
    products: product,
    cart: [],
    subTotal: 0
  });

  const increaseQty = (index, product, quantity) => {
    // first remove product from cart then update
    props.removeItemFromCart(index, product);

    props.ModalProductincreaseItemQuantity(index, product, quantity);
  };

  const decreaseQty = (index, product, quantity) => {
    // first remove product from cart then update
    props.removeItemFromCart(index, product);

    props.ModalProductdecreaseItemQuantity(index, product, quantity);
  };

  return (
    <Fragment>
      <div
        className='modal fade'
        id='quickViewModal'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='container'>
                {product !== undefined || product.length > 0
                  ? product.map((product, index) => (
                      <div className='row align-items-center' key={index}>
                        <div className='col-12 col-md-6'>
                          <div className='row '>
                            <div
                              id='quickViewCarousel'
                              className='carousel slide'
                              data-ride='carousel'
                            >
                              {/* The slideshow */}
                              <div className='carousel-inner'>
                                <div className='carousel-item active'>
                                  <img
                                    className='img-fluid'
                                    src={product.products_image}
                                    alt='product'
                                  />
                                </div>
                                <div className='carousel-item'>
                                  <img
                                    className='img-fluid'
                                    src={product.images}
                                    alt='product'
                                  />
                                </div>
                              </div>
                              {/* Left and right controls */}
                              <a
                                className='carousel-control-prev'
                                href='#quickViewCarousel'
                                data-slide='prev'
                              >
                                <span className='fas fa-angle-left ' />
                              </a>
                              <a
                                className='carousel-control-next'
                                href='#quickViewCarousel'
                                data-slide='next'
                              >
                                <span className='fas fa-angle-right ' />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='pro-description'>
                            <h2 className='pro-title'>
                              {t(`${product.products_name}.1`)}
                            </h2>
                            <div className='pro-price'>
                              <del>
                                {props.currencyDetails.symbolLeft}
                                {(500 * props.currencyDetails.value).toFixed(2)}
                              </del>
                              <ins>
                                {props.currencyDetails.symbolLeft}
                                {(
                                  product.products_price *
                                  props.currencyDetails.value
                                ).toFixed(2)}
                              </ins>
                            </div>
                            <div className='pro-infos'>
                              <div className='pro-single-info'>
                                <b>{t('Product ID.1')} :</b>
                                {product.products_id}
                              </div>
                              <div className='pro-single-info'>
                                <b>{t('Categroy.1')} :</b>
                                <Link to={`${process.env.PUBLIC_URL}/#`}>
                                  {t('Ring.1')}
                                </Link>
                              </div>
                              <div className='pro-single-info'>
                                <b>{t('Tags.1')} :</b>
                                <ul>
                                  <li>
                                    <Link to={`${process.env.PUBLIC_URL}/#`}>
                                      {t('bracelets.1')}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`${process.env.PUBLIC_URL}/#`}>
                                      {t('diamond.1')}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`${process.env.PUBLIC_URL}/#`}>
                                      {t('ring.1')}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className='pro-single-info'>
                                <b>{t('Available.1')} :</b>
                                <span className='text-secondary'>
                                  {t('InStock.1')}
                                </span>
                              </div>
                            </div>
                            <p>
                              {t(
                                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.1'
                              )}
                            </p>
                            <div className='pro-counter'>
                              <div className='input-group item-quantity'>
                                <input
                                  type='text'
                                  id='quantity1'
                                  name='quantity'
                                  className='form-control '
                                  value={product.quantity}
                                  disabled
                                />
                                <span className='input-group-btn'>
                                  <button
                                    type='button'
                                    className='quantity-plus btn'
                                    data-type='plus'
                                    data-field
                                    onClick={() =>
                                      increaseQty(
                                        index,
                                        product,
                                        product.quantity + 1
                                      )
                                    }
                                  >
                                    <i className='fas fa-plus' />
                                  </button>
                                  {product.quantity > 1 ? (
                                    <button
                                      type='button'
                                      value='quantity1'
                                      className='quantity-minus btn'
                                      data-type='minus'
                                      data-field
                                      onClick={() =>
                                        decreaseQty(
                                          index,
                                          product,
                                          product.quantity - 1
                                        )
                                      }
                                    >
                                      <i className='fas fa-minus' />
                                    </button>
                                  ) : (
                                    <button
                                      type='button'
                                      value='quantity1'
                                      className='quantity-minus btn'
                                      data-type='minus'
                                      disabled
                                    >
                                      <i className='fas fa-minus' />
                                    </button>
                                  )}
                                </span>
                              </div>
                              <button
                                type='button'
                                className='btn btn-secondary btn-lg swipe-to-top'
                                onClick={() => addProductToCart(product)}
                              >
                                {t('Add to Cart.1')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : 'null'}
              </div>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Product_Model.propTypes = {
  ModalProductdecreaseItemQuantity: PropTypes.func.isRequired,
  ModalProductincreaseItemQuantity: PropTypes.func.isRequired,
  increaseItemQuantity: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired
};

export default connect(null, {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  ModalProductdecreaseItemQuantity,
  ModalProductincreaseItemQuantity,
  removeItemFromCart
})(Product_Model);
