import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const Main_Slider = props => {
  const { t } = useTranslation();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='carousel Modern-Slider'>
      <div className='slide item'>
        <div className='img-fill'>
          <div
            className='info'
            style={{ backgroundImage: `url(${props.slider.image1})` }}
          >
            <div className='container'>
              <div className='info_left'>
                <h5>{t(`${props.slider.text1}.1`)}</h5>
                <h2>
                  {t(`${props.slider.text2}.1`)}
                  <br />
                  {t(`${props.slider.text3}.1`)}
                </h2>
                <button className='btn btn-secondary swipe-to-top'>
                  {t(`${props.slider.textShopNow}.1`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='slide item'>
        <div className='img-fill'>
          <div
            className='info'
            style={{ backgroundImage: `url(${props.slider.image2})` }}
          >
            <div className='container'>
              <div className='info_center'>
                <h5>{t(`${props.slider.text4}.1`)}</h5>
                <h2>
                  {t(`${props.slider.text5}.1`)} <br />
                  {t(`${props.slider.text6}.1`)}
                </h2>
                <button className='btn btn-secondary swipe-to-top'>
                  {t(`${props.slider.textShopNow}.1`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='slide item'>
        <div className='img-fill'>
          <div
            className='info'
            style={{ backgroundImage: `url(${props.slider.image3})` }}
          >
            <div className='container'>
              <div className='info_right'>
                <h5>{t(`${props.slider.text7}.1`)}</h5>
                <h2>
                  {t(`${props.slider.text8}.1`)} <br />
                  {t(`${props.slider.text9}.1`)}
                </h2>
                <button className='btn btn-secondary swipe-to-top'>
                  {t(`${props.slider.textShopNow}.1`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Main_Slider;
