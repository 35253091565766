import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Routes from './routing/Routes';
import './assets/scss/style.scss';
import { Provider } from 'react-redux';
import store from './store';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          {/* <Layout> */}

          <Route component={Routes} />

          {/* </Layout> */}
        </Router>
      </Provider>
    );
  }
}
export default App;
