import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { allcategories, MensProducts, WomenProducts, KidsProduct } from '../../../data_api/products';
import { useSelector, useDispatch } from 'react-redux';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts
} from '../../../actions/cart';
import { addItemToModal } from '../../../actions/modalProduct';
import Slider from 'react-slick';
import { useAlert } from 'react-alert';
const BestProductSlider = props => {
  
  const { t } = useTranslation();

  // for use dispatach action from store
  const dispatch = useDispatch();

  // for show alert
  const alert = useAlert();
  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);

  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);
  // get all product from redux store
  const products = useSelector(state => state.cart.products);
  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      dispatch(
        increaseItemQuantity(productIndex, product, (itemQty = itemQty + 1))
      );
    } else {
      dispatch(addItemToCart(product));
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage
     dispatch(addToCart());
  };




  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      dispatch(AddWishlistProducts(product));

      alert.success('Successfully added to Wishlist!');
    }
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }

  };

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    console.log('already product exist', alreadyModalProduct);

    if (productExists) {
      dispatch(addItemToModal(alreadyModalProduct));
    } else {
      dispatch(addItemToModal(product));
    }

  };

  // setting of slick carouser
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <section className="pro-content pro-tab-content ">
        <div className="container">
          <div className="products-area">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-12">
                <div className="pro-heading-title">
                  <h1> {t('Best Products.1')}
                  </h1>
                </div>
              </div>
            </div>
            {/* ..........tabs start ......... */}
            <div className="row ">
              <div className="col-md-12 p-0">
                <div className="nav" role="tablist" id="tabCarousel">
                  <a className="nav-link  active" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">{t('All Categories.1')}</a>
                  <a className="nav-link" data-toggle="tab" href="#men" role="tab" aria-controls="men" aria-selected="true">{t('Men Fashion.1')}</a>
                  <a className="nav-link" data-toggle="tab" href="#women" role="tab" aria-controls="women" aria-selected="true">{t('Women Fashion.1')}</a>
                  <a className="nav-link" data-toggle="tab" href="#kids" role="tab" aria-controls="kids" aria-selected="true">{t('Kids.1')}</a>
                </div>
                {/* Tab panes */}
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane fade active show" id="all">
                  
                  <Slider className='carousel' {...settings}>
                      {allcategories.product_data !== undefined &&
                        allcategories.product_data.length > 0
                        ? allcategories.product_data.map((product, index) => {
                          return (
                         <div className='slide' key={index}>  
                              <div className="product">
                                <article>
                                  <div className="pro-icons mobile-pro-icons d-lg-none d-xl-none">
                                    <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                      <i className="fas fa-heart" />
                                    </div>
                                    <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                      ShowProductInModal(product)
                                    }
                                    >
                                      <i className="fas fa-eye" />
                                    </div>
                                    <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                  </div>
                                  <div className="pro-thumb ">
                                    <Link to="/product-page1">
                                      <span className="pro-image"><img className="img-fluid" src={product.products_image} alt="Product" /></span>
                                      <span className="pro-image-hover swipe-to-top"><img className="img-fluid" src={product.images[0]} alt="Product" /></span>
                                    </Link>
                                    <div className="pro-buttons d-none d-lg-block d-xl-block">
                                      <div className="pro-icons">
                                        <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                          <i className="fas fa-heart" />
                                        </div>
                                        <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                          ShowProductInModal(product)
                                        }
                                        >
                                          <i className="fas fa-eye" />
                                        </div>
                                        <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                      </div>
                                      <button type="button" className="btn btn-secondary btn-block swipe-to-top" onClick={() => addAndUpdatenTheCart(product)}>{t('Add to Cart.1')}</button>
                                    </div>
                                    <div className="pro-tag bg-success">{t('NEW.1')}</div>
                                  </div>
                                  <div className="pro-description">
                                    <span className="pro-info">
                                      {t(`${product.categories[0].categories_name}.1`)}
                                    </span>
                                    <h2 className="pro-title"><Link to="/product-page1">{t(`${product.products_name}.1`)}</Link></h2>
                                    <div className="pro-price">
                                      <ins> {props.currencyDetails.symbolLeft}
                                        {(
                                          product.products_price *
                                          props.currencyDetails.value
                                        ).toFixed(2)}</ins>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                        : 'null'}
 
            </Slider>

                    {/* 1st tab */}
                  </div>
                  <div role="tabpanel" className="tab-pane fade" id="men">
                    <Slider className='carousel' {...settings}>
                      {MensProducts.product_data !== undefined &&
                        MensProducts.product_data.length > 0
                        ? MensProducts.product_data.map((product, index) => {
                          return (
                         <div className='slide' key={index}>  
                              <div className="product">
                                <article>
                                  <div className="pro-icons mobile-pro-icons d-lg-none d-xl-none">
                                    <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                      <i className="fas fa-heart" />
                                    </div>
                                    <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                      ShowProductInModal(product)
                                    }
                                    >
                                      <i className="fas fa-eye" />
                                    </div>
                                    <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                  </div>
                                  <div className="pro-thumb">
                                    <Link to="/product-page1">
                                      <span className="pro-image"><img className="img-fluid" src={product.products_image} alt="Product" /></span>
                                      <span className="pro-image-hover swipe-to-top"><img className="img-fluid" src={product.images[0]} alt="Product" /></span>
                                    </Link>
                                    <div className="pro-buttons d-none d-lg-block d-xl-block">
                                      <div className="pro-icons">
                                        <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                          <i className="fas fa-heart" />
                                        </div>
                                        <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                          ShowProductInModal(product)
                                        }
                                        >
                                          <i className="fas fa-eye" />
                                        </div>
                                        <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                      </div>
                                      <button type="button" className="btn btn-secondary btn-block swipe-to-top" onClick={() => addAndUpdatenTheCart(product)}>{t('Add to Cart.1')} </button>
                                    </div>
                                    <div className="pro-tag bg-primary">{t('Featured.1')}</div>
                                  </div>
                                  <div className="pro-description">
                                    <span className="pro-info">
                                      {t(`${product.categories[0].categories_name}.1`)}
                                    </span>
                                    <h2 className="pro-title"><Link to="/product-page1">{t(`${product.products_name}.1`)}</Link></h2>
                                    <div className="pro-price">
                                      <ins> {props.currencyDetails.symbolLeft}
                                        {(
                                          product.products_price *
                                          props.currencyDetails.value
                                        ).toFixed(2)}</ins>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                        : 'null'}
                    </Slider>
                    {/* 2nd tab */}
                  </div>
                  <div role="tabpanel" className="tab-pane fade" id="women">
                  <Slider className='carousel' {...settings}>
                      {WomenProducts.product_data !== undefined &&
                        WomenProducts.product_data.length > 0
                        ? WomenProducts.product_data.map((product, index) => {
                          return (
                            <div className='slide' key={index}>  

                            {/* // <div className="col-12 col-md-4 col-lg-3" key={index}> */}
                              <div className="product">
                                <article>
                                  <div className="pro-icons mobile-pro-icons d-lg-none d-xl-none">
                                    <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                      <i className="fas fa-heart" />
                                    </div>
                                    <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                      ShowProductInModal(product)
                                    }
                                    >
                                      <i className="fas fa-eye" />
                                    </div>
                                    <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                  </div>
                                  <div className="pro-thumb ">
                                    <Link to="/product-page1">
                                      <span className="pro-image"><img className="img-fluid" src={product.products_image} alt="Product" /></span>
                                      <span className="pro-image-hover swipe-to-top"><img className="img-fluid" src={product.images[0]} alt="Product" /></span>
                                    </Link>
                                    <div className="pro-buttons d-none d-lg-block d-xl-block">
                                      <div className="pro-icons">
                                        <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                          <i className="fas fa-heart" />
                                        </div>
                                        <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                          ShowProductInModal(product)
                                        }
                                        >
                                          <i className="fas fa-eye" />
                                        </div>
                                        <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                      </div>
                                      <button type="button" className="btn btn-secondary btn-block swipe-to-top" onClick={() => addAndUpdatenTheCart(product)}>{t('Add to Cart.1')}</button>
                                    </div>
                                    <div className="pro-tag">{t('Sale.1')}</div>
                                  </div>
                                  <div className="pro-description">
                                    <span className="pro-info">
                                      {t(`${product.categories[0].categories_name}.1`)}
                                    </span>
                                    <h2 className="pro-title"><Link to="/product-page1">{t(`${product.products_name}.1`)}</Link></h2>
                                    <div className="pro-price">
                                      <del> {props.currencyDetails.symbolLeft}
                                        {(
                                          420 *
                                          props.currencyDetails.value
                                        ).toFixed(2)}</del><ins> {props.currencyDetails.symbolLeft}
                                        {(
                                          product.products_price *
                                          props.currencyDetails.value
                                        ).toFixed(2)}</ins>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                        : 'null'}
                    </Slider>
                    {/* </div> */}
                    {/* 3rd tab */}
                  </div>
                  <div role="tabpanel" className="tab-pane fade" id="kids">
                  <Slider className='carousel' {...settings}>
                      {KidsProduct.product_data !== undefined &&
                        KidsProduct.product_data.length > 0
                        ? KidsProduct.product_data.map((product, index) => {
                          return (
                            <div className='slide' key={index}>  
                              <div className="product">
                                <article>
                                  <div className="pro-icons mobile-pro-icons d-lg-none d-xl-none">
                                    <div onClick={() => AddToWishList(product)} className="icon active swipe-to-top">
                                      <i className="fas fa-heart" />
                                    </div>
                                    <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                      ShowProductInModal(product)
                                    }
                                    >
                                      <i className="fas fa-eye" />
                                    </div>
                                    <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                  </div>
                                  <div className="pro-thumb ">
                                    <Link to="/product-page1">
                                      <span className="pro-image"><img className="img-fluid" src={product.products_image} alt="Product" /></span>
                                      <span className="pro-image-hover swipe-to-top"><img className="img-fluid" src={product.images[0]} alt="Product" /></span>
                                    </Link>
                                    <div className="pro-buttons d-none d-lg-block d-xl-block">
                                      <div className="pro-icons">
                                        <div className="icon active swipe-to-top" onClick={() => AddToWishList(product)}>
                                          <i className="fas fa-heart" />
                                        </div>
                                        <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                          ShowProductInModal(product)
                                        }
                                        >
                                          <i className="fas fa-eye" />
                                        </div>
                                        <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                      </div>
                                      <button className="btn btn-secondary btn-block swipe-to-top" onClick={() => addAndUpdatenTheCart(product)}>{t('Add to Cart.1')}</button>
                                    </div>
                                  </div>
                                  <div className="pro-description">
                                    <span className="pro-info">
                                      {t(`${product.categories[0].categories_name}.1`)}
                                    </span>
                                    <h2 className="pro-title"><Link to="/product-page1">{t(`${product.products_name}.1`)}</Link></h2>
                                    <div className="pro-price">
                                      <ins>{props.currencyDetails.symbolLeft}
                                        {(
                                          product.products_price *
                                          props.currencyDetails.value
                                        ).toFixed(2)}</ins>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                        : 'null'}
                   </Slider>
                    {/* 4th tab */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  )
}

export default BestProductSlider
