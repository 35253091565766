import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OfferBar = () => {
  // for language excahnger
  const { t } = useTranslation();

  return (
    <div
      className='alert alert-warning alert-dismissible fade show'
      role='alert'
    >
      <div className='container'>
        <div className='pro-description'>
          <div className='pro-info'>
            {t('Get.1')}
            <strong> {t('UPTO 40% OFF.1')} </strong>
            {t('on your 1st order.1')}
            {'  '}
            <div className='pro-link-dropdown js-toppanel-link-dropdown'>
              <Link to='/shop-page1' className='pro-dropdown-toggle'>
                {t('More details.1')}
              </Link>
            </div>
          </div>
          <button
            type='button'
            className='close'
            data-dismiss='alert'
            aria-label='Close'
          >
            <span aria-hidden='true'>×</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferBar;
