import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../../layouts/header/main-header/header-one';
import { currency } from '../../components/utils/currency_array';
import StickyHeader from '../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../layouts/footer/mobile-footer';
import MainFooter from '../../layouts/footer/main-footer';
import ArrowBackToTop from '../../components/arrow-backToTop';
import Switcher from '../../components/switcher';
import NewsLetterModal from '../../components/newslatter-modal';
import Cookies from '../../components/cookies';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t} = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* Paste this code after body tag */}
      {/* <div className='se-pre-con' /> */}
      {/* //Header Style One */}
      <MainHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* About-us Content */}
      <section className='empty-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='pro-empty-page'>
                <h2>{t('Error 404.1')}...</h2>
                <h1>{t('Page Not Found.1')}</h1>
                <p>
                  {t('We looked everywhere for this page.1')}.{' '}
                  {t('Are you sure the website URL is correct? Go to the.1')}
                  <strong>
                    <Link to='/' className='btn-link'>
                      {t('main page.1')}
                    </Link>
                  </strong>
                  {t('or select suitable category.1')}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default NotFound;
