import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts,
  WishlistdecreaseItemQuantity,
  WishlistincreaseItemQuantity,
  removeItemFromWishlist,
  removeItemFromCart
} from '../../../actions/cart';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Wishlist = props => {
  const { t } = useTranslation();

  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);
  // get the lenght of woislist product
  const length = WishlistProducts.length;
  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  // for show alert
  const alert = useAlert();

  // get all product from redux store
  const products = useSelector(state => state.cart.Wishlist_Products);
  // get cart product from the store
  const cartProduct = useSelector(state => state.cart.products);

  console.log('detailed products is', products);
  // add to cart function

  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let CartproductIndex = -1;
    let WishlistproductIndex = -1;
    // check the product exist in cart
    cartProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        CartproductIndex = idx;
      }
    });
    // check the product exist in wishlist and assign index of product
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        WishlistproductIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      console.log('add item of product cart is', product.quantity);
      props.increaseItemQuantity(CartproductIndex, product, itemQty + 1);
      props.WishlistincreaseItemQuantity(
        WishlistproductIndex,
        product,
        itemQty + 1
      );
    } else {
      props.addItemToCart(product);
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    props.addToCart();
  };

  const [formData, setFormData] = useState({
    quantity: 0,
    products: products,
    cart: [],
    subTotal: 0
  });

  const { quantity } = formData;
  const increaseQty = (index, product, quantity) => {
    // first remove product from cart then update
    props.removeItemFromCart(index, product);
    props.WishlistincreaseItemQuantity(index, product, quantity);
  };

  const decreaseQty = (index, product, quantity) => {
    // first remove product from cart then update
    props.removeItemFromCart(index, product);
    props.WishlistdecreaseItemQuantity(index, product, quantity);
  };
  // For delete product from wishlist
  const confirmDelete = (index, item) => {
    props.removeItemFromWishlist(index, item);
    props.addToCart();
    alert.error('Delected successfully!');
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    cartProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  return (
    <Fragment>
      {/* //Header Style One */}
      <MainHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />

      {length === 0 || length === undefined || length === null ? (
        <div class='container'>
          <div class='row'>
            <div class='col-12 empty-content'>
              <div class='pro-empty-page'>
                <h2 style={{ fontSize: '150px' }}>
                  <i class='fas fa-shopping-cart'></i>
                </h2>
                <h1>{t('Your Wishlist is empty.1')}</h1>
                <p>
                  <Link to='/shop-page1' class='btn btn-secondary'>
                    {t('Continue To Add.1')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          {/* wishlist Content */}
          <div className='container-fuild'>
            <nav aria-label='breadcrumb'>
              <div className='container'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='#'>{t('Home.1')}</Link>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    {t('Wishlist.1')}
                  </li>
                </ol>
              </div>
            </nav>
          </div>
          <section className='pro-content wishlist-content'>
            <div className='container'>
              <div className='row'>
                <div className='pro-heading-title'>
                  <h1>{t('Wishlist Page.1')}</h1>
                </div>
              </div>
              <div className='row'>
                <div className=' col-12 col-lg-3'>
                  <ul className='list-group'>
                    <li className='list-group-item'>
                      <Link className='nav-link' to='/profile'>
                        <i className='fas fa-user' />
                        {t('Profile.1')}
                      </Link>
                    </li>
                    <li className='list-group-item'>
                      <Link className='nav-link' to='/wishlist'>
                        <i className='fas fa-heart' />
                        {t('Wishlist.1')}
                      </Link>
                    </li>
                    <li className='list-group-item'>
                      <Link className='nav-link' to='/orders'>
                        <i className='fas fa-shopping-cart' />
                        {t('Orders.1')}
                      </Link>
                    </li>
                    <li className='list-group-item'>
                      <Link className='nav-link' to='/shipping-address'>
                        <i className='fas fa-map-marker-alt' />
                        {t('Shipping Address.1')}
                      </Link>
                    </li>
                    <li className='list-group-item'>
                      <Link className='nav-link' to='#'>
                        <i className='fas fa-power-off' />
                        {t('Logout.1')}
                      </Link>
                    </li>
                    <li className='list-group-item'>
                      <Link className='nav-link' to='/change-password'>
                        <i className='fas fa-unlock-alt' />
                        {t('Change Password.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='col-12 col-lg-9 '>
                  {WishlistProducts !== undefined && WishlistProducts.length > 0
                    ? WishlistProducts.map((product, index) => {
                        return (
                          <Fragment>
                            <div className='media' key={index}>
                              <div className='media-thumb'>
                                <img
                                  className='img-fluid'
                                  src={product.products_image}
                                  alt='Product'
                                />
                              </div>
                              <div className='media-body'>
                                <div className='row'>
                                  <div className='col-12 col-md-8'>
                                    <h2>
                                      <Link to='/product-page1'>
                                        {t(`${product.products_name}.1`)}
                                      </Link>
                                    </h2>
                                    <div className='pro-price'>
                                      <ins>${product.products_price}</ins>
                                    </div>
                                    <p className='d-none d-lg-block d-xl-block'>
                                      {t(
                                        'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                                      )}
                                    </p>
                                    <div className='buttons'>
                                      <div className='input-group item-quantity'>
                                        <input
                                          type='text'
                                          id='quantity1'
                                          name='quantity'
                                          className='form-control'
                                          value={product.quantity}
                                          disabled
                                        />
                                        <span className='input-group-btn'>
                                          <button
                                            type='button'
                                            value='quantity1'
                                            className='quantity-plus btn'
                                            data-type='plus'
                                            onClick={() =>
                                              increaseQty(
                                                index,
                                                product,
                                                product.quantity + 1
                                              )
                                            }
                                            data-field
                                          >
                                            <span className='fas fa-plus' />
                                          </button>
                                          {product.quantity > 1 ? (
                                            <button
                                              type='button'
                                              value='quantity1'
                                              className='quantity-minus btn'
                                              data-type='minus'
                                              data-field
                                              onClick={() =>
                                                decreaseQty(
                                                  index,
                                                  product,
                                                  product.quantity - 1
                                                )
                                              }
                                            >
                                              <span className='fas fa-minus' />
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              value='quantity1'
                                              className='quantity-minus btn'
                                              data-type='minus'
                                              disabled
                                            >
                                              <i className='fas fa-minus' />
                                            </button>
                                          )}
                                        </span>
                                      </div>
                                      <Link
                                        className='btn btn-secondary swipe-to-top'
                                        onClick={() =>
                                          addAndUpdatenTheCart(product)
                                        }
                                      >
                                        {t('Add to Cart.1')}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='col-12 col-md-4 detail'>
                                    <div className='share'>
                                      <Link to='#'>
                                        {t('Share.1')} &nbsp;
                                        <i className='fas fa-share' />
                                      </Link>
                                    </div>
                                    <div className='share'>
                                      <div
                                        onClick={() =>
                                          confirmDelete(index, product)
                                        }
                                      >
                                        {t('Remove.1')} &nbsp;
                                        <i className='fas fa-trash-alt' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className='border-line' />
                          </Fragment>
                        );
                      })
                    : 'You have no any product to your wishlist!'}
                  <div className='pagination justify-content-between '>
                    <label className='col-form-label'>
                      {t('Showing.1')} 1–
                      <span className='showing_record'>1</span>
                      &nbsp;{t('of.1')}&nbsp;
                      <span className='showing_total_record'>23</span>
                      &nbsp;{t('results.1')}
                    </label>
                    <div className='col-12 col-sm-6'>
                      <ol className='loader-page'>
                        <li className='loader-page-item'>
                          <Link to='#'>
                            <i
                              className='fa fa-angle-double-left'
                              style={{ fontSize: 12 }}
                            />
                          </Link>
                        </li>
                        <li className='loader-page-item'>
                          <Link to='#'>1</Link>
                        </li>
                        <li className='loader-page-item'>
                          <Link to='#'>2</Link>
                        </li>
                        <li className='loader-page-item'>
                          <Link to='#'>3</Link>
                        </li>
                        <li className='loader-page-item'>
                          <Link to='#'>4</Link>
                        </li>
                        <li className='loader-page-item'>
                          <Link to='#'>
                            <i
                              className='fa fa-angle-double-right'
                              style={{ fontSize: 12 }}
                            />
                          </Link>
                        </li>
                      </ol>
                    </div>
                  </div>
                  {/* ............the end..... */}
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

Wishlist.propTypes = {
  WishlistdecreaseItemQuantity: PropTypes.func.isRequired,
  increaseItemQuantity: PropTypes.func.isRequired,
  WishlistincreaseItemQuantity: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  AddWishlistProducts: PropTypes.func.isRequired,
  removeItemFromWishlist: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired
};

export default connect(null, {
  WishlistdecreaseItemQuantity,
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts,
  WishlistincreaseItemQuantity,
  removeItemFromWishlist,
  removeItemFromCart
})(Wishlist);
