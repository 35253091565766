import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loadStickyHeaderScript } from '../../../../components/utils/LoadScript';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { AllProduct } from '../../../../data_api/products';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts,
  removeItemFromCart
} from '../../../../actions/cart';
import { addItemToModal } from '../../../../actions/modalProduct';

const StickyHeader = props => {
  useEffect(() => {
    loadStickyHeaderScript();
  }, []);

  // for language excahnger
  const { t, i18n } = useTranslation();
  // state for currency changed or not
  const [changeCurrency, setChangeCurrency] = useState(true);

  // select product from reducer cart
  const products = useSelector(state => state.cart.products);
  // length of product
  const productLength = products.length;
  const total = useSelector(state => state.cart.total);
  // for dispatch the function
  const dispatch = useDispatch();
  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);
  // get the length of the wishlist product
  const wishlistProductLenght = WishlistProducts.length;

  // length of product in cart
  const length = products.length;

  // for show alert
  const alert = useAlert();

  // for delete the product form cart or list
  const confirmDelete = (index, item) => {
    dispatch(removeItemFromCart(index, item));
    dispatch(addToCart());
    alert.error('Delected successfully!');
  };

  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty == undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      console.log('add item of product cart is', product.quantity);
      dispatch(
        increaseItemQuantity(productIndex, product, (itemQty = itemQty + 1))
      );
    } else {
      dispatch(addItemToCart(product));
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    dispatch(addToCart());
  };
  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      dispatch(AddWishlistProducts(product));

      alert.success('Successfully added to Wishlist!');
    }
  };

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    if (productExists) {
      dispatch(addItemToModal(alreadyModalProduct));
    } else {
      dispatch(addItemToModal(product));
    }
  };

  return (
    <header id='stickyHeader' className='header-area header-sticky d-none'>
      <div className='header-maxi bg-sticky-bar'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-sm-12 col-lg-2'>
              <Link
                to='/'
                className='logo'
                data-toggle='tooltip'
                data-placement='bottom'
                title='logo'
              >
                <img
                  className='img-fluid'
                  src='assets/images/logo/logo.png'
                  alt='logo here'
                />
              </Link>
            </div>
            <div className='col-12 col-sm-8' style={{ position: 'static' }}>
              <nav
                id='stickyNavbar'
                className='navbar navbar-expand-lg navbar-fixed-top'
              >
                <div className='navbar-collapse'>
                  <ul className='navbar-nav'>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='javascript:void(0);'
                      >
                        {t('Home.1')}
                      </a>
                      <div className='dropdown-menu'>
                        <Link className='dropdown-item' to='/'>
                          {t('Home 1.1')}
                        </Link>
                        <Link className='dropdown-item' to='/index-2'>
                          {t('Home 2.1')}
                        </Link>
                        <Link className='dropdown-item' to='/index-3'>
                          {t('Home 3.1')}
                        </Link>
                      </div>
                    </li>
                    <li className='nav-item dropdown mega-dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='javascript:void(0);'
                      >
                        {t('Mega Menu.1')}
                        <span className='badge badge-secondary'>
                          {t('NEW.1')}
                        </span>
                      </a>
                      <div className='dropdown-menu mega-dropdown-menu row '>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-md-2'>
                              <ul>
                                <li className='dropdown-header'>
                                  {t('Categories.1')}
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Rings.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Earrings.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Crowns.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Necklace.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Bracelet.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Anklet Bracelets.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Hairpin.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Bangle.1')}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className='col-md-2'>
                              <ul>
                                <li className='dropdown-header'>
                                  {t('Departments.1')}
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Fine Jewelry.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Bridal Jewelry.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Timeless Classics.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Mens Jewelry.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Womens Jewelry.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Baby Jewelry.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link className='dropdown-item' to='#'>
                                    {t('Charitable Jewelry.1')}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className='col-md-2'>
                              <ul>
                                <li>
                                  <Link
                                    className='dropdown-item dropdown-header'
                                    to='#'
                                  >
                                    {t('Shop Instagram.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className='dropdown-item dropdown-header'
                                    to='#'
                                  >
                                    {t('Shop By Brands.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className='dropdown-item dropdown-header'
                                    to='#'
                                  >
                                    {t('Repair & Cleaning.1')}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className='dropdown-item dropdown-header'
                                    to='#'
                                  >
                                    {t('Sell Your Jewellery.1')}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className='col-md-6'>
                              <div className='row'>
                                {AllProduct.product_data !== undefined &&
                                AllProduct.product_data.length > 0
                                  ? AllProduct.product_data
                                      .slice(0, 2)
                                      .map((product, index) => {
                                        return (
                                          <div
                                            className='col-6 col-lg-6'
                                            key={index}
                                          >
                                            <div className='product'>
                                              <article>
                                                <div className='pro-thumb '>
                                                  <Link to='/product-page1'>
                                                    <span className='pro-image'>
                                                      <img
                                                        className='img-fluid'
                                                        src={
                                                          product.products_image
                                                        }
                                                        alt='Product'
                                                      />
                                                    </span>
                                                    <span className='pro-image-hover swipe-to-top'>
                                                      <img
                                                        className='img-fluid'
                                                        src={product.images}
                                                        alt='Product'
                                                      />
                                                    </span>
                                                  </Link>
                                                  <div className='pro-buttons d-none d-lg-block d-xl-block'>
                                                    <div className='pro-icons'>
                                                      <div
                                                        className='icon active swipe-to-top'
                                                        onClick={() =>
                                                          AddToWishList(product)
                                                        }
                                                      >
                                                        <i className='fas fa-heart' />
                                                      </div>
                                                      <div
                                                        className='icon swipe-to-top'
                                                        data-toggle='modal'
                                                        data-target='#quickViewModal'
                                                        onClick={() =>
                                                          ShowProductInModal(
                                                            product
                                                          )
                                                        }
                                                      >
                                                        <i className='fas fa-eye' />
                                                      </div>
                                                      <Link
                                                        to='/compare'
                                                        className='icon swipe-to-top'
                                                      >
                                                        <i
                                                          className='fas fa-align-right'
                                                          data-fa-transform='rotate-90'
                                                        />
                                                      </Link>
                                                    </div>
                                                    <button
                                                      type='button'
                                                      className='btn btn-secondary btn-block swipe-to-top'
                                                      onClick={() =>
                                                        addAndUpdatenTheCart(
                                                          product
                                                        )
                                                      }
                                                    >
                                                      {t('Add to Cart.1')}
                                                    </button>
                                                  </div>
                                                  <div className='pro-tag'>
                                                    {t('Sale.1')}
                                                  </div>
                                                </div>
                                                <div className='pro-description'>
                                                  <h2 className='pro-title'>
                                                    <Link to='/product-page1'>
                                                      {t(
                                                        `${product.products_name}.1`
                                                      )}
                                                    </Link>
                                                  </h2>
                                                </div>
                                              </article>
                                            </div>
                                          </div>
                                        );
                                      })
                                  : 'null'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='javascript:void(0);'
                      >
                        {t('Shop.1')}
                      </a>
                      <div className='dropdown-menu'>
                        <Link className='dropdown-item' to='/shop-page1'>
                          {t('Top Bar.1')}
                        </Link>
                        <Link className='dropdown-item' to='/shop-page2'>
                          {t('Right Sidebar.1')}
                        </Link>
                        <Link className='dropdown-item' to='/shop-page3'>
                          {t('Top and Right Sidebar.1')}
                        </Link>
                        <Link className='dropdown-item' to='/shop-page4'>
                          {t('Left Sidebar.1')}
                        </Link>
                        <Link className='dropdown-item' to='/shop-page5'>
                          {t('Top and left Sidebar.1')}
                        </Link>
                      </div>
                    </li>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='javascript:void(0);'
                      >
                        {t('Product.1')}
                      </a>
                      <div className='dropdown-menu'>
                        <Link className='dropdown-item' to='/product-page1'>
                          {t('Left Carousel.1')}
                        </Link>
                        <Link className='dropdown-item' to='/product-page2'>
                          {t('Right Carousel.1')}
                        </Link>
                        <Link className='dropdown-item' to='/product-page3'>
                          {t('Vertical Thumbnail Carousel.1')}
                        </Link>
                        <Link className='dropdown-item' to='/product-page4'>
                          {t('Right Banner.1')}
                        </Link>
                        <Link className='dropdown-item' to='/product-page5'>
                          {t('Best Seller.1')}
                        </Link>
                      </div>
                    </li>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='javascript:void(0);'
                      >
                        {t('Pages.1')}
                      </a>
                      <div className='dropdown-menu'>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='/404'>
                            {t('404 Page.1')}
                          </Link>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='#'>
                            {t('About Us.1')}
                            <i className='fas fa-chevron-right' />
                          </Link>
                          <div className='dropdown-menu'>
                            <Link className='dropdown-item' to='/about-page1'>
                              {t('About Us Page 1.1')}
                            </Link>
                            <Link className='dropdown-item' to='/about-page2'>
                              {t('About Us Page 2.1')}
                            </Link>
                          </div>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='#'>
                            {t('Contact Us.1')}
                            <i className='fas fa-chevron-right' />
                          </Link>
                          <div className='dropdown-menu'>
                            <Link className='dropdown-item' to='/contact-page1'>
                              {t('Contact Us Page 1.1')}
                            </Link>
                            <Link className='dropdown-item' to='/contact-page2'>
                              {t('Contact Us Page 2.1')}
                            </Link>
                          </div>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='#'>
                            {t('Registration.1')}
                            <i className='fas fa-chevron-right' />
                          </Link>
                          <div className='dropdown-menu'>
                            <Link className='dropdown-item' to='/signup'>
                              {t('Signup Page.1')}
                            </Link>
                            <Link className='dropdown-item' to='/login-page1'>
                              {t('Login Page 1.1')}
                            </Link>
                            <Link className='dropdown-item' to='/login-page2'>
                              {t('Login Page 2.1')}
                            </Link>
                          </div>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='#'>
                            {t('Blog.1')}
                            <i className='fas fa-chevron-right' />
                          </Link>
                          <div className='dropdown-menu'>
                            <Link className='dropdown-item' to='/blog-page1'>
                              {t('Blog Page 1.1')}
                            </Link>
                            <Link className='dropdown-item' to='/blog-page2'>
                              {t('Blog Page 2.1')}
                            </Link>
                          </div>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='#'>
                            {t('Shopping Cart.1')}
                            <i className='fas fa-chevron-right' />
                          </Link>
                          <div className='dropdown-menu'>
                            <Link className='dropdown-item' to='/cart-page1'>
                              {t('Cart Page 1.1')}
                            </Link>
                            <Link className='dropdown-item' to='/cart-page2'>
                              {t('Cart Page 2.1')}
                            </Link>
                          </div>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='/checkout'>
                            {t('Checkout.1')}
                          </Link>
                        </div>
                        <div className='dropdown-submenu'>
                          <Link className='dropdown-item' to='#'>
                            {t('Email Templates.1')}
                          </Link>
                          <div className='dropdown-menu'>
                            <a
                              className='dropdown-item'
                              href='http://katora-html.themes-coder.net/email-templates/amazing-deals.html'
                            >
                              {t('Amazing Deals.1')}
                            </a>
                            <a
                              className='dropdown-item'
                              href='http://katora-html.themes-coder.net/email-templates/new-arrival.html'
                            >
                              {t('New Arrival.1')}
                            </a>
                            <a
                              className='dropdown-item'
                              href='http://katora-html.themes-coder.net/email-templates/overall-sale.html'
                            >
                              {t('Overall Sale.1')}
                            </a>
                            <a
                              className='dropdown-item'
                              href='http://katora-html.themes-coder.net/email-templates/winter-sale.html'
                            >
                              {t('Winter Sale.1')}
                            </a>
                            <a
                              className='dropdown-item'
                              href='http://katora-html.themes-coder.net/email-templates/signup.html'
                            >
                              {t('Signup.1')}
                            </a>
                            <a
                              className='dropdown-item'
                              href='http://katora-html.themes-coder.net/email-templates/forgot-password.html'
                            >
                              {t('Forgot Password.1')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className='col-6 col-sm-6 col-md-4 col-lg-2'>
              <ul className='pro-header-right-options'>
                <li className='dropdown'>
                  <button
                    type='button'
                    id='dropdownSearch'
                    className='btn dropdown-toggle'
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-toggle='dropdown'
                  >
                    <i className='fas fa-search' />
                  </button>
                  <div
                    className='dropdown-menu dropdown-menu-right'
                    aria-labelledby='dropdownSearch'
                  >
                    <form>
                      <div className='search-field-module'>
                        <input
                          type='search'
                          placeholder='Search Products...'
                          data-toggle='tooltip'
                          data-placement='bottom'
                          title='search item'
                        />
                        <button className='btn' type='submit'>
                          <i className='fas fa-search' />
                        </button>
                      </div>
                    </form>
                  </div>
                </li>
                <li>
                  <Link
                    to='/wishlist'
                    className='btn'
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='Wishlist'
                  >
                    <i className='far fa-heart' />
                    <span className='badge badge-secondary'>
                      {wishlistProductLenght}
                    </span>
                  </Link>
                </li>
                <li
                  className='cart-header dropdown'
                  data-toggle='tooltip'
                  data-placement='bottom'
                  title='cart'
                >
                  <button
                    type='button'
                    id='stickyHeaderCartButton'
                    className='btn dropdown-toggle'
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-toggle='dropdown'
                  >
                    <i className='fas fa-shopping-cart' />
                    <span className='badge badge-secondary'>{length}</span>
                  </button>
                  <div
                    className='dropdown-menu dropdown-menu-right'
                    aria-labelledby='stickyHeaderCartButton'
                  >
                    {productLength === 0 ||
                    productLength === null ||
                    productLength === undefined ? (
                      <ul>
                        <li>
                          {t('your cart is empty please please add product.1')}!
                        </li>
                      </ul>
                    ) : (
                      <ul className='shopping-cart-items'>
                        {products !== undefined && products !== null
                          ? products.map((item, index) => (
                              <li>
                                <div className='item-thumb'>
                                  <div className='image'>
                                    <img
                                      className='img-fluid'
                                      src={item.products_image}
                                      alt='Product'
                                    />
                                  </div>
                                </div>
                                <div className='item-detail'>
                                  <h2>{item.products_name}</h2>
                                  <div className='item-s'>
                                    {item.quantity} x{' '}
                                    {props.currencyDetails.symbolLeft}
                                    {(
                                      item.products_price *
                                      props.currencyDetails.value
                                    ).toFixed(2)}
                                    <i
                                      className='fas fa-trash'
                                      onClick={() => confirmDelete(index, item)}
                                    />
                                  </div>
                                </div>
                              </li>
                            ))
                          : null}

                        <li>
                          <span className='item-summary'>
                            {t('Total.1')}&nbsp;:&nbsp;
                            <span>
                              {props.currencyDetails.symbolLeft}
                              {(total * props.currencyDetails.value).toFixed(2)}
                            </span>
                          </span>
                        </li>
                        <li>
                          <Link
                            className='btn btn-link btn-block '
                            to='/cart-page1'
                          >
                            {t('View Cart.1')}
                          </Link>
                          <Link
                            className='btn btn-secondary btn-block  swipe-to-top'
                            to='/checkout'
                          >
                            {t('Checkout.1')}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default StickyHeader;
