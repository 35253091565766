exports.Css = url => {
  return new Promise((resolve, reject) => {
    // for (var i = 0; i < scripts.length; i++) {
    //   if (scripts[i].src) console.log(i, scripts[i].src)
    //   else console.log(i, scripts[i].innerHTML)
    // }

    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    link.onload = function() {
      resolve();
      console.log('style has loaded');
    };

    let headScript = document.querySelector('script');
    headScript.parentNode.insertBefore(link, headScript);
    // headScript.parentNode.removeChild(headScript);
    //document.body.removeChild(link);
  });
};

// for load scripts
exports.Script = src => {
  return new Promise(function(resolve, reject) {
    //document.body.removeChild(script);

    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function() {
      resolve();
      console.log('script has loaded');
    });
    script.addEventListener('error', function(e) {
      reject(e);
    });
    document.body.appendChild(script);
    //document.body.removeChild(script);
    let headLink = document.querySelector('link');
    // headLink.parentNode.insertAfter(script, headLink);
    // headLink.parentNode.removeChild(headLink);
  });
};
exports.loadHomeCss = () => {
  //this.removeCss();

  this.Css('assets/css/style.css');
  this.Css('assets/revolution/css/settings.css');
  this.Css('assets/revolution/css/layers.css');
  this.Css('assets/revolution/css/navigation.css');
  this.Css('https://use.fontawesome.com/releases/v5.7.1/css/all.css');
};

exports.loadHomeScript = () => {
  //this.removeJs();
  this.Script('assets/js/scripts.js');
  // this.Script('assets/js/mc-validate.js');
  //  this.Script('assets/js/subscribe.js');
  this.Script('assets/revolution/js/jquery.themepunch.tools.min.js');
  this.Script('assets/revolution/js/jquery.themepunch.revolution.min.js');
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.actions.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.carousel.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.kenburn.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.layeranimation.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.navigation.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.parallax.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.slideanims.min.js'
  );
  this.Script(
    'assets/revolution/js/extensions/revolution.extension.video.min.js'
  );
};

exports.loadStickyHeaderScript = () => {
  //this.removeJs();
  this.Script('assets/js/stickyHeaderScript.js');
  // this.Script('assets/js/mc-validate.js');
  // this.Script('assets/js/subscribe.js');
  // this.Script('assets/revolution/js/jquery.themepunch.tools.min.js');
  // this.Script('assets/revolution/js/jquery.themepunch.revolution.min.js');
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.actions.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.carousel.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.kenburn.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.layeranimation.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.navigation.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.parallax.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.slideanims.min.js'
  // );
  // this.Script(
  //   'assets/revolution/js/extensions/revolution.extension.video.min.js'
  // );
};

/// remove the css file on history changes
exports.removeCss = () => {
  var elements = document.querySelectorAll('link[rel=stylesheet]');

  for (var i = 0; i < elements.length; i++) {
    elements[i].parentNode.removeChild(elements[i]);
  }
};

exports.removeJs = () => {
  var selements = document.querySelectorAll('script');
  for (var i = 0; i < selements.length; i++) {
    selements[i].parentNode.removeChild(selements[i]);
  }
};

exports.addMobileCss = () => {
  this.Css('assets/css/style3.css');
  this.Css(
    'https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.min.css'
  );
};

exports.addMobileScript = () => {
  this.Script('https://code.jquery.com/jquery-3.3.1.slim.min.js');
  this.Script(
    'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js'
  );
  this.Script(
    'https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.concat.min.js'
  );
  this.Script('assets/js/mobile-navigation.js');
};

exports.MobileMenu = () => {
  this.Script('assets/js/mobileMenu.js');

  // this.Script('assets/js/mobileMenu.js');
};

// for tab changing script
exports.TabChanging = () => {
  this.Script('assets/js/scripts.js');
};

// for show product in grids
exports.productShowInGrid = () => {
  this.Script('assets/js/productGridShow.js');
};

// for switcher
exports.switcher = () => {
  this.Script('assets/js/switcher.js');
};

// for load header 3 search
exports.headersearch = () => {
  this.Script('assets/js/header3_search.js');
};

// for scroll to top
exports.scrollToTop = () => {
  this.Script('assets/js/scrollToTop.js');
};

// for mobile menu
exports.MobileMenu = () => {
  this.Script('assets/js/mobileMenu.js');
};

// for product detail page
exports.productDetailPage = () => {
  this.Script('assets/js/productDetailPage.js');
};

// for rev-slider script
exports.ReversibleSliderScript = () => {
  this.Css('assets/css/animate.min.css');
};

// for tab-carousel slider
exports.tabCarouselSlider = () => {
  this.Script('assets/js/tab-carousel.js');
};

// for tab-carousel slider
exports.tabCarouselSliderChange = () => {
  this.Script('assets/js/tab-carouser-change.js');
};

// for tab-carousel slider
exports.script = () => {
  this.Script('assets/js/scripts.js');
};

// for popular-categories-carousel slider
exports.popularCarouselSlider = () => {
  this.Script('assets/js/popular-categories-slider.js');
};

// for testimonials-carousel slider
exports.testimonialsCarouselSlider = () => {
  this.Script('assets/js/testimonials.js');
};

// for aboutus-carousel slider
exports.aboutUSCarouselSlider = () => {
  this.Script('assets/js/aboutus-slider.js');
};

// for productPage5-carousel slider
exports.productPage5caroselCarouselSlider = () => {
  this.Script('assets/js/productPage-5-carosel.js');
};

// all Categories dropdown
exports.allCategoriesDropDown = () => {
  this.Script('assets/js/allCategories-dropdown.js');
};

// for drop down show
exports.CategoriesDropDownCss = () => {
  this.Css('assets/css/style.css');
};

// for add to cart button
exports.addToCartButton = () => {
  this.Script('assets/js/addToCart.js');
};

// for checkout tab changing script
exports.checkOutTabChanging = () => {
  this.Script('assets/js/tabChangingScript.js');
};

// for product detail page fancy box
exports.fancyBoxScript = () => {
  this.Script('assets/js/fancyBoxProductDetailPage.js');
};
