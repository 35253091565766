import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const NewsLetter_Modal = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div
        className='modal fade show'
        id='newsletterModal'
        tabIndex={-1}
        role='dialog'
        aria-hidden='false'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6'>
                    <div className='row '>
                      <div className='pro-image'>
                        <img
                          className='img-fluid'
                          src='assets/images/miscellaneous/newsletter.jpg'
                          alt='newslatter'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='promo-box'>
                      <div className='text-01'>
                        {t('Sign Up for Our Newsletter.1')}
                      </div>
                      <div className='text-03'>
                        {t(
                          'Be the first to learn about our latest trends and get exclusive offers.1'
                        )}
                      </div>
                      <form>
                        <div className='form-group'>
                          <input
                            type='email'
                            //defaultValue
                            name='EMAIL'
                            className='required email form-control'
                            placeholder='Enter Your Email Address...'
                          />
                        </div>
                        <button
                          type='submit'
                          value='Subscribe'
                          name='subscribe'
                          id='mc-embedded-subscribe'
                          className='btn btn-secondary swipe-to-top'
                        >
                          {t('Subscribe.1')}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewsLetter_Modal;
