import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Compare = () => {
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Compare Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Compare.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content compare-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Compare Page.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <table className='table'>
                <thead>
                  <tr>
                    <td>
                      <div className='img-div'>
                        <img
                          className='img-fluid'
                          src='assets/images/compare/compare_1.jpg'
                          alt='Product'
                        />
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h4>{t('Austrian Crystals Wedding Ring.1')}</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Price.1')}&nbsp;:&nbsp;</span>
                      <span className='price-tag'>$285</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Color.1')}&nbsp;:&nbsp;</span>
                      {t('Gold Daimond.1')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Size.1')}e&nbsp;:&nbsp;</span>28
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Type.1')}&nbsp;:&nbsp;</span>
                      {t('Rings.1')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='detail-buttons'>
                        <Link
                          to='/product-page1'
                          className='btn btn-secondary swipe-to-top'
                        >
                          {t('View Details.1')}
                        </Link>
                        <div className='share'>
                          <Link to='#'>
                            {t('Share.1')} &nbsp;
                            <i className='fas fa-share' />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-12 col-md-6'>
              <table className='table'>
                <thead>
                  <tr>
                    <td>
                      <div className='img-div'>
                        <img
                          className='img-fluid'
                          src='assets/images/compare/compare_2.jpg'
                          alt='Product'
                        />
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h4>{t('Austrian Crystals Engagement Ring.1')}</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Price.1')}&nbsp;:&nbsp;</span>
                      <span className='price-tag'>$85</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Color.1')}&nbsp;:&nbsp;</span>
                      {t('Gold Crystal.1')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Size.1')}&nbsp;:&nbsp;</span>26
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{t('Type.1')}&nbsp;:&nbsp;</span>
                      {t('Ring.1')}s
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='detail-buttons'>
                        <Link
                          to='/product-page1'
                          className='btn btn-secondary swipe-to-top'
                        >
                          {t('View Details.1')}
                        </Link>
                        <div className='share'>
                          <Link to='#'>
                            {t('Share.1')} &nbsp;
                            <i className='fas fa-share' />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Compare;
