import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Mobile_Footer = () => {
  // for language excahnger
  const { t } = useTranslation();

  return (
    <Fragment>
      <footer
        id='footerMobile'
        className='footer-area footer-mobile d-lg-none d-xl-none'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-3'>
              <div className='single-footer'>
                <div className='pro-about'>
                  <a
                    href='#'
                    data-toggle='collapse'
                    data-target='#collapseFooter'
                    aria-expanded='false'
                    aria-controls='collapseFooter'
                  >
                    {t('Store.1')}
                  </a>
                  <div className='collapse' id='collapseFooter'>
                    <div className='card card-body'>
                      <ul className='pl-0 mb-0'>
                        <li>
                          <Link to='/'>
                            <img
                              src='assets/images/logo/logo.png'
                              alt='logo here'
                            />
                          </Link>
                        </li>
                        <li>
                          <span>
                            {t(
                              '1223, Main Street, Anytown New York, 38000 USA.1'
                            )}
                          </span>
                        </li>
                        <li>
                          <span>888 - 963 - 600</span>
                          <span>
                            <Link to='#'>info@example.com</Link>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-2'>
              <div className='single-footer'>
                <a
                  href='#'
                  data-toggle='collapse'
                  data-target='#collapseFooterOne'
                  aria-expanded='false'
                  aria-controls='collapseFooterOne'
                >
                  {t('Info.1')}
                </a>
                <div className='collapse' id='collapseFooterOne'>
                  <div className='card card-body'>
                    <ul className='pl-0 mb-0'>
                      <li>
                        <Link to='/'>{t('Home.1')}</Link>
                      </li>
                      <li>
                        <Link to='/blog-page1'>{t('Blog.1')}</Link>
                      </li>
                      <li>
                        <Link to='/about-page1'>{t('About Us.1')}</Link>
                      </li>
                      <li>
                        <Link to='/contact-page1'>{t('Contact Us.1')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-2'>
              <div className='single-footer'>
                <a
                  href='#'
                  data-toggle='collapse'
                  data-target='#collapseFooterTwo'
                  aria-expanded='false'
                  aria-controls='collapseFooterTwo'
                >
                  {t('Our Polices.1')}
                </a>
                <div className='collapse' id='collapseFooterTwo'>
                  <div className='card card-body'>
                    <ul className='pl-0 mb-0'>
                      <li>
                        <Link to='#'>{t('FAQs.1')}</Link>
                      </li>
                      <li>
                        <Link to='/privacy'>{t('Privacy Policy.1')}</Link>
                      </li>
                      <li>
                        <Link to='/refund'>{t('Refund Policy.1')}</Link>
                      </li>
                      <li>
                        <Link to='/term'>{t('Terms of Service.1')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-2'>
              <div className='single-footer'>
                <a
                  href='#'
                  data-toggle='collapse'
                  data-target='#collapseFooterThree'
                  aria-expanded='false'
                  aria-controls='collapseFooterThree'
                >
                  {t('My Account.1')}
                </a>
                <div className='collapse' id='collapseFooterThree'>
                  <div className='card card-body'>
                    <ul className='pl-0 mb-0'>
                      <li>
                        <Link to='/shop-page1'>{t('Shop.1')}</Link>
                      </li>
                      <li>
                        <Link to='/orders'>{t('Orders.1')}</Link>
                      </li>
                      <li>
                        <Link to='/cart-page1'>{t('Shopping Cart.1')}</Link>
                      </li>
                      <li>
                        <Link to='/wishlist'>{t('Wishlist.1')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-3'>
              <div className='single-footer'>
                <div className='pro-newsletter'>
                  <h5>{t('Newsletter.1')}</h5>
                  <form className='form-inline'>
                    <div className='search'>
                      <input type='search' placeholder='Your Email ...' />
                      <button type='button' className='btn btn-secondary'>
                        <i className='fas fa-location-arrow' />
                      </button>
                    </div>
                  </form>
                  <p>
                    {t(
                      'By entering your email, you agree to our Terms of Service and Privacy Policy.1'
                    )}
                  </p>
                </div>
                <div className='pro-socials'>
                  <h5>{t('Follow Us.1')}</h5>
                  <ul>
                    <li>
                      <Link to='#' className='fab fb fa-facebook-square' />
                    </li>
                    <li>
                      <Link to='#' className='fab tw fa-twitter-square' />
                    </li>
                    <li>
                      <Link to='#' className='fab sk fa-skype' />
                    </li>
                    <li>
                      <Link to='#' className='fab ln fa-linkedin' />
                    </li>
                    <li>
                      <Link to='#' className='fab ig fa-instagram' />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid p-0'>
          <div className='copyright-content'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-12 col-sm-12'>
                  <div className='footer-info'>
                    ©&nbsp;2019 Company, Inc.
                    <Link to='/privacy'>{t('Privacy.1')}</Link>
                    &nbsp;•&nbsp;<Link to='/term'>{t('Terms.1')}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Mobile_Footer;
