import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Thankyou = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* Paste this code after body tag */}
      {/* <div className="se-pre-con" /> */}
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* About-us Content */}
      <section className='empty-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='pro-empty-page'>
                <h2 style={{ fontSize: 150 }}>
                  <i className='far fa-check-circle' />
                </h2>
                <h1>{t('Thank You.1')}</h1>
                <p>
                  {t('You have successfully place your order.1')}.
                  {t('Your order id is.1')} 354365. {t('Go to the.1')}
                  <Link to='/order-detail' className='btn-link'>
                    <b>
                      {' '}
                      {'  '}
                      {t('Order page.1')}
                    </b>
                  </Link>
                  .
                </p>
              </div>
              <p />
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Thankyou;
