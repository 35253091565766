import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Shipping_Address = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/*Shipping Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Shipping Address.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content shipping-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Shipping Address.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-3'>
              <ul className='list-group'>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/profile'>
                    <i className='fas fa-user' />
                    {t('Profile.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/wishlist'>
                    <i className='fas fa-heart' />
                    {t('Wishlist.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/orders'>
                    <i className='fas fa-shopping-cart' />
                    {t('Orders.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/shipping-address'>
                    <i className='fas fa-map-marker-alt' />
                    {t('Shipping Address.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='#'>
                    <i className='fas fa-power-off' />
                    {t('Logout.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/change-password'>
                    <i className='fas fa-unlock-alt' />
                    {t('Change Password.1')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-12 col-lg-9 '>
              <table className='table'>
                <thead>
                  <tr className='d-flex'>
                    <th className='col-12 col-md-8'>
                      {t('DEFAULT ADDRESS.1')}
                    </th>
                    <th className='col-12 col-md-4'>{t('ACTION.1')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='d-flex'>
                    <td className='col-12 col-md-8'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='exampleRadios'
                          id='exampleRadios1'
                          defaultValue='option1'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='exampleRadios1'
                        >
                          {t(
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                          )}
                        </label>
                      </div>
                    </td>
                    <td className=' col-12 col-md-4'>
                      <ul className='controls'>
                        <li>
                          <Link to='#'>
                            <i className='fas fa-pen' /> {t('Edit.1')}
                          </Link>
                        </li>
                        <li>
                          <Link to='#'>
                            <i className='fas fa-trash-alt' /> {t('Remove.1')}
                          </Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='add-address'>
                <form action='/' name='general-form'>
                  <h4>{t('Personal Information.1')}</h4>
                  <div className='form-row'>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group '>
                        <input
                          type='text'
                          name='firstname'
                          className='form-control'
                          id='inlineFormInputGroup0'
                          placeholder='First Name'
                        />
                      </div>
                    </div>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group '>
                        <input
                          type='text'
                          name='lastname'
                          className='form-control'
                          id='inlineFormInputGroup1'
                          placeholder='Last Name'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group '>
                        <input
                          type='text'
                          className='form-control'
                          id='inlineFormInputGroup2'
                          placeholder='Company Name'
                        />
                      </div>
                    </div>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group '>
                        <input
                          type='text'
                          name='address'
                          className='form-control'
                          id='inlineFormInputGroup3'
                          placeholder='Address'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group select-control'>
                        <select
                          className='form-control'
                          name='SelectName'
                          id='inlineFormInputGroup4'
                        >
                          <option selected>Select Country</option>
                          <option value={1}>Canada</option>
                          <option value={2}>United Kingdom</option>
                          <option value={3}>United States</option>
                        </select>
                      </div>
                    </div>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group select-control'>
                        <select
                          className='form-control'
                          name='SelectStateName'
                          id='inlineFormInputGroup5'
                        >
                          <option selected>Select State</option>
                          <option value={1}>Alaska</option>
                          <option value={2}>New York</option>
                          <option value={3}>Taxes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group'>
                        <input
                          type='text'
                          name='postcode'
                          className='form-control'
                          id='inlineFormInputGroup7'
                          placeholder='City'
                        />
                      </div>
                    </div>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group'>
                        <input
                          type='text'
                          name='postcode'
                          className='form-control'
                          id='inlineFormInputGroup7'
                          placeholder='Postal Code'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='from-group col-md-6 mb-4'>
                      <div className='input-group'>
                        <input
                          type='text'
                          name='phone'
                          className='form-control'
                          id='inlineFormInputGroup8'
                          placeholder='Phone'
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type='submit'
                    className='btn btn-secondary swipe-to-top'
                  >
                    {t('Add Address.1')}
                  </button>
                </form>
              </div>
              {/* ............the end..... */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Shipping_Address;
