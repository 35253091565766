import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Blog_page2 = () => {
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* Paste this code after body tag */}
      {/* <div className="se-pre-con" /> */}
      {/* //Header Style One */}
      <MainHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Site Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Blog.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content blog-content blog-content-page'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Blog Page.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className='blog-area'>
                <div className='row'>
                  <div className='col-12 col-sm-12'>
                    <div className='blog'>
                      <div className='blog-thumbnail'>
                        <div className='blog-date'>
                          30
                          <small>{t('Dec.1')}</small>
                        </div>
                        <Link to='/blog-single-post'>
                          <img
                            className='img-thumbnail'
                            src='assets/images/blogs/blog_post_1.jpg'
                            alt='Thumbnail'
                          />
                        </Link>
                      </div>
                      <h4>
                        <Link to='/blog-single-post'>
                          {t(
                            'Woman wearing Silver-colore ring pendant necklaces.1'
                          )}
                        </Link>
                        <br />
                      </h4>
                      <p>
                        {t(
                          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Ut enim ad minim veniam, voluptatem.1'
                        )}
                        <Link to='/blog-single-post'>{t('Read More.1')}..</Link>
                      </p>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <div className='blog'>
                      <div className='blog-thumbnail'>
                        <div className='blog-date'>
                          30
                          <small>{t('Dec.1')}</small>
                        </div>
                        <Link to='/blog-single-post'>
                          <img
                            className='img-thumbnail'
                            src='assets/images/blogs/blog_post_2.jpg'
                            alt='Thumbnail'
                          />
                        </Link>
                      </div>
                      <h4>
                        <Link to='/blog-single-post'>
                          {t('White gold engagement rings for couples.1')}
                        </Link>
                        <br />
                      </h4>
                      <p>
                        {t(
                          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Ut enim ad minim veniam, voluptatem.1'
                        )}
                        <Link to='/blog-single-post'>{t('Read More.1')}..</Link>
                      </p>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <div className='blog'>
                      <div className='blog-thumbnail'>
                        <div className='blog-date'>
                          30
                          <small>{t('De.1')}c</small>
                        </div>
                        <Link to='/blog-single-post'>
                          <img
                            className='img-thumbnail'
                            src='assets/images/blogs/blog_post_3.jpg'
                            alt='Thumbnail'
                          />
                        </Link>
                      </div>
                      <h4>
                        <Link to='/blog-single-post'>
                          {t(
                            'Shallow focus photo of person putting gold-colored ring.1'
                          )}
                        </Link>
                        <br />
                      </h4>
                      <p>
                        {t(
                          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Ut enim ad minim veniam, voluptatem.1'
                        )}
                        <Link to='/blog-single-post'>{t('Read More.1')}..</Link>
                      </p>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <div className='blog'>
                      <div className='blog-thumbnail'>
                        <div className='blog-date'>
                          30
                          <small>{t('Dec.1')}</small>
                        </div>
                        <Link to='/blog-single-post'>
                          <img
                            className='img-thumbnail'
                            src='assets/images/blogs/blog_post_4.jpg'
                            alt='Thumbnail'
                          />
                        </Link>
                      </div>
                      <h4>
                        <Link to='/blog-single-post'>
                          {t('Pearl necklace jewelry treasure box.1')}
                        </Link>
                        <br />
                      </h4>
                      <p>
                        {t(
                          'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                        )}
                        <Link to='/blog-single-post'>{t('Read More.1')}..</Link>
                      </p>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='pagination justify-content-between '>
                      <label className='col-form-label'>
                        {t('Showing.1')} 1–
                        <span className='showing_record'>1</span>&nbsp;
                        {t('of.1')}&nbsp;
                        <span className='showing_total_record'>23</span>&nbsp;
                        {t('results.1')}.
                      </label>
                      <div className='col-12 col-sm-6'>
                        <ol className='loader-page'>
                          <li className='loader-page-item'>
                            <Link to='/'>
                              <i
                                className='fa fa-angle-double-left'
                                style={{ fontSize: 12 }}
                              />
                            </Link>
                          </li>
                          <li className='loader-page-item'>
                            <Link to='#'>1</Link>
                          </li>
                          <li className='loader-page-item'>
                            <Link to='#'>2</Link>
                          </li>
                          <li className='loader-page-item'>
                            <Link to='#'>3</Link>
                          </li>
                          <li className='loader-page-item'>
                            <Link to='#'>4</Link>
                          </li>
                          <li className='loader-page-item'>
                            <Link to='#'>
                              <i
                                className='fa fa-angle-double-right'
                                style={{ fontSize: 12 }}
                              />
                            </Link>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-4  d-lg-block d-xl-block blog-menu'>
              <div className='block'>
                <div className='pro-heading-subtitle'>
                  <h4>{t('Recent Posts.1')}</h4>
                </div>
                <div className='media'>
                  <img
                    src='assets/images/miscellaneous/avatar.jpg'
                    alt='Avatar'
                    style={{ width: 68, height: 68 }}
                  />
                  <div className='media-body'>
                    <h6>
                      <Link to='/blog-single-post'>
                        {t(
                          'Gold bangle is worn on top of the engagement band.1'
                        )}
                      </Link>
                    </h6>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, sed do eiusmod tempor incdidun dolore magna aliqua.1'
                      )}
                    </p>
                  </div>
                </div>
                <div className='media'>
                  <img
                    src='assets/images/miscellaneous/avatar.jpg'
                    alt='Avatar'
                    style={{ width: 68, height: 68 }}
                  />
                  <div className='media-body'>
                    <h6>
                      <Link to='/blog-single-post'>
                        {t(
                          'Woman wearing all kind of silver colore jewelery.1'
                        )}
                      </Link>
                    </h6>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, sed do eiusmod tempor incdidun dolore magna aliqua.1'
                      )}
                    </p>
                  </div>
                </div>
                <div className='media'>
                  <img
                    src='assets/images/miscellaneous/avatar.jpg'
                    alt='Avatar'
                    style={{ width: 68, height: 68 }}
                  />
                  <div className='media-body'>
                    <h6>
                      <Link to='/blog-single-post'>
                        {t('White gold engagement rings for couples.1')}
                      </Link>
                    </h6>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, sed do eiusmod tempor incdidun dolore magna aliqua.1'
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className='block'>
                <div className='pro-heading-subtitle'>
                  <h4>{t('Latest Comments.1')}</h4>
                </div>
                <div className='media'>
                  <i className='fas fa-comments' />
                  <div className='media-body'>
                    <p>
                      <Link to='/blog-single-post'>
                        {t('Lorem ipsum dolor sit amet, consectetur aliqua.1')}
                      </Link>
                    </p>
                    <span>
                      <i className='far fa-clock' />
                      {t('Dec 25,2019.1')}
                    </span>
                  </div>
                </div>
                <div className='media'>
                  <i className='fas fa-comments' />
                  <div className='media-body'>
                    <p>
                      <Link to='/blog-single-post'>
                        {t(
                          'Lorem ipsum dolor sit amet, sed do eiusmod tempor incdidun dolore magna aliqua.1'
                        )}
                      </Link>
                    </p>
                    <span>
                      <i className='far fa-clock' />
                      {t('Dec 25,2019.1')}
                    </span>
                  </div>
                </div>
                <div className='media'>
                  <i className='fas fa-comments' />
                  <div className='media-body'>
                    <p>
                      <Link to='/blog-single-post'>
                        {t(
                          'Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                        )}
                      </Link>
                    </p>
                    <span>
                      <i className='far fa-clock' />
                      {t('Dec 25,2019.1')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='block'>
                <div className='pro-socials'>
                  <h4>{t('Stay Connected.1')}</h4>
                  <ul>
                    <li>
                      <Link to='#' className='fab fb fa-facebook-square' />
                    </li>
                    <li>
                      <Link to='#' className='fab tw fa-twitter-square' />
                    </li>
                    <li>
                      <Link to='#' className='fab sk fa-skype' />
                    </li>
                    <li>
                      <Link to='#' className='fab ln fa-linkedin' />
                    </li>
                    <li>
                      <Link to='#' className='fab ig fa-instagram' />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Blog_page2;
