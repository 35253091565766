import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const Testimonials = () => {
  const { t } = useTranslation();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <section className='pro-content testimonails-content'>
        <div className='container'>
          {/* heading */}
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-12'>
              <div className='pro-heading-title'>
                <h2> {t('Testimonials our clients.1')}</h2>
                <p>
                  {t(
                    'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                  )}
                </p>
              </div>
            </div>
          </div>
          {/* <div className='testimonials-carousel-js row'> */}
          <Slider className='carousel' {...settings}>
            <div className='slide'>
              <figure className='banner-image'>
                <img
                  className='img-fluid'
                  src='assets/images/about-us/profile.png'
                  alt='Profile'
                />
              </figure>
              <div className='pro-detail'>
                <h2>
                  <Link to='/blog-page1'>Jeny Martinez</Link>
                </h2>
                <p>
                  {t(
                    'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                  )}
                </p>
                <Link to='#' className='pro-readmore' tabIndex={0}>
                  {t('Read more.1')}
                </Link>
              </div>
            </div>
            <div className='slide'>
              <figure className='banner-image'>
                <img
                  className='img-fluid'
                  src='assets/images/about-us/profile.png'
                  alt='Banner'
                />
              </figure>
              <div className='pro-detail'>
                <h2>
                  <Link to='/blog-page1'>Theresa May</Link>
                </h2>
                <p>
                  {t(
                    'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                  )}
                </p>
                <Link to='#' className='pro-readmore' tabIndex={0}>
                  {t('Read more.1')}
                </Link>
              </div>
            </div>
            <div className='slide'>
              <figure className='banner-image'>
                <img
                  className='img-fluid'
                  src='assets/images/about-us/profile.png'
                  alt='Banner'
                />
              </figure>
              <div className='pro-detail'>
                <h2>
                  <Link to='/blog-page1'>Malissa</Link>
                </h2>
                <p>
                  {t(
                    'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                  )}
                </p>
                <Link to='#' className='pro-readmore' tabIndex={0}>
                  {t('Read more.1')}
                </Link>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </Fragment>
  );
};

export default Testimonials;
