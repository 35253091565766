import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
  // for translation
  const { t } = useTranslation();

  return (
    <Fragment>
      <section className='info-boxes-content'>
        <div className='container-fuild'>
          <div className='info-box-full bg-info-boxes-content'>
            <div className='row'>
              <div className='col-12 col-md-6 col-xl-3 pl-xl-0'>
                <div className='info-box'>
                  <i className='fas fa-truck' />
                  <div className='block'>
                    <h4 className='title'>{t('Free Shipping.1')}</h4>
                    <p>
                      {t('We provide free shipping for all order over.1')}{' '}
                      $200.00
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-xl-3 pl-xl-0'>
                <div className='info-box'>
                  <i className='fas fa-life-ring' />
                  <div className='block'>
                    <h4 className='title'>{t('Support.1')} 24/7</h4>
                    <p>
                      {t('We provide support to our customers 24 hours.1')}.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-xl-3 pl-xl-0'>
                <div className='info-box'>
                  <i className='fas fa-credit-card' />
                  <div className='block'>
                    <h4 className='title'>{t('Safe Payment.1')}</h4>
                    <p>{t('We provide always safe payment methods.1')}.</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-xl-3 pl-xl-0'>
                <div className='info-box last'>
                  <i className='fas fa-reply' />
                  <div className='block'>
                    <h4 className='title'>{t('Returns.1')}</h4>
                    <p>{t('We provide always safe payment methods.1')}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Services;
