import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Cookies = () => {
  // for language excahnger
  const { t } = useTranslation();

  return (
    <div
      className='alert alert-warning alert-dismissible alert-cookie fade show'
      role='alert'
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-8 col-lg-9'>
            <div className='pro-description'>
              {t('This site uses cookies.1')}{' '}
              {t(
                'By continuing to browse the site you are agreeing to our use of cookies.1'
              )}
              {'  '}
              {t('Review our.1')}
              {'  '}
              <Link to='#' className='btn-link'>
                {t('cookies information.1')}
                {'  '}
              </Link>
              {t('for more details.1')}
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-3'>
            <button
              type='button'
              className='btn btn-secondary swipe-to-top'
              data-dismiss='alert'
              aria-label='Close'
            >
              {t('OK, I agree.1')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
