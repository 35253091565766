import React, { Fragment, useEffect, useState } from 'react';
import MainHeaderThree from '../../../layouts/header/main-header/header-three';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MainSlider from '../../../components/sliders/main_slider';
import BestProductSlider from '../../../components/sliders/best_products_slider';
import BannerCategories from '../../../components/products/banners/banner_home3';
import PapularCategories from '../../../components/papularCategory';
import Testimonials from '../../../components/testimonials';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import Footer3 from '../../../layouts/footer/footer-three';
import ProductModel from '../../../components/products/product_model';
import { currency } from '../../../components/utils/currency_array';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';
import { sliderThree } from '../../../data_api/slider';

const Home_3 = () => {
  const { t} = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////
  return (
    <Fragment>
      {/* //header style three */}
      <MainHeaderThree
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Revolution Layer Slider */}
      <MainSlider slider={sliderThree} />
      {/* Products Tabs */}
      <BestProductSlider currencyDetails={currencyDetail} />
      {/* banner category */}
      <BannerCategories />
      {/* Products Tabs */}
      <PapularCategories />
      {/* Testimonails */}
      <Testimonials />

      <footer id='footerTwo' className='footer-area footer-two d-block'>
        <div className='container-fluid p-0'>
          <div className='newsletter-content'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12 col-lg-12'>
                  <div className='pro-heading-title'>
                    <h2> {t('Newsletter.1')}</h2>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.1'
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className='row justify-content-center'>
                <div className='col-12 col-lg-8'>
                  <div className='newsletter-form'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Your Email...'
                    />
                    <button className='btn btn-secondary btn-lg swipe-to-top'>
                      {t('Subscribe.1')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Product Modal */}
      <ProductModel currencyDetails={currencyDetail} />
      {/* Footer Mobile */}
      <MobileFooter />
      {/* Footer Two */}
      <Footer3 />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Home_3;
