import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Change_Password = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* login Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Change Password.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content login-content center-content-page'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='pro-heading-title'>
              <h1>{t('Change Password.1')}</h1>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-12 col-md-8 col-lg-5 mb-5 px-0'>
              <div className='tab-content' id='registerTabContent'>
                <div className='tab-pane active'>
                  <div className='registration-process'>
                    <form>
                      <div className='from-group mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='password'
                            className='form-control'
                            id='inlineFormInputGroup3'
                            placeholder='Enter Your Old Password'
                          />
                        </div>
                      </div>
                      <div className='from-group mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='password'
                            className='form-control'
                            id='inlineFormInputGroup4'
                            placeholder='Enter Your New Password'
                          />
                        </div>
                      </div>
                      <div className='col-12 col-sm-12'>
                        <button className='btn btn-secondary swipe-to-top'>
                          {t('Change.1')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='container'>
              <div className='registration-socials'>
                <p className='mb-3 text-center'>
                  {t('Access Your Account Through Your Social Networks.1')}
                </p>
                <div className='from-group'>
                  <button
                    type='button'
                    className='btn btn-google swipe-to-top'
                    style={{ marginRight: '10px'}}
                  >
                    <i className='fab fa-google-plus-g' />
                    &nbsp;{t('Google.1')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-facebook swipe-to-top'
                    style={{ marginRight: '10px'}}
                  >
                    <i className='fab fa-facebook-f' />
                    &nbsp;{t('Facebook.1')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-twitter swipe-to-top'
                  >
                    <i className='fab fa-twitter' />
                    &nbsp;{t('Twitter.1')}
                  </button>
                </div>
              </div>
              <p>
                {t(
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, Nulla vitae viverra nibh, Etiam a arcu sed mi suscipit rutrum, Sed a lorem pellentesque, dignissim risus in, feugiat ipsum, Nulla laoreet faucibus velit eget iaculis, Vivamus porttitor diam lectus, eu rhoncus lacus dignissim et.1'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Change_Password;
