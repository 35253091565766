import React, { Fragment, useEffect, useState } from 'react';
import HeaderOne from '../../../layouts/header/main-header/header-one';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Cookies from '../../../components/cookies';
import { currency } from '../../../components/utils/currency_array';
import MainSlider from '../../../components/sliders/main_slider';
import Banners from '../../../components/products/banners/banner_home1';
import Productslider from '../../../components/sliders/product_slider';
import Specialoffer from '../../../components/products/special_offer';
import NewArrival from '../../../components/sliders/new_arrival_slider';
import FullScreenbanner from '../../../components/products/fullScreen_banner';
import BlogSlider from '../../../components/sliders/blog_slider';
import ProductModel from '../../../components/products/product_model';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import { sliderOne } from '../../../data_api/slider';
import { PATH_1 } from '../../../data_api/fullscreen-banners';
const Home = () => {
  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail == null || LocalCurrencyDetail == undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////
  return (
    <Fragment>
      <HeaderOne
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Revolution Layer Slider */}
      <MainSlider slider={sliderOne} />
      {/* Banners */}
      <Banners />
      {/* Products Tabs */}
      <Productslider currencyDetails={currencyDetail} />
      {/* Special Offer */}
      <Specialoffer currencyDetails={currencyDetail} />
      {/* Popular Products */}
      <NewArrival currencyDetails={currencyDetail} />

      {/* Full Screen Banner */}
      <FullScreenbanner image={PATH_1} />

      {/* Blogs */}
      <BlogSlider />

      {/* Product Modal */}
      <ProductModel currencyDetails={currencyDetail} />
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />

      <ArrowBackToTop />

      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />

      <Cookies />
    </Fragment>
  );
};

export default Home;
