import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Login_Page2 = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* login Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Login.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content login-content center-content-page'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='pro-heading-title'>
              <h1>{t('Login Page.1')}</h1>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-12 col-md-8 col-lg-5 mb-5 '>
              <ul className='nav nav-tabs' id='registerTab' role='tablist'>
                <li className='nav-item'>
                  <a
                    className='nav-link active'
                    id='login-tab'
                    data-toggle='tab'
                    href='#login'
                    role='tab'
                    aria-controls='login'
                    aria-selected='true'
                  >
                    {t('Login.1')}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    id='signup-tab'
                    data-toggle='tab'
                    href='#signup'
                    role='tab'
                    aria-controls='signup'
                    aria-selected='false'
                  >
                    {t('New Customer.1')}
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='registerTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='login'
                  role='tabpanel'
                  aria-labelledby='login-tab'
                >
                  <div className='registration-process'>
                    <form action='/' name='login'>
                      <div className='from-group mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='text'
                            name='email'
                            className='form-control'
                            id='inlineFormInputGroup2'
                            placeholder='Enter Your Email'
                          />
                        </div>
                      </div>
                      <div className='from-group mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='text'
                            name='password'
                            className='form-control'
                            id='inlineFormInputGroup3'
                            placeholder='Enter Your Password'
                          />
                        </div>
                      </div>
                      <div className='col-12 col-sm-12'>
                        <button className='btn btn-secondary swipe-to-top'>
                          {t('Login.1')}
                        </button>
                        <Link to='/change-password' className='btn btn-link'>
                          {t('Forgot Password.1')}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='signup'
                  role='tabpanel'
                  aria-labelledby='signup-tab'
                >
                  <div className='registration-process'>
                    <form action='/' name='general-form'>
                      <div className='from-group  mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='text'
                            name='fullname'
                            className='form-control'
                            id='inlineFormInputGroup4'
                            placeholder='Enter Your Fullname'
                          />
                        </div>
                      </div>
                      <div className='from-group mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='text'
                            name='email'
                            className='form-control'
                            id='inlineFormInputGroup5'
                            placeholder='Enter Your Email'
                          />
                        </div>
                      </div>
                      <div className='from-group mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='password'
                            name='password'
                            className='form-control'
                            id='inlineFormInputGroup6'
                            placeholder='Enter Your Password'
                          />
                        </div>
                      </div>
                      <div className='from-group  mb-4'>
                        <div className='input-group col-12'>
                          <input
                            type='text'
                            className='form-control'
                            id='inlineFormInputGroup7'
                            placeholder='Enter Your Mobile Number'
                          />
                        </div>
                      </div>
                      <div className='from-group'>
                        <div className='col-12'>
                          <button className='btn btn-primary swipe-to-top'>
                            {t('Create Account.1')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='registration-socials'>
              <div className='col-12'>
                <p className='mb-3 text-center'>
                  {t('Access Your Account Through Your Social Networks.1')}
                </p>
                <div className='from-group'>
                  <button
                    type='button'
                    className='btn btn-google swipe-to-top'
                   // style={{ marginRight: '5px' }}
                  >
                    <i className='fab fa-google-plus-g' />
                    &nbsp;{t('Google.1')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-facebook swipe-to-top'
                    //style={{ marginRight: '5px' }}
                  >
                    <i className='fab fa-facebook-f' />
                    &nbsp;{t('Facebook.1')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-twitter swipe-to-top'
                  >
                    <i className='fab fa-twitter' />
                    &nbsp;{t('Twitter.1')}
                  </button>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <p className='mt-3'>
                {t(
                  'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Login_Page2;
