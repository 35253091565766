import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeItemFromCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  addToCart
} from '../../../actions/cart';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
const Cart_Page2 = props => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  // const cartItem = JSON.parse(localStorage.getItem('cartItem'));
  const products = useSelector(state => state.cart.products);
  const total = useSelector(state => state.cart.total);

  // for show alert
  const alert = useAlert();

  //check length of cart
  const length = products.length;

  // get value from localStorage
  const cartItem = JSON.parse(localStorage.getItem('cartItem'));

  const [formData, setFormData] = useState({
    quantity: 0,
    products: cartItem,
    cart: [],
    subTotal: 0
  });

  const confirmDelete = (index, item) => {
    props.removeItemFromCart(index, item);
    props.addToCart();
    alert.error('Delected successfully!');
  };

  const increaseQty = (index, product, quantity) => {
    props.increaseItemQuantity(index, product, quantity);
    props.addToCart();
  };

  const decreaseQty = (index, product, quantity) => {
    props.decreaseItemQuantity(index, product, quantity);
    props.addToCart();
  };

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* cart Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Cart Page.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content cart-content cart-page-2'>
        {length === 0 || length === undefined || length === null ? (
          <div class='container'>
            <div class='row'>
              <div class='col-12 empty-content'>
                <div class='pro-empty-page'>
                  <h2 style={{ fontSize: '150px' }}>
                    <i class='fas fa-shopping-cart'></i>
                  </h2>
                  <h1>{t('Your Cart is empty.1')}</h1>
                  <p>
                    <Link to='/shop-page1' class='btn btn-secondary'>
                      {t('Continue Shopping.1')}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className='row'>
              <div className='pro-heading-title'>
                <h1>{t('Cart Page.1')}</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-12'>
                <table className='table top-table'>
                  <tbody>
                    {products !== undefined && products !== null
                      ? products.map((item, index) => (
                          <tr className='d-flex' key={index}>
                            <td className='col-12 col-md-1'>
                              <img
                                className='img-fluid'
                                src={item.products_image}
                                alt='Product'
                              />
                            </td>
                            <td className='col-12 col-md-4 '>
                              <div className='item-detail'>
                                <span className='pro-info'>
                                  {t(`${item.categories[0].categories_name}.1`)}
                                </span>
                                <h2 className='pro-title'>
                                  <Link to='#'>
                                    {t(`${item.products_name}.1`)}
                                  </Link>
                                </h2>
                                <div className='item-attributes' />
                              </div>
                            </td>
                            <td className='col-12 col-md-2 item-price'>
                              {currencyDetail.symbolLeft}
                              {(
                                item.products_price * currencyDetail.value
                              ).toFixed(2)}
                            </td>
                            <td className='col-12 col-md-2'>
                              <div className='input-group item-quantity'>
                                <input
                                  type='text'
                                  id='quantity1'
                                  name='quantity'
                                  className='form-control'
                                  value={item.quantity}
                                  disabled
                                />
                                <span className='input-group-btn'>
                                  <button
                                    type='button'
                                    value='quantity1'
                                    className='quantity-plus btn'
                                    data-type='minus'
                                    data-field
                                    onClick={() =>
                                      increaseQty(
                                        index,
                                        item,
                                        item.quantity + 1
                                      )
                                    }
                                  >
                                    <span className='fas fa-plus' />
                                  </button>
                                  {item.quantity > 1 ? (
                                    <button
                                      type='button'
                                      value='quantity1'
                                      className='quantity-minus btn'
                                      data-type='plus'
                                      data-field
                                      onClick={() =>
                                        decreaseQty(
                                          index,
                                          item,
                                          item.quantity - 1
                                        )
                                      }
                                    >
                                      <span className='fas fa-minus' />
                                    </button>
                                  ) : (
                                    <button
                                      type='button'
                                      value='quantity1'
                                      className='quantity-minus btn'
                                      data-type='minus'
                                      disabled
                                    >
                                      <i className='fas fa-minus' />
                                    </button>
                                  )}
                                </span>
                              </div>
                            </td>
                            <td className='col-12 col-md-1 item-total'>
                              {currencyDetail.symbolLeft}
                              {(
                                item.quantity *
                                item.products_price *
                                currencyDetail.value
                              ).toFixed(2)}
                            </td>
                            <td className='col-12 col-md-2 align-right'>
                              <div className='item-controls'>
                                <button type='button' className='btn'>
                                  <span className='fas fa-pencil-alt' />
                                </button>
                                <button
                                  type='button'
                                  className='btn'
                                  onClick={() => confirmDelete(index, item)}
                                >
                                  <span className='fas fa-times' />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-9 '>
                <table className='table table-background'>
                  <thead>
                    <tr>
                      <th scope='col' colSpan={4}>
                        {t('CALCULATE SHIPPING.1')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='d-flex'>
                      <td scope='row'>
                        <input
                          type='text'
                          className='form-control'
                          id='city'
                          aria-describedby='city'
                          placeholder='City'
                        />
                      </td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          id='country'
                          aria-describedby='country'
                          placeholder='Country'
                        />
                      </td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          id='postcode'
                          aria-describedby='postcode'
                          placeholder='Post Code'
                        />
                      </td>
                      <td>
                        <button
                          className='btn btn-block btn-secondary swipe-to-top'
                          type='button'
                        >
                          {t('Estimate.1')}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='col-12 col-sm-12 mb-4'>
                  <div className='row justify-content-between '>
                    <div className='col-12 col-lg-5'>
                      <div className='row'>
                        <div className='input-group '>
                          <input
                            type='text'
                            className='form-control'
                            style={{ height: 44 }}
                            placeholder='Coupon Code'
                            aria-label='Coupon Code'
                            aria-describedby='coupon-code'
                          />
                          <div className='input-group-append'>
                            <button
                              className='btn btn-secondary swipe-to-top'
                              type='button'
                              id='coupon-code'
                            >
                              {t('Apply.1')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-lg-7'>
                      <div className='row justify-content-end btn-res'>
                        <button type='button' className='btn btn-link'>
                          {t('Continue Shopping.1')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-3'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col' colSpan={2}>
                        {t('Order Summary.1')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{t('Subtotal.1')}</th>
                      <td>
                        {currencyDetail.symbolLeft}
                        {(total * currencyDetail.value).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Coupon Code.1')}</th>
                      <td>$0</td>
                    </tr>
                    <tr className='item-price'>
                      <th>{t('Total.1')}</th>
                      <td>
                        {currencyDetail.symbolLeft}
                        {(total * currencyDetail.value).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button className='btn btn-secondary btn-block swipe-to-top'>
                  {t('Proceed to checkout.1')}
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Cart_Page2;
