import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import MainHeader from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ProductModel from '../../../components/products/product_model';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { AllProduct } from '../../../data_api/products';
import { useSelector, useDispatch } from 'react-redux';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts
} from '../../../actions/cart';
//import Alert from '../../../layouts/alert/Alert';
// import { setAlert } from '../../../actions/alert';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { productShowInGrid } from '../../../components/utils/LoadScript';
import { addItemToModal } from '../../../actions/modalProduct';

const Shop_Page5 = () => {
  // for language excahnger
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    // run script
    productShowInGrid();
    
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  const alert = useAlert();

  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);

  // get all product from redux store
  const products = useSelector(state => state.cart.products);
  // for Dispatch a fuction
  const dispatch = useDispatch();
  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success('Already in cart!');
      dispatch(increaseItemQuantity(
        productIndex,
        product,
        (itemQty = itemQty + 1)
      ));
    } else {
      dispatch(addItemToCart(product));
      // props.setAlert(
      //   '{product.products_name} has been added to cart',
      //   'success'
      // );
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    dispatch(addToCart());
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }

  };

  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      dispatch(AddWishlistProducts(product));

      alert.success('Successfully added to Wishlist!');
    }
  };

  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);
  

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    
    if (productExists) {
      dispatch(addItemToModal(alreadyModalProduct));
    } else {
      dispatch(addItemToModal(product));
    }
  };

  return (
    <Fragment>

      {/* Paste this code after body tag */}
      {/* <div className="se-pre-con" /> */}
      {/* //Header Style One */}
      <MainHeader onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail} />
      {/* //Sticky Header */}
      <StickyHeader onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail} />
      {/* //Mobile Header */}
      <MobileHeader onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail} />
      {/* Products content */}
      <div className="container-fuild">
        <nav aria-label="breadcrumb">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="#">{t('Home.1')}</Link></li>
              <li className="breadcrumb-item active" aria-current="page">{t('Shop.1')}</li>
            </ol>
          </div>
        </nav>
      </div>
      <section className="pro-content shop-content shop-special">
        <div className="container">
          <div className="row">
            <div className="pro-heading-title">
              <h1>
                {t('Shop Page.1')}
              </h1>
            </div>
          </div>
          <div className="top-bar">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-2">
                    <div className="block">
                      <label>{t('Display.1')}</label>
                      <div className="buttons">
                        <a href="javascript:void(0);" id="grid_3column" className="fas fa-th-large active" ></a>
                        <a href="javascript:void(0);" id="list_3column" className="fas fa-list" ></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <form className="form-inline justify-content-center">
                      <div className="form-group ">
                        <label>{t('Category.1')}</label>
                        <div className="select-control">
                          <select className="form-control ">
                            <option value={1}>{t('Necklace.1')}</option>
                            <option value={2}>{t('Earrings.1')}</option>
                            <option value={3}>{t('Crowns.1')}</option>
                            <option value={4}>{t('Rings.1')}</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label>{t('Price.1')}</label>
                        <div className="select-control">
                          <select className="form-control ">
                            <option value={1}>$1001-$2500</option>
                            <option value={2}>$501-$1000</option>
                            <option value={3}>0-$500</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label>{t('Size.1')}</label>
                        <div className="select-control">
                          <select className="form-control ">
                            <option value={1}>{t('Medium.1')}</option>
                            <option value={2}>{t('Large.1')}</option>
                            <option value={3}>{t('Small.1')}</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-12 col-lg-4">
                    <form className="form-inline justify-content-end">
                      <div className="form-group">
                        <label>{t('Sort by.1')}</label>
                        <div className="select-control">
                          <select className="form-control ">
                            <option selected>{t('Popular.1')}</option>
                            <option value={1}>{t('Newest.1')}</option>
                            <option value={2}>{t('Offers.1')}</option>
                            <option value={3}>{t('Old.1')}</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{t('Items.1')}</label>
                        <div className="select-control">
                          <select className="form-control ">
                            <option selected>08</option>
                            <option value={1}>20</option>
                            <option value={2}>16</option>
                            <option value={3}>12</option>
                            <option value={4}>08</option>
                            <option value={5}>04</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-3  d-lg-block d-xl-block right-menu">
              <div className="accordion shop-bar-categories" id="accordionExample1">
                <div className="card">
                  <div className="card-header" id="CardOne">
                    <Link href className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      {t('Collections.1')}
                    </Link>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="CardOne" data-parent="#accordionExample1">
                    <div className="card-body">
                      <ul className="brands">
                        <li><Link className=" brands-btn" to="#" role="button"><i className="fas fa-angle-right" />{t('Rings.1')}</Link></li>
                        <li><Link className="  brands-btn" to="#" role="button"><i className="fas fa-angle-right" />{t('Earrings.1')}</Link></li>
                        <li><Link className=" brands-btn" to="#" role="button"><i className="fas fa-angle-right" />{t('Crowns.1')}</Link></li>
                        <li><Link className=" brands-btn" to="#" role="button"><i className="fas fa-angle-right" />{t('Necklace.1')}</Link></li>
                        <li><Link className=" brands-btn" to="#" role="button"><i className="fas fa-angle-right" />{t('Bracelet.1')} </Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion shop-bar-categories" id="accordionExample2">
                <div className="card">
                  <div className="card-header" id="CardTwo">
                    <Link href className="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      {t('Price.1')}
                    </Link>
                  </div>
                  <div id="collapseTwo" className="collapse show" aria-labelledby="CardTwo" data-parent="#accordionExample2">
                    <div className="card-body">
                      <ul className="brands">
                        <li><Link className=" brands-btn" to="#" role="button">$10-$100
                      </Link></li>
                        <li><Link className="  brands-btn" to="#" role="button">$100-$200
                      </Link></li>
                        <li><Link className=" brands-btn" to="#" role="button">$200-$300
                      </Link></li>
                        <li><Link className=" brands-btn" to="#" role="button">$300-$400
                      </Link></li>
                        <li><Link className=" brands-btn" to="#" role="button">$400-$500</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion shop-bar-categories" id="accordionExample3">
                <div className="card">
                  <div className="card-header" id="CardThree">
                    <Link href className="mb-0" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">
                      {t('Color Selection.1')}
                    </Link>
                  </div>
                  <div id="collapseThree" className="collapse show" aria-labelledby="CardThree" data-parent="#accordionExample3">
                    <div className="card-body">
                      <div className="pro-options">
                        <div className="color-selection">
                          <ul>
                            <li className="active"><a className="bg-primary " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-secondary " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-success " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-info " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-warning " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-danger " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-light " href="javascript:void(0);" ></a></li>
                            <li><a className="bg-dark " href="javascript:void(0);" ></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion shop-bar-categories" id="accordionExample4">
                <div className="card">
                  <div className="card-header" id="CardFour">
                    <Link href className="mb-0" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">
                      {t('Size Selection.1')}
                    </Link>
                  </div>
                  <div id="collapseFour" className="collapse show" aria-labelledby="CardFour" data-parent="#accordionExample4">
                    <div className="card-body">
                      <div className="pro-options">
                        <div className="size-selection">
                          <ul>
                            <li className="active"><a href="javascript:void(0);" tabIndex={0}>28</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>32</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>34</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>36</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>38</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>40</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>42</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>44</a></li>
                            <li><a href="javascript:void(0);" tabIndex={0}>46</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="img-main">
                <Link to="/shop-page1"><img className="img-fluid" src="assets/images/shop/side-image.jpg" alt="shop" /></Link>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="products-area">
                <div className="row">
                  <div id="swap" className="col-12 col-sm-12">
                    <div className="row">
                      {AllProduct.product_data !== undefined &&
                        AllProduct.product_data.length > 0
                        ? AllProduct.product_data
                          .slice(0, 8)
                          .map((product, index) => {
                            return (

                              <div className="col-12 col-sm-6  col-lg-4" key={index}>
                                <div className="product">
                                  <article>
                                    <div className="pro-thumb ">
                                      <div className="pro-icons mobile-pro-icons d-lg-none d-xl-none">
                                        <div className="icon active swipe-to-top" onClick={() =>
                                          AddToWishList(product)
                                        }>
                                          <i className="fas fa-heart" />
                                        </div>
                                        <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                            ShowProductInModal(product)
                                          }>
                                          <i className="fas fa-eye" />
                                        </div>
                                        <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                      </div>
                                      <Link to="/product-page1">
                                        <span className="pro-image"><img className="img-fluid" src={product.products_image} alt="Product" /></span>
                                        <span className="pro-image-hover swipe-to-top  d-none d-lg-block d-xl-block"><img className="img-fluid" src={product.images} alt="Product" /></span>
                                      </Link>
                                      <div className="pro-buttons d-none d-lg-block d-xl-block">
                                        <div className="pro-icons">
                                          <div className="icon active swipe-to-top" onClick={() =>
                                            AddToWishList(product)
                                          }>
                                            <i className="fas fa-heart" />
                                          </div>
                                          <div className="icon swipe-to-top" data-toggle="modal" data-target="#quickViewModal" onClick={() =>
                                            ShowProductInModal(product)
                                          }>
                                            <i className="fas fa-eye" />
                                          </div>
                                          <Link to="/compare" className="icon swipe-to-top"><i className="fas fa-align-right" data-fa-transform="rotate-90" /></Link>
                                        </div>
                                        <button type="button" className="btn btn-secondary btn-block swipe-to-top" onClick={() =>
                                          addAndUpdatenTheCart(product)
                                        }>{t('Add to Cart.1')}</button>
                                      </div>
                                    </div>
                                    <div className="pro-description">
                                      <span className="pro-info">
                                        {t(`${product.categories[0]
                                          .categories_name
                                          }.1`)}
                                      </span>
                                      <h2 className="pro-title"><Link to="/product-page1">{t(`${product.products_name}.1`)}</Link></h2>
                                      <div className="pro-price">
                                        <ins> {currencyDetail.symbolLeft}
                                          {(
                                            product.products_price *
                                            currencyDetail.value
                                          ).toFixed(2)}</ins>
                                      </div>
                                      <div className="list2">
                                        <div className="pro-options">
                                          <div className="color-selection">
                                            <h4>{t('Color.1')}: <span>{t('Silver.1')}</span></h4>
                                            <ul>
                                              <li className="active"><a className="green " href="javascript:void(0);" tabIndex={0} ></a></li>
                                              <li><a className="red " href="javascript:void(0);" tabIndex={0} ></a></li>
                                            </ul>
                                          </div>
                                          <div className="size-selection">
                                            <h4>{t('Size.1')}: <span>28</span></h4>
                                            <ul>
                                              <li className="active"><a href="javascript:void(0);" tabIndex={0}>28</a></li>
                                              <li><a href="javascript:void(0);" tabIndex={0}>32</a></li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pro-mobile-buttons d-lg-none d-xl-none">
                                        <button type="button" className="btn btn-secondary btn-block swipe-to-top" onClick={() =>
                                          addAndUpdatenTheCart(product)
                                        }>{t('Add to Cart.1')}</button>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              </div>
                            );
                          })
                        : 'null'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination justify-content-between ">
                <label className="col-form-label">{t('Showing.1')} 1–<span className="showing_record">1</span>&nbsp;{t('of.1')}&nbsp;<span className="showing_total_record">23</span>&nbsp;{t('results.1')}.</label>
                <div className="col-12 col-sm-6">
                  <ol className="loader-page">
                    <li className="loader-page-item "><Link to="#">
                      <i className="fa fa-angle-double-left" style={{ fontSize: 12 }} /></Link>
                    </li>
                    <li className="loader-page-item"><Link to="#">1</Link></li>
                    <li className="loader-page-item "><Link to="#">2</Link></li>
                    <li className="loader-page-item "><Link to="#">3</Link></li>
                    <li className="loader-page-item "><Link to="#">4</Link></li>
                    <li className="loader-page-item "><Link to="#">
                      <i className="fa fa-angle-double-right" style={{ fontSize: 12 }} /></Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product Modal */}
      <ProductModel currencyDetails={currencyDetail} />
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />



    </Fragment>
  )
}

export default Shop_Page5
