import React, { Fragment, useEffect } from 'react';
import { switcher } from '../utils/LoadScript';
import { useTranslation } from 'react-i18next';

const Switcher = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // load switcher script
    switcher();
  }, []);
  return (
    <Fragment>
      <div className='switcher'>
        <button type='button' className='btn slide-toggle'>
          <i className='fa fa-cogs' aria-hidden='true' />
        </button>
        <a href='#' className='swticher-rtl'>
          <div className='text '>{t('Use demo with RTL.1')}</div>
          <div className='toggle'>
            <span className='circle ' />
          </div>
        </a>
        <a href='#' className='swticher-boxed '>
          <div className='text '>{t('Use demo with Box.1')}</div>
          <div className='toggle'>
            <span className='circle' />
          </div>
        </a>
        <div className='swicher-color'>
          <div className='text '>
            <b>{t('Use demo with Colors.1')}</b>
          </div>
          <ul id='switchColor'>
            <li className='active'>
              <a href='#' id='default'>
                <img src='assets/images/colors/default.png' alt='icon' />
              </a>
            </li>
            <li>
              <a href='#' id='yellow'>
                <img src='assets/images/colors/yellow.png' alt='icon' />
              </a>
            </li>
            <li>
              <a href='#' id='blue'>
                <img src='assets/images/colors/blue.png' alt='icon' />
              </a>
            </li>
            <li>
              <a href='#' id='green'>
                <img src='assets/images/colors/green.png' alt='icon' />
              </a>
            </li>
            <li>
              <a href='#' id='navy-blue'>
                <img src='assets/images/colors/navy-blue.png' alt='icon' />
              </a>
            </li>
            <li>
              <a href='#' id='red'>
                <img src='assets/images/colors/red.png' alt='icon' />
              </a>
            </li>
            <li>
              <a href='#' id='pink'>
                <img src='assets/images/colors/pink.png' alt='icon' />
              </a>
            </li>
          </ul>
          <div className='text mt-2 mb-0'>
            {t(
              'Template built with sass, you can use desire primary and secondary colors of your choice.1'
            )}
            .
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Switcher;
