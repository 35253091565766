import React, { Fragment, useEffect } from 'react';
import { scrollToTop } from '../utils/LoadScript';
const Arrow_BackToTop = () => {
  useEffect(() => {
    // load scrollToTop script
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <div className='mobile-overlay' />
      <div
        //href='javascript:void();'
        id='back-to-top'
        className='swipe-to-top'
        title='Back to top'
      >
        ↑
      </div>
    </Fragment>
  );
};

export default Arrow_BackToTop;
