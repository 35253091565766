import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AllProduct } from '../../../data_api/products';
import { useSelector, useDispatch } from 'react-redux';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts
} from '../../../actions/cart';
import { useTranslation } from 'react-i18next';
import { addItemToModal } from '../../../actions/modalProduct';
import { tabCarouselSlider } from '../../utils/LoadScript';

import { useAlert } from 'react-alert';

const Related_Product = props => {
  // for Slider
  useEffect(() => {
    // for tab carousel
    tabCarouselSlider();
  }, []);

  const alert = useAlert();
  const { t } = useTranslation();
  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);

  // get all product from redux store
  const products = useSelector(state => state.cart.products);
  // for Dispatch a fuction
  const dispatch = useDispatch();
  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      dispatch(
        increaseItemQuantity(productIndex, product, (itemQty = itemQty + 1))
      );
    } else {
      dispatch(addItemToCart(product));
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    dispatch(addToCart());
  };

  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);
  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      dispatch(AddWishlistProducts(product));

      alert.success('Successfully added to Wishlist!');
    }
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    console.log('already product exist', alreadyModalProduct);

    if (productExists) {
      dispatch(addItemToModal(alreadyModalProduct));
    } else {
      dispatch(addItemToModal(product));
    }
  };

  return (
    <Fragment>
      <div className='related-product-content'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-6'>
            <div className='pro-heading-title'>
              <h1> {t('Related Products.1')}</h1>
            </div>
          </div>
        </div>
        <div className='tab-carousel-js row'>
          {AllProduct.product_data !== undefined &&
          AllProduct.product_data.length > 0
            ? AllProduct.product_data.slice(0, 8).map((product, index) => {
                return (
                  <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                    <div className='product'>
                      <article>
                        <div className='pro-icons mobile-pro-icons d-lg-none d-xl-none'>
                          <div
                            //to='/wishlist'
                            className='icon active swipe-to-top'
                            onClick={() => AddToWishList(product)}
                          >
                            <i className='fas fa-heart' />
                          </div>
                          <div
                            className='icon swipe-to-top'
                            data-toggle='modal'
                            data-target='#quickViewModal'
                            onClick={() => ShowProductInModal(product)}
                          >
                            <i className='fas fa-eye' />
                          </div>
                          <Link to='/compare' className='icon swipe-to-top'>
                            <i
                              className='fas fa-align-right'
                              data-fa-transform='rotate-90'
                            />
                          </Link>
                        </div>
                        <div className='pro-thumb '>
                          <div className='pro-tag bg-success'>{t('NEW.1')}</div>
                          <Link to='/product-page1'>
                            <span className='pro-image'>
                              <img
                                className='img-fluid'
                                src={product.products_image}
                                alt='Product'
                              />
                            </span>
                            <span className='pro-image-hover swipe-to-top  d-none d-lg-block d-xl-block'>
                              <img
                                className='img-fluid'
                                src={product.images}
                                alt='Product'
                              />
                            </span>
                          </Link>
                          <div className='pro-buttons d-none d-lg-block d-xl-block'>
                            <div className='pro-icons'>
                              <div
                                className='icon active swipe-to-top'
                                onClick={() => AddToWishList(product)}
                              >
                                <i className='fas fa-heart' />
                              </div>
                              <div
                                className='icon swipe-to-top'
                                data-toggle='modal'
                                data-target='#quickViewModal'
                                onClick={() => ShowProductInModal(product)}
                              >
                                <i className='fas fa-eye' />
                              </div>
                              <Link to='/compare' className='icon swipe-to-top'>
                                <i
                                  className='fas fa-align-right'
                                  data-fa-transform='rotate-90'
                                />
                              </Link>
                            </div>
                            <button
                              type='button'
                              className='btn btn-secondary btn-block swipe-to-top'
                              onClick={() => addAndUpdatenTheCart(product)}
                            >
                              {t('Add to Cart.1')}
                            </button>
                          </div>
                        </div>
                        <div className='pro-description'>
                          <span className='pro-info'>
                            {t(`${product.categories[0].categories_name}.1`)}
                          </span>
                          <h2 className='pro-title'>
                            <Link to='/product-page1'>
                              {t(`${product.products_name}.1`)}
                            </Link>
                          </h2>
                          <div className='pro-price'>
                            <ins>
                              {props.currencyDetails.symbolLeft}
                              {(
                                product.products_price *
                                props.currencyDetails.value
                              ).toFixed(2)}
                            </ins>
                          </div>
                          <div className='pro-mobile-buttons d-lg-none d-xl-none'>
                            <button
                              type='button'
                              className='btn btn-secondary btn-block swipe-to-top'
                              onClick={() => addAndUpdatenTheCart(product)}
                            >
                              {t('Add to Cart.1')}
                            </button>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                );
              })
            : 'null'}
        </div>
      </div>
    </Fragment>
  );
};

export default Related_Product;
