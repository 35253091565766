import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const FullScreen_banner = props => {
  // for language excahnger
  const { t } = useTranslation();

  return (
    <Fragment>
      <section className='pro-content pro-full-screen-banner'>
        <div
          className='pro-full-bg'
          style={{ backgroundImage: `url(${props.image})` }}
        ></div>
        <div className='pro-full-screen-overlay'>
          <div className='pro-full-screen'>
            <div className='pro-heading-title'>
              <h2> {t('The Perfect Match.1')}</h2>
              <p>
                {t(
                  'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                )}
              </p>
            </div>
            <button className='btn btn-secondary swipe-to-top'>
              {t('Shop Now.1')}
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default FullScreen_banner;
