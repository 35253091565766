import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Special_offer = props => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <section className='pro-content pro-sp-content'>
        <div className='container'>
          <div className='products-area '>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-7 order-2 order-lg-1'>
                <div className='pro-description'>
                  <span className='pro-info'>
                    {t('Super deal of the Month.1')}
                  </span>
                  <h2 className='pro-title'>
                    <Link to='/product-page1'>
                      {t('Women Zircon Crystal Engagement Ring Now.1')}
                    </Link>
                  </h2>
                  <div className='pro-price'>
                    <del>
                      {props.currencyDetails.symbolLeft}
                      {180 * props.currencyDetails.value}
                    </del>
                    <ins>
                      {props.currencyDetails.symbolLeft}
                      {250 * props.currencyDetails.value}
                    </ins>
                  </div>
                  <div className='countdown pro-timer pro-single-timer'>
                    <span className='days' />
                    <span className='hours' />
                    <span className='mintues' />
                    <span className='seconds' />
                  </div>
                  <div className='pro-btns'>
                    <button
                      type='button'
                      className='btn btn-secondary swipe-to-top'
                    >
                      {t('grab now.1')}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-5 order-1 order-lg-2'>
                <div className='pro-thumb '>
                  <Link to='/product-page1'>
                    <span className='pro-image'>
                      <img
                        className='img-fluid'
                        src='assets/images/product_images/deals_of_month.png'
                        alt='Product'
                      />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Special_offer;
