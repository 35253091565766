export const AllProduct = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 1,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_01.jpg',
      products_price: 285,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Austrian Crystals Engagement Jewelry Ring',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_01_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Ring Collection',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 2,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_02.jpg',
      products_price: 285,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen-1',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: 'Crystal Water Drop Wedding Function  Earrings',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_02_02.jpg'],
      categories: [
        {
          categories_id: 12,
          categories_name: 'Earrings',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 3,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_03.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mobile-phone-tablet-pc',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: 'Crystal Wedding Function Rings',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_03_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Ring Collection',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 4,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_04.jpg',
      products_price: 110,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'apple-iphone-6s-factory-unlock',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: 'Women Crystal Zircon Engagement And Wedding Ring',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_04_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Rings',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 5,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_06.jpg',
      products_price: 185,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'electric-steam-irons',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: 'Vienois Rose Gold Circle Bangle for Wedding',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_06_02.jpg'],
      categories: [
        {
          categories_id: 15,
          categories_name: 'Bangle',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 6,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_05.jpg',
      products_price: 81,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '2000',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 35,
      language_id: 1,
      products_name: 'Bracelet Women Metal Chain for Engagement',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_05_02.jpg'],
      categories: [
        {
          categories_id: 18,
          categories_name: 'Bracelet',
          categories_image: '83',
          categories_icon: '89',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 7,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_07.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '250',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'gaming-headset-deep-bass',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 33,
      language_id: 1,
      products_name: 'Gold Rhinestone Pageant Crown for Women',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_07_02.jpg'],
      categories: [
        {
          categories_id: 17,
          categories_name: 'Crown',
          categories_image: '83',
          categories_icon: '89',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 8,
      products_quantity: 0,
      products_model: null,
      products_image: 'assets/images/product_images/product_image_08.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mini-triangle-sandwich-maker',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 31,
      language_id: 1,
      products_name: 'Romantic Love Hollow Heart Necklace for Women',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images/product_image_08_02.jpg'],
      categories: [
        {
          categories_id: 16,
          categories_name: 'Necklace',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ],
  message: 'Returned all products.',
  total_record: 20
};

export const AllProduct_Home2 = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 9,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_01.jpg',
      products_price: 900,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Original Samsung Galaxy S8 Plus',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_01_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Mobiles and Tablets',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 10,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_02.jpg',
      products_price: 1185,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen-1',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: '15-6inch Gaming Notebook Laptop',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_02_02.jpg'],
      categories: [
        {
          categories_id: 12,
          categories_name: 'Computers & Accessories',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 11,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_04.jpg',
      products_price: 755,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mobile-phone-tablet-pc',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: 'Smart Watches With Heart Beat And ECG',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_04_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Watches',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 12,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_03.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'apple-iphone-6s-factory-unlock',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: '3D Stereo Wireless Earphone For Laptop',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_03_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'CAMERAS, AUDIO & VIDEO',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 13,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_05.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'electric-steam-irons',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: 'Branded Electric Steam Irons For Cloth',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_05_02.jpg'],
      categories: [
        {
          categories_id: 15,
          categories_name: 'Home Applainces',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 14,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_06.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '2000',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 35,
      language_id: 1,
      products_name: 'Mini Triangle Sandwich Maker For Delicious Sandwich',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_06_02.jpg'],
      categories: [
        {
          categories_id: 18,
          categories_name: 'Kitchen Applainces',
          categories_image: '83',
          categories_icon: '89',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 15,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_07.jpg',
      products_price: 80,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '250',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'gaming-headset-deep-bass',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 33,
      language_id: 1,
      products_name: 'Gaming Headset Deep Base For Laptop',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_07_02.jpg'],
      categories: [
        {
          categories_id: 17,
          categories_name: 'Music and Video Games',
          categories_image: '83',
          categories_icon: '89',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 16,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_08.jpg',
      products_price: 1100,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mini-triangle-sandwich-maker',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 31,
      language_id: 1,
      products_name: 'Slim LCD Touch Screen With High Quality Resolution',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_08_02.jpg'],
      categories: [
        {
          categories_id: 16,
          categories_name: 'LCD',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ],
  message: 'Returned all products.',
  total_record: 20
};

export const allcategories = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 17,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_06.jpg',
      products_price: 450,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Printed chiffon coat',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_06_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Winter Wear',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 18,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_13.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen-1',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: 'Princess Flower Girl Dress',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_13_02.jpg'],
      categories: [
        {
          categories_id: 12,
          categories_name: 'Girl Dresses',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 19,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_11.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mobile-phone-tablet-pc',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: 'Strip Tshirt for Women',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_11_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Tops',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 20,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_08.jpg',
      products_price: 452,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'apple-iphone-6s-factory-unlock',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: 'Double Tank Trouser',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_08_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Bottoms',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 21,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_01.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'electric-steam-irons',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: 'Carrot Fitt Jeans',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_01_02.jpg'],
      categories: [
        {
          categories_id: 15,
          categories_name: 'Men Jeans',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ],
  message: 'Returned all products.',
  total_record: 20
};

export const MensProducts = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 22,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_06.jpg',
      products_price: 450,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Printed chiffon coat',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_06_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Winter Wear',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 23,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_01.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen-1',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: 'Carrot Fitt Jeans',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_01_02.jpg'],
      categories: [
        {
          categories_id: 12,
          categories_name: 'Men Jeans',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 24,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_04.jpg',
      products_price: 20,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mobile-phone-tablet-pc',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: 'Basketball Style Tshirt',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_04_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'T-Shirts',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 25,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_02.jpg',
      products_price: 185,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'apple-iphone-6s-factory-unlock',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: 'Denim Jeans for Men',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_02_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Men Jeans',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 26,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_05.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'electric-steam-irons',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: 'Classic Curves Long Jacket',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_05_02.jpg'],
      categories: [
        {
          categories_id: 15,
          categories_name: 'Winter Wear',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ],
  message: 'Returned all products.',
  total_record: 20
};

export const WomenProducts = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 27,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_07.jpg',
      products_price: 342,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Denim Skirt for Women',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_07_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Bottoms',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 28,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_12.jpg',
      products_price: 455,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen-1',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: 'Straight Long Coat',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_12_02.jpg'],
      categories: [
        {
          categories_id: 12,
          categories_name: 'Winter Wear',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 29,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_11.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mobile-phone-tablet-pc',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: 'Strip Tshirt for Women',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_11_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Tops',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 30,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_08.jpg',
      products_price: 452,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'apple-iphone-6s-factory-unlock',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: 'Double Tank Trouser',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_08_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Bottoms',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 31,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_09.jpg',
      products_price: 950,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'electric-steam-irons',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: 'Flower Dress for Women',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_09_02.jpg'],
      categories: [
        {
          categories_id: 15,
          categories_name: 'Tops',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ],
  message: 'Returned all products.',
  total_record: 20
};

export const KidsProduct = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 32,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_13.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Princess Flower Girl Dress',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_13_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Girl Dresses',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 33,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_14.jpg',
      products_price: 15,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'slim-lcd-touch-screen-1',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: 'Bow Print Long Sleeve',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_14_02.jpg'],
      categories: [
        {
          categories_id: 12,
          categories_name: 'Girl Dresses',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 34,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_15.jpg',
      products_price: 175,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'mobile-phone-tablet-pc',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: 'Casual Breathable Sneakers',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_15_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Shoes',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 35,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_16.jpg',
      products_price: 85,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '500',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'apple-iphone-6s-factory-unlock',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: 'Kids Sport Shoes Boys',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_16_02.jpg'],
      categories: [
        {
          categories_id: 11,
          categories_name: 'Shoes',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    },
    {
      server_time: 1576216365,
      products_id: 36,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_3/product_image_17.jpg',
      products_price: 252,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '300',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'electric-steam-irons',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: 'Casual Tracksuit Children Boy',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_3/product_image_17_02.jpg'],
      categories: [
        {
          categories_id: 15,
          categories_name: 'Boy Dresses',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ],
  message: 'Returned all products.',
  total_record: 20
};

export const DetailedProduct = {
  success: '1',
  product_data: [
    {
      server_time: 1576216365,
      products_id: 37,
      products_quantity: 0,
      products_model: null,
      products_image:
        'assets/images/product_images_demo_2/product_image_01.jpg',
      products_price: 900,
      products_date_added: '0000-00-00 00:00:00',
      products_last_modified: null,
      products_date_available: null,
      products_weight: '200',
      products_weight_unit: 'Gram',
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      products_slug: 'smartwatch-motorola-moto-360',
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: 'Original Samsung Galaxy S8 Plus',
      products_description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      products_url: null,
      products_viewed: 0,
      products_left_banner: '',
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: '',
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: 'usd',
      images: ['assets/images/product_images_demo_2/product_image_01_02.jpg'],
      categories: [
        {
          categories_id: 14,
          categories_name: 'Mobiles and Tablets',
          categories_image: '83',
          categories_icon: '83',
          parent_id: 0
        }
      ],
      rating: '0.00',
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: '0',
      attributes: [],
      quantity: 1
    }
  ]
};
