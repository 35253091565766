import React, { Fragment, useState, useEffect } from 'react';
import MainHeader from '../../layouts/header/main-header/header-one';
import { currency } from '../../components/utils/currency_array';
import StickyHeader from '../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../layouts/footer/mobile-footer';
import MainFooter from '../../layouts/footer/main-footer';
import ArrowBackToTop from '../../components/arrow-backToTop';
import Switcher from '../../components/switcher';
import NewsLetterModal from '../../components/newslatter-modal';
import Cookies from '../../components/cookies';
import { useTranslation } from 'react-i18next';

const Term = () => {
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <MainHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      <section className='pro-content page-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Terms of Service.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-sm-12'>
              <p className='text-wrap'>
                {t(
                  'Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}

                <br />
                {t(
                  'Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}

                <br />
                {t(
                  'Sed convallis magna in tortor fermentum, sit amet accumsan diam malesuada, Aliquam urna turpis, sodales et tempor nec, dignissim eu odio, Vivamus et mattis urna, Praesent ullamcorper ut est non lobortis, Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}
                <br />
                {t(
                  'Sed convallis magna in tortor fermentum, sit amet accumsan diam malesuada, Aliquam urna turpis, sodales et tempor nec, dignissim eu odio, Vivamus et mattis urna, Praesent ullamcorper ut est non lobortis, Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}
              </p>
              <h2>{t('sit amet accumsan diam malesuada.1')}</h2>
              <p className='text-wrap'>
                {t(
                  'Sed convallis magna in tortor fermentum, sit amet accumsan diam malesuada, Aliquam urna turpis, sodales et tempor nec, dignissim eu odio, Vivamus et mattis urna, Praesent ullamcorper ut est non lobortis, Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus.1'
                )}
                <br />
                {t(
                  'Sed convallis magna in tortor fermentum, sit amet accumsan diam malesuada, Aliquam urna turpis, sodales et tempor nec, dignissim eu odio, Vivamus et mattis urna, Praesent ullamcorper ut est non lobortis, Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Term;
