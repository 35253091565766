import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AllProduct_Home2 } from '../../../data_api/products';
import { useSelector, useDispatch } from 'react-redux';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts
} from '../../../actions/cart';
import { useAlert } from 'react-alert';
import { addItemToModal } from '../../../actions/modalProduct';

const Products = props => {
  const { t } = useTranslation();
  // for show alert
  const alert = useAlert();
  // for dispatch action
  const dispatch = useDispatch();

  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);

  // get all product from redux store
  const products = useSelector(state => state.cart.products);
  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);

  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      dispatch(
        increaseItemQuantity(productIndex, product, (itemQty = itemQty + 1))
      );
    } else {
      dispatch(addItemToCart(product));
      alert.success('Successfully added to cart!');
    }

    // to add the product in localstorage
    dispatch(addToCart());
  };

  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      dispatch(AddWishlistProducts(product));

      alert.success('Successfully added to Wishlist!');
    }
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    if (productExists) {
      dispatch(addItemToModal(alreadyModalProduct));
    } else {
      dispatch(addItemToModal(product));
    }
  };

  return (
    <Fragment>
      <section className='pro-content pro-plr-content '>
        <div className='container'>
          <div className='products-area'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-12'>
                <div className='pro-heading-title'>
                  <h2>{t('Welcome to Store.1')}</h2>
                  <p>
                    {t(
                      'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className=' row'>
              {AllProduct_Home2.product_data !== undefined &&
              AllProduct_Home2.product_data.length > 0
                ? AllProduct_Home2.product_data.map((product, index) => {
                    return (
                      <div
                        className='col-12 col-md-6 col-lg-4 col-xl-3'
                        key={index}
                      >
                        <div className='product'>
                          <article>
                            <div className='pro-icons mobile-pro-icons d-lg-none d-xl-none'>
                              <div
                                className='icon active swipe-to-top'
                                onClick={() => AddToWishList(product)}
                              >
                                <i className='fas fa-heart' />
                              </div>
                              <div
                                className='icon swipe-to-top'
                                data-toggle='modal'
                                data-target='#quickViewModal'
                                onClick={() => ShowProductInModal(product)}
                              >
                                <i className='fas fa-eye' />
                              </div>
                              <Link
                                to={`${process.env.PUBLIC_URL}/compare`}
                                className='icon swipe-to-top'
                              >
                                <i
                                  className='fas fa-align-right'
                                  data-fa-transform='rotate-90'
                                />
                              </Link>
                            </div>
                            <div className='pro-thumb '>
                              <Link
                                to={`${process.env.PUBLIC_URL}/product-page1`}
                              >
                                <span className='pro-image'>
                                  <img
                                    className='img-fluid'
                                    src={product.products_image}
                                    alt='Product'
                                  />
                                </span>
                                <span className='pro-image-hover swipe-to-top'>
                                  <img
                                    className='img-fluid'
                                    src={product.images[0]}
                                    alt='Product'
                                  />
                                </span>
                              </Link>
                              <div className='pro-buttons d-none d-lg-block d-xl-block'>
                                <div className='pro-icons'>
                                  <div
                                    className='icon active swipe-to-top'
                                    onClick={() => AddToWishList(product)}
                                  >
                                    <i className='fas fa-heart' />
                                  </div>
                                  <div
                                    className='icon swipe-to-top'
                                    data-toggle='modal'
                                    data-target='#quickViewModal'
                                    onClick={() => ShowProductInModal(product)}
                                  >
                                    <i className='fas fa-eye' />
                                  </div>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/compare`}
                                    className='icon swipe-to-top'
                                  >
                                    <i
                                      className='fas fa-align-right'
                                      data-fa-transform='rotate-90'
                                    />
                                  </Link>
                                </div>
                                <Link
                                  className='btn btn-secondary btn-block swipe-to-top'
                                  onClick={() => addAndUpdatenTheCart(product)}
                                >
                                  {t('Add to Cart.1')}
                                </Link>
                              </div>
                              <div className='pro-tag'>{t('Sale.1')}</div>
                            </div>
                            <div className='pro-description'>
                              <span className='pro-info'>
                                {t(
                                  `${product.categories[0].categories_name}.1`
                                )}
                              </span>
                              <h2 className='pro-title'>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/product-page1`}
                                >
                                  {t(`${product.products_name}.1`)}
                                </Link>
                              </h2>
                              <div className='pro-price'>
                                <del>
                                  {props.currencyDetails.symbolLeft}
                                  {(999 * props.currencyDetails.value).toFixed(
                                    2
                                  )}
                                </del>
                                <ins>
                                  {props.currencyDetails.symbolLeft}
                                  {(
                                    product.products_price *
                                    props.currencyDetails.value
                                  ).toFixed(2)}
                                </ins>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    );
                  })
                : 'null'}
            </div>
          </div>
        </div>
      </section>
      <section className='pro-content pro-mini-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <div className='pro-banner-text'>
                <div className='pro-banner-thumb'>
                  <img
                    className='img-fluid '
                    src='assets/images/categories/category_image_6.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-image-text'>
                  <h4>
                    {t('Game.1')}
                    <br />
                    {t('Accessories.1')}
                  </h4>
                  <Link
                    to={`${process.env.PUBLIC_URL}/#`}
                    className='btn btn-secondary swipe-to-top'
                  >
                    {t('Shop Now.1')}
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='pro-banner-text'>
                <div className='pro-banner-thumb'>
                  <img
                    className='img-fluid '
                    src='assets/images/categories/category_image_3.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-image-text'>
                  <h4>
                    {t('Multimedia.1')}
                    <br />
                    {t('Devices.1')}
                  </h4>
                  <Link
                    to={`${process.env.PUBLIC_URL}/#`}
                    className='btn btn-secondary swipe-to-top'
                  >
                    {t('Shop Now.1')}
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='pro-banner-text'>
                <div className='pro-banner-thumb'>
                  <img
                    className='img-fluid '
                    src='assets/images/categories/category_image_2.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-image-text'>
                  <h4>
                    {t('Computers.1')}
                    <br />
                    {t('Accessories.1')}
                  </h4>
                  <Link
                    to={`${process.env.PUBLIC_URL}/#`}
                    className='btn btn-secondary swipe-to-top'
                  >
                    {t('Shop Now.1')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Products;
