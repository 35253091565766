import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { aboutUSCarouselSlider } from '../../utils/LoadScript';

const Our_Team = () => {
  const { t } = useTranslation();
  // for Slider
  useEffect(() => {
    // for  carousel
    aboutUSCarouselSlider();
  }, []);

  return (
    <Fragment>
      <div className='conatiner-fluid '>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-6'>
              <div className='pro-heading-title'>
                <h1>{t('Our Team.1')}</h1>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div
                className='aboutUs-carousel-js row'
                style={{ marginBottom: 10 }}
              >
                <div className='col-12 col-md-3'>
                  <div className='team-member'>
                    <article>
                      <div className='team-thumb'>
                        <img
                          className='img-fluid'
                          src='assets/images/about-us/14_01_about.jpg'
                          alt='Member'
                        />
                      </div>
                      <div className='team-info'>
                        <h3>Charlis</h3>
                        <p>{t('Chief Officer.1')}</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className='col-12 col-md-3'>
                  <div className='team-member'>
                    <article>
                      <div className='team-thumb'>
                        <img
                          className='img-fluid'
                          src='assets/images/about-us/14_02_about.jpg'
                          alt='Member'
                        />
                      </div>
                      <div className='team-info'>
                        <h3>John Doe</h3>
                        <p>{t('Sales Executive.1')}</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className='col-12 col-md-3'>
                  <div className='team-member'>
                    <article>
                      <div className='team-thumb'>
                        <img
                          className='img-fluid'
                          src='assets/images/about-us/14_03_about.jpg'
                          alt='Member'
                        />
                      </div>
                      <div className='team-info'>
                        <h3>Theresa May</h3>
                        <p>{t('Markting Officer.1')}</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className='col-12 col-md-3'>
                  <div className='team-member'>
                    <article>
                      <div className='team-thumb'>
                        <img
                          className='img-fluid'
                          src='assets/images/about-us/14_04_about.jpg'
                          alt='Member'
                        />
                      </div>
                      <div className='team-info'>
                        <h3>Xavior</h3>
                        <p>{t('Manager.1')}</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className='col-12 col-md-3'>
                  <div className='team-member'>
                    <article>
                      <div className='team-thumb'>
                        <img
                          className='img-fluid'
                          src='assets/images/about-us/14_05_about.jpg'
                          alt='Member'
                        />
                      </div>
                      <div className='team-info'>
                        <h3>Malissa</h3>
                        <p>{t('Product Manager.1')}</p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Our_Team;
