import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Papular_Categories = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <section className='pro-content pro-bc-content'>
        <div className='container'>
          <div className='products-area'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-12'>
                <div className='pro-heading-title'>
                  <h2> {t('Popular Categories.1')}</h2>
                  <p>
                    {t(
                      'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='pro-main'>
                <figure className='banner-image'>
                  <Link to='/shop-page1'>
                    <img
                      className='img-fluid'
                      src='assets/images/banners_demo_3/banner_5.jpg'
                      alt='Banner'
                    />
                  </Link>
                </figure>
                <div className='pro-description'>
                  <h2 className='pro-title'>{t('Sunglasses.1')}</h2>
                  <p>{t('Discover the new collection.1')}</p>
                  <Link
                    to='#'
                    className='btn btn-secondary swipe-to-top'
                    tabIndex={0}
                  >
                    {t('Discover Now.1')}
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='pro-main last'>
                <figure className='banner-image'>
                  <Link to='/shop-page1'>
                    <img
                      className='img-fluid'
                      src='assets/images/banners_demo_3/banner_4.jpg'
                      alt='Banner'
                    />
                  </Link>
                </figure>
                <div className='pro-description'>
                  <h2 className='pro-title'>{t('Sneakers.1')}</h2>
                  <p>{t('Protect your every step.1')}</p>
                  <Link
                    to='#'
                    className='btn btn-secondary swipe-to-top'
                    tabIndex={0}
                  >
                    {t('Discover Now.1')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Papular_Categories;
