export const currency = [
  {
    title: 'U.S. Dollar',
    code: 'USD',
    symbolRight: '',
    symbolLeft: '$',
    decimalPlaces: '2',
    value: 1
  },
  {
    title: 'EURO',
    code: 'EURO',
    symbolRight: '',
    symbolLeft: '€',
    decimalPlaces: '2',
    value: 3.66
  }
];
