import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Order_Detail = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/*My Order Content */}
      <section className='pro-content order-detail-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Order Information.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-3 '>
              <ul className='list-group mb-4'>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/profile'>
                    <i className='fas fa-user' />
                    {t('Profile.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/wishlist'>
                    <i className='fas fa-heart' />
                    {t('Wishlist.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/orders'>
                    <i className='fas fa-shopping-cart' />
                    {t('Orders.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/shipping-address'>
                    <i className='fas fa-map-marker-alt' />
                    {t('Shipping Address.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='#'>
                    <i className='fas fa-power-off' />
                    {t('Logout.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/change-password'>
                    <i className='fas fa-unlock-alt' />
                    {t('Change Password.1')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-12 col-lg-9 '>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <h4>{t('Order ID 35468430.1')}</h4>
                  <table className='table order-id'>
                    <tbody>
                      <tr className='d-flex'>
                        <td className='col-6 col-md-6 pb-0'>
                          <strong>{t('Order Status.1')}</strong>
                        </td>
                        <td className='pending col-6 col-md-6 pb-0'>
                          <p>{t('Pending.1')}</p>
                        </td>
                      </tr>
                      <tr className='d-flex'>
                        <td className='col-6 col-md-6 '>
                          <strong>{t('Order Date.1')}</strong>
                        </td>
                        <td className='col-6 col-md-6'>28/04/2019</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-12 col-md-6'>
                  <h4>{t('Payment Methods.1')}</h4>
                  <table className='table order-id'>
                    <tbody>
                      <tr className='d-flex'>
                        <td className='col-6 pb-0'>
                          <strong>{t('Shipping Method.1')}</strong>
                        </td>
                        <td className='col-6 pb-0'>{t('Flat Rate.1')}</td>
                      </tr>
                      <tr className='d-flex'>
                        <td className='col-6'>
                          <strong>{t('Payment Method.1')}</strong>
                        </td>
                        <td className='underline col-6'>
                          {t('Cash on Delivery.1')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <h4>{t('Shipping Details.1')}</h4>
                  <table className='table order-id'>
                    <tbody>
                      <tr className='d-flex'>
                        <td className='address col-12 col-md-6 pb-0'>
                          <strong>{t('Shipping Address.1')}</strong>
                        </td>
                      </tr>
                      <tr className='d-flex'>
                        <td className='address col-12 col-md-12'>
                          {t(
                            'Address Details, Near, City Name, Country Name.1'
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-12 col-md-6'>
                  <h4>{t('Billing Details.1')}</h4>
                  <table className='table order-id'>
                    <tbody>
                      <tr className='d-flex'>
                        <td className='address col-12 pb-0'>
                          <strong>{t('Shipping Address.1')}</strong>
                        </td>
                      </tr>
                      <tr className='d-flex'>
                        <td className='address col-12'>
                          {t(
                            'Address Details, Near, City Name, Country Name.1'
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <table className='table top-table'>
                <thead>
                  <tr className='d-flex'>
                    <th className='col-12 col-md-2'>{t('ITEM(S).1')}</th>
                    <th className='col-12 col-md-4' />
                    <th className='col-12 col-md-2'>{t('PRICE.1')}</th>
                    <th className='col-12 col-md-2'>{t('QTY.1')}</th>
                    <th className='col-12 col-md-2'>{t('SUBTOTAL.1')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='d-flex'>
                    <td className='col-12 col-md-2'>
                      <img
                        className='img-fluid'
                        src='assets/images/product_images/product_image_02.jpg'
                        alt='Product'
                      />
                    </td>
                    <td className='col-12 col-md-4'>
                      <div className='item-detail'>
                        <span className='pro-info'>{t('Earrings.1')}</span>
                        <h2 className='pro-title'>
                          <Link to='#'>
                            {t('Crytal Wedding Engagement Rings.1')}
                          </Link>
                        </h2>
                        <div className='item-attributes' />
                      </div>
                    </td>
                    <td className='col-12 col-md-2'>
                      <span className='item-price'>$285</span>
                    </td>
                    <td className='col-12 col-md-2'>
                      <div className='input-group '> 2</div>
                    </td>
                    <td className='col-12 col-md-2'>
                      <span className='item-price'>$540</span>
                    </td>
                  </tr>
                  <tr className='d-flex'>
                    <td className='col-12 col-md-2'>
                      <img
                        className='img-fluid'
                        src='assets/images/product_images/product_image_02.jpg'
                        alt='Product'
                      />
                    </td>
                    <td className='col-12 col-md-4'>
                      <div className='item-detail'>
                        <span className='pro-info'>{t('Earrings.1')}</span>
                        <h2 className='pro-title'>
                          <Link to='#'>
                            {t('Crytal Wedding Engagement Rings.1')}
                          </Link>
                        </h2>
                        <div className='item-attributes' />
                      </div>
                    </td>
                    <td className='col-12 col-md-2'>
                      <span className='item-price'>$85</span>
                    </td>
                    <td className='col-12 col-md-2'>
                      <div className='input-group'>4</div>
                    </td>
                    <td className='col-12 col-md-2'>
                      <span className='item-price'>$370</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='row'>
                <div className='col-12 col-sm-12'>
                  <div className='comments-area'>
                    <h4>{t('Order Comments.1')}</h4>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                      )}
                    </p>
                  </div>
                </div>
              </div>
              {/* ............the end..... */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Order_Detail;
