import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Main_Footer = () => {
  // for language excahnger
  const { t } = useTranslation();

  return (
    <Fragment>
      <footer
        id='footerThree'
        className='footer-area footer-three footer-desktop d-none d-lg-block d-xl-block'
      >
        <div className='container'>
          <div className='footer-top-content'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-7'>
                <h4>{t('SIGN UP FOR NEWSLETTER & GET ALL UPDATES.1')}</h4>
                <p>
                  {t(
                    'Lorem ipsum dolor sit amet, sed do eiusmod tempor incdidunt ut labore et.1'
                  )}
                </p>
              </div>
              <div className='col-12 col-lg-5'>
                <form className='form-inline'>
                  <div className='search'>
                    <input type='search' placeholder='Your Email ...' />
                    <button
                      className='btn btn-secondary swipe-to-top'
                      type='submit'
                    >
                      {t('Subscribe.1')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-4'>
              <div className='single-footer'>
                <div className='pro-about'>
                  <h5>{t('Store.1')}</h5>
                  <ul className='pl-0 mb-0'>
                    <li>
                      <Link
                        to='/'
                        className='logo'
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title='logo'
                      >
                        <img
                          className='img-fuild'
                          src='assets/images/logo/logo.png'
                          alt='logo here'
                        />
                      </Link>
                    </li>
                    <li>
                      <span>
                        {t('1223, Main Street, New York, 38000 USA.1')}
                      </span>
                    </li>
                    <li>
                      <span>888 - 963 - 600</span>
                      <span>
                        <Link to='#'>info@example.com</Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-2'>
              <div className='single-footer'>
                <h5>{t('Info.1')}</h5>
                <ul className='pl-0 mb-0'>
                  <li>
                    <Link to='/'>{t('Home.1')}</Link>
                  </li>
                  <li>
                    <Link to='/blog-page1'>{t('Blog.1')}</Link>
                  </li>
                  <li>
                    <Link to='/about-page1'>{t('About Us.1')}</Link>
                  </li>
                  <li>
                    <Link to='/contact-page1'>{t('Contact Us.1')}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-2'>
              <div className='single-footer'>
                <h5>{t('Our Polices.1')}</h5>
                <ul className='pl-0 mb-0'>
                  <li>
                    <Link to='#'>{t('FAQs.1')}</Link>
                  </li>
                  <li>
                    <Link to='/privacy'>{t('Privacy Policy.1')}</Link>
                  </li>
                  <li>
                    <Link to='/refund'>{t('Refund Policy.1')}</Link>
                  </li>
                  <li>
                    <Link to='/term'>{t('Terms of Service.1')}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-2'>
              <div className='single-footer'>
                <h5>{t('My Account.1')}</h5>
                <ul className='pl-0 mb-0'>
                  <li>
                    <Link to='/shop-page1'>{t('Shop.1')}</Link>
                  </li>
                  <li>
                    <Link to='/orders'>{t('Orders.1')}</Link>
                  </li>
                  <li>
                    <Link to='/cart-page1'>{t('Shopping Cart.1')}</Link>
                  </li>
                  <li>
                    <Link to='/wishlist'>{t('Wishlist.1')}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-2'>
              <div className='single-footer'>
                <h5>{t('Social Network.1')}</h5>
                <ul className='pl-0 mb-0'>
                  <li>
                    <Link to='/shop-page1'>{t('Facebook.1')}</Link>
                  </li>
                  <li>
                    <Link to='/orders'>{t('Twitter.1')}</Link>
                  </li>
                  <li>
                    <Link to='/cart-page1'>{t('Instagram.1')} </Link>
                  </li>
                  <li>
                    <Link to='/wishlist'>{t('Linkedin.1')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid p-0'>
          <div className='copyright-content'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-12 col-sm-12'>
                  <div className='footer-info'>
                    ©&nbsp;2019 Company, Inc.
                    <Link to='/privacy'>{t('Privacy.1')}</Link>
                    &nbsp;•&nbsp;<Link to='/term'>{t('Terms.1')}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Main_Footer;
