import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Login_Page1 = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <MainHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* login Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Login.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content login-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Login Page.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
              <div className='registration-process'>
                <h2>{t('Already Registered.1')}?</h2>
                <form name='login'>
                  <div className='from-group row mb-4'>
                    <div className='input-group col-12'>
                      <input
                        type='text'
                        name='email'
                        className='form-control'
                        id='inlineFormInputGroup0'
                        placeholder='Enter Your Email'
                      />
                    </div>
                  </div>
                  <div className='from-group row mb-4'>
                    <div className='input-group col-12'>
                      <input
                        type='password'
                        name='password'
                        className='form-control'
                        id='inlineFormInputGroup1'
                        placeholder='Enter Your Password'
                      />
                    </div>
                  </div>
                  <div className='from-group'>
                    <button className='btn btn-secondary swipe-to-top'>
                      {t('Login.1')}
                    </button>
                    <Link to='/change-password' className='btn btn-link'>
                      {t('Forgot Password.1')}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
              <h2>{t('New Customer.1')}</h2>
              <div className='registration-process'>
                <p>
                  {t(
                    'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
                  )}
                </p>
                <form>
                  <div className='from-group'>
                    <Link to='/signup' className='btn btn-primary swipe-to-top'>
                      {t('Create an Account.1')}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className='col-12 col-sm-12 '>
              <div className='registration-socials'>
                <p className='mb-3 text-center'>
                  {t('Access Your Account Through Your Social Networks.1')}
                </p>
                <div className='from-group'>
                  <button
                    type='button'
                    className='btn btn-google swipe-to-top'
                   // style={{ marginRight: '5px' }}
                  >
                    <i className='fab fa-google-plus-g' />
                    &nbsp;{t('Google.1')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-facebook swipe-to-top'
                   // style={{ marginRight: '5px' }}
                  >
                    <i className='fab fa-facebook-f' />
                    &nbsp;{t('Facebook.1')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-twitter swipe-to-top'
                  >
                    <i className='fab fa-twitter' />
                    &nbsp;{t('Twitter.1')}
                  </button>
                </div>
              </div>
              {t(
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.1'
              )}
              <p> </p>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Login_Page1;
