import React, { Fragment, useEffect, useState } from 'react';
import { checkOutTabChanging } from '../../../components/utils/LoadScript';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';

import {
  removeItemFromCart,
  addToCart,
  clearCartContent
} from '../../../actions/cart';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Checkout = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    checkOutTabChanging();
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  const [defaultAdd, setDfaultAdd] = useState(false);
  const orderData = JSON.parse(localStorage.getItem('orderData'));
  const cartproducts = useSelector(state => state.cart.products);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
    customers_id: '',
    customers_name: '',
    customers_telephone: '03167418077',
    delivery_firstname: '',
    delivery_lastname: '',
    delivery_companyname: '',
    delivery_street_address: '',
    delivery_country: '',
    delivery_country_id: '',
    delivery_state: '',
    delivery_city: '',
    delivery_postcode: '',
    delivery_phone: '',
    billing_firstname: '',
    billing_lastname: '',
    billing_companyname: '',
    billing_street_address: '',
    billing_country: '',
    billing_country_id: '',
    billing_state: '',
    billing_city: '',
    billing_postcode: '',
    billing_phone: '',
    coupon_amount: '',
    is_coupon_applied: '',
    language_id: 1,
    payment_method: '',
    currency_code: 'usd',
    products: [],
    productsTotal: '23.99',
    shipping_cost: '130.0',
    shipping_method: 0,
    totalPrice: 153.99,
    tax_zone_id: 182,
    total_tax: 0.0,
    //tabChanged: false,
    shippingAddressTabChanged: false,
    billingAddressTabChanged: false,
    shippingMethodTabChanged: false,
    orderViewTabChanged: false
  });

  const {
    shipAddressActive,
    shipMethodActive,
    billAddressActive,
    orderDetailActive,
    email,
    customers_id,
    customers_name,
    billing_country_id,
    customers_telephone,
    delivery_firstname,
    delivery_lastname,
    delivery_companyname,
    delivery_street_address,
    delivery_country,
    delivery_country_id,
    delivery_state,
    delivery_city,
    delivery_postcode,
    delivery_phone,
    billing_firstname,
    billing_lastname,
    billing_companyname,
    billing_street_address,
    billing_country,
    billing_state,
    billing_city,
    billing_postcode,
    billing_phone,
    coupon_amount,
    is_coupon_applied,
    language_id,
    currency_code,
    payment_method,
    products,
    productsTotal,
    shipping_cost,
    shipping_method,
    totalPrice,
    tax_zone_id,
    total_tax,
    shippingAddressTabChanged,
    billingAddressTabChanged,
    shippingMethodTabChanged,
    orderViewTabChanged,
    error
  } = formData;

  // get total from store
  const total = useSelector(state => state.cart.total);
  // adding total with shipping method charges
  const totalnum = parseInt(total);
  const parse = parseInt(shipping_method);
  const totalWithShippingMethod = totalnum + parse;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addShippingAddress = () => {
    const data = {
      delivery_firstname: delivery_firstname,
      delivery_lastname: delivery_lastname,
      delivery_companyname: delivery_companyname,
      delivery_street_address: delivery_street_address,
      delivery_country: delivery_country,
      delivery_state: delivery_state,
      delivery_city: delivery_city,
      delivery_postcode: delivery_postcode,
      delivery_phone: delivery_phone
    };

    localStorage.setItem('orderData', JSON.stringify(data));
  };

  const addBillingAddress = () => {
    // check if use check same billing and shipping address check
    if (defaultAdd) {
      const data = {
        delivery_firstname: delivery_firstname,
        delivery_lastname: delivery_lastname,
        delivery_companyname: delivery_companyname,
        delivery_street_address: delivery_street_address,
        delivery_country: delivery_country,
        delivery_state: delivery_state,
        delivery_city: delivery_city,
        delivery_postcode: delivery_postcode,
        delivery_phone: delivery_phone,
        billing_firstname: delivery_firstname,
        billing_lastname: delivery_lastname,
        billing_companyname: delivery_companyname,
        billing_street_address: delivery_street_address,
        billing_country: delivery_country,
        billing_state: delivery_state,
        billing_city: delivery_city,
        billing_postcode: delivery_postcode,
        billing_phone: delivery_phone
      };
      localStorage.setItem('orderData', JSON.stringify(data));
    } else {
      const data = {
        delivery_firstname: delivery_firstname,
        delivery_lastname: delivery_lastname,
        delivery_companyname: delivery_companyname,
        delivery_street_address: delivery_street_address,
        delivery_country: delivery_country,
        delivery_state: delivery_state,
        delivery_city: delivery_city,
        delivery_postcode: delivery_postcode,
        delivery_phone: delivery_phone,
        billing_firstname: billing_firstname,
        billing_lastname: billing_lastname,
        billing_companyname: billing_companyname,
        billing_street_address: billing_street_address,
        billing_country: billing_country,
        billing_state: billing_state,
        billing_city: billing_city,
        billing_postcode: billing_postcode,
        billing_phone: billing_phone
      };
      localStorage.setItem('orderData', JSON.stringify(data));
    }
  };

  const addShippingMethod = () => {
    const data = JSON.parse(localStorage.getItem('orderData'));

    const orderdata = {
      delivery_firstname: data.delivery_firstname,
      delivery_lastname: data.delivery_lastname,
      delivery_companyname: data.delivery_companyname,
      delivery_street_address: data.delivery_street_address,
      delivery_country: data.delivery_country,
      delivery_state: data.delivery_state,
      delivery_city: data.delivery_city,
      delivery_postcode: data.delivery_postcode,
      delivery_phone: data.delivery_phone,
      billing_firstname: data.delivery_firstname,
      billing_lastname: data.delivery_lastname,
      billing_companyname: data.delivery_companyname,
      billing_street_address: data.delivery_street_address,
      billing_country: data.delivery_country,
      billing_state: data.delivery_state,
      billing_city: data.delivery_city,
      billing_postcode: data.delivery_postcode,
      billing_phone: data.delivery_phone,
      shipping_method: shipping_method
    };
    localStorage.setItem('orderData', JSON.stringify(orderdata));
  };

  // for delete item from cart
  const confirmDelete = (index, item) => {
    dispatch(removeItemFromCart(index, item));
    dispatch(addToCart());
  };

  // for show order data on console and delelte it form localstorage
  const showOrderData = () => {
    const data = JSON.parse(localStorage.getItem('orderData'));

    const orderdata = {
      delivery_firstname: data.delivery_firstname,
      delivery_lastname: data.delivery_lastname,
      delivery_companyname: data.delivery_companyname,
      delivery_street_address: data.delivery_street_address,
      delivery_country: data.delivery_country,
      delivery_state: data.delivery_state,
      delivery_city: data.delivery_city,
      delivery_postcode: data.delivery_postcode,
      delivery_phone: data.delivery_phone,
      billing_firstname: data.delivery_firstname,
      billing_lastname: data.delivery_lastname,
      billing_companyname: data.delivery_companyname,
      billing_street_address: data.delivery_street_address,
      billing_country: data.delivery_country,
      billing_state: data.delivery_state,
      billing_city: data.delivery_city,
      billing_postcode: data.delivery_postcode,
      billing_phone: data.delivery_phone,
      shipping_method: shipping_method,
      products: cartproducts,
      payment_method: payment_method
    };

    console.log('User Order Details', orderdata);

    setTimeout(() => {
      dispatch(clearCartContent());
      localStorage.removeItem('cartItem');
      localStorage.removeItem('orderData');

      console.log('the data is removed');
    }, 3000);
  };

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* checkout Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Checkout.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content checkout-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Checkout Page.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-xl-9'>
              <div className='row'>
                <div className='checkout-module'>
                  <ul
                    className='nav nav-pills checkoutd-nav mb-3'
                    id='pills-tab'
                    role='tablist'
                  >
                    <li className='nav-item'>
                      <a
                        className='nav-link  active'
                        id='pills-shipping-tab'
                        data-toggle='pill'
                        href='#pills-shipping'
                        role='tab'
                        aria-controls='pills-shipping'
                        aria-selected='true'
                      >
                        <span className='d-flex d-lg-none'>1</span>
                        <span className='d-none d-lg-flex'>
                          {t('Shipping Address.1')}
                        </span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link '
                        id='pills-billing-tab'
                        data-toggle='pill'
                        href='#pills-billing'
                        role='tab'
                        aria-controls='pills-billing'
                        aria-selected='false'
                      >
                        <span className='d-flex d-lg-none'>2</span>
                        <span className='d-none d-lg-flex'>
                          {t('Billing Address.1')}
                        </span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link '
                        id='pills-method-tab'
                        data-toggle='pill'
                        href='#pills-method'
                        role='tab'
                        aria-controls='pills-method'
                        aria-selected='false'
                      >
                        <span className='d-flex d-lg-none'>3</span>
                        <span className='d-none d-lg-flex'>
                          {t('Shipping Methods.1')}
                        </span>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link '
                        id='pills-order-tab'
                        data-toggle='pill'
                        href='#pills-order'
                        role='tab'
                        aria-controls='pills-order'
                        aria-selected='false'
                      >
                        <span className='d-flex d-lg-none'>4</span>
                        <span className='d-none d-lg-flex'>
                          {t('Order Details.1')}
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div
                      className='tab-pane fade show active'
                      id='pills-shipping'
                      role='tabpanel'
                      aria-labelledby='pills-shipping-tab'
                    >
                      <form>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup0'
                                placeholder='First Name'
                                name='delivery_firstname'
                                value={delivery_firstname}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup1'
                                placeholder='Last Name'
                                name='delivery_lastname'
                                value={delivery_lastname}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup2'
                                placeholder='Company Name'
                                name='delivery_companyname'
                                value={delivery_companyname}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup3'
                                placeholder='Address'
                                name='delivery_street_address'
                                value={delivery_street_address}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group select-control'>
                              <select
                                className='form-control'
                                id='inlineFormInputGroup4'
                                name='delivery_country'
                                value={delivery_country}
                                onChange={e => onChange(e)}
                              >
                                <option selected>Select Country</option>
                                <option value='USA'>USA</option>
                                <option value='Canada'>Canada</option>
                              </select>
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group select-control'>
                              <select
                                className='form-control'
                                id='inlineFormInputGroup5'
                                name='delivery_state'
                                value={delivery_state}
                                onChange={e => onChange(e)}
                              >
                                <option selected>Select State</option>
                                <option value='New York'>New York</option>
                                <option value='Taxes'>Taxes</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup7'
                                placeholder='City'
                                name='delivery_city'
                                value={delivery_city}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup7'
                                placeholder='Postal Code'
                                name='delivery_postcode'
                                value={delivery_postcode}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup8'
                                placeholder='Phone'
                                name='delivery_phone'
                                value={delivery_phone}
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <span style={{ color: 'red', margin: '5px' }}>
                          {error}
                        </span>
                        <div className='col-12 col-sm-12'>
                          <div className='row'>
                            <a
                              data-toggle='pill'
                              href='#pills-billing'
                              // href={
                              //   shippingAddressTabChanged
                              //     ? '#pills-billing'
                              //     : '#pills-shipping'
                              // }
                              className='btn btn-secondary swipe-to-top cta'
                              onClick={() => addShippingAddress()}
                            >
                              {t('Continue.1')}
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='pills-billing'
                      role='tabpanel'
                      aria-labelledby='pills-billing-tab'
                    >
                      <form>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup01'
                                placeholder='First Name'
                                name='billing_firstname'
                                value={
                                  defaultAdd
                                    ? orderData.delivery_firstname
                                    : billing_firstname
                                }
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup12'
                                placeholder='Last Name'
                                name='billing_lastname'
                                //value={billing_lastname}

                                value={
                                  defaultAdd
                                    ? orderData.delivery_lastname
                                    : billing_lastname
                                }
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup23'
                                placeholder='Company Name'
                                name='billing_companyname'
                                value={
                                  defaultAdd
                                    ? orderData.delivery_companyname
                                    : billing_companyname
                                }
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group '>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup34'
                                placeholder='Address'
                                name='billing_street_address'
                                // value={billing_street_address}
                                onChange={e => onChange(e)}
                                value={
                                  defaultAdd
                                    ? orderData.delivery_street_address
                                    : billing_street_address
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group select-control'>
                              <select
                                className='form-control'
                                id='inlineFormInputGroup45'
                                name='billing_country'
                                //value={billing_country}
                                onChange={e => onChange(e)}
                                value={
                                  defaultAdd
                                    ? orderData.delivery_country
                                    : billing_country
                                }
                              >
                                <option selected>Select Country</option>
                                <option value='USA'>USA</option>
                                <option value='Canada'>Canada</option>
                              </select>
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group select-control'>
                              <select
                                className='form-control'
                                id='inlineFormInputGroup56'
                                name='billing_state'
                                //value={billing_state}

                                value={
                                  defaultAdd
                                    ? orderData.delivery_state
                                    : billing_state
                                }
                                onChange={e => onChange(e)}
                              >
                                <option selected>Select State</option>
                                <option value='New York'>New York</option>
                                <option value='Taxes'>Taxes</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup7'
                                placeholder='City'
                                name='billing_city'
                                //value={billing_city}

                                value={
                                  defaultAdd
                                    ? orderData.delivery_city
                                    : billing_city
                                }
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup78'
                                placeholder='Postal Code'
                                name='billing_postcode'
                                //value={billing_postcode}

                                value={
                                  defaultAdd
                                    ? orderData.delivery_postcode
                                    : billing_postcode
                                }
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='from-group col-md-6 mb-3'>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='inlineFormInputGroup89'
                                placeholder='Phone'
                                name='billing_phone'
                                // value={billing_phone}
                                value={
                                  defaultAdd
                                    ? orderData.delivery_phone
                                    : billing_phone
                                }
                                onChange={e => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              defaultValue
                              id='defaultCheck0'
                              onChange={() => setDfaultAdd(!defaultAdd)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='defaultCheck0'
                            >
                              {t('Same shipping and billing address.1')}.
                            </label>
                            <small
                              id='checkboxHelp'
                              className='form-text text-muted'
                            />
                          </div>
                        </div>
                        <div className='col-12 col-sm-12'>
                          <div className='row'>
                            <a
                              data-toggle='pill'
                              href='#pills-shipping'
                              className='btn btn-light swipe-to-top cta'
                            >
                              {t('Back.1')}
                            </a>
                            <a
                              data-toggle='pill'
                              href='#pills-method'
                              // href={
                              //   tabChanged ? '#pills-method' : '#pills-billing'
                              // }
                              className='btn btn-secondary swipe-to-top cta'
                              onClick={() => addBillingAddress()}
                            >
                              {t('Continue.1')}
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='pills-method'
                      role='tabpanel'
                      aria-labelledby='pills-method-tab'
                    >
                      <div className='col-12 col-sm-12 '>
                        <div className='row'>
                          <p>
                            {t(
                              'Please select a prefered shipping method to use on this order.1'
                            )}
                          </p>
                        </div>
                      </div>
                      <div className='col-12 col-sm-12 '>
                        <div className='row'>
                          <div className='col-12 col-sm-6 mb-4'>
                            <h4>{t('Flate Rate.1')}</h4>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultValue
                                id='defaultCheck1'
                                value='11'
                                name='shipping_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='defaultCheck1'
                              >
                                {t('Flat Rate.1')} --- $11
                              </label>
                              <small
                                id='emailHelp'
                                className='form-text text-muted'
                              />
                            </div>
                          </div>
                          <div className='col-12 col-sm-6'>
                            <h4>{t('UPS Shipping.1')}</h4>
                            <div className='form-check mb-2'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultValue
                                id='defaultCheck2'
                                value='15'
                                name='shipping_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='defaultCheck2'
                              >
                                {t('24 hours.1')} --- $15
                              </label>
                            </div>
                            <div className='form-check mb-2'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultValue
                                id='defaultCheck3'
                                value='10'
                                name='shipping_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='defaultCheck3'
                              >
                                {t('48 hours.1')} --- $10
                              </label>
                            </div>
                            <div className='form-check mb-4'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultValue
                                id='defaultCheck4'
                                value='5'
                                name='shipping_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='defaultCheck4'
                              >
                                {t('48 hours.1')} --- $5
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-sm-12'>
                        <div className='row'>
                          <a
                            data-toggle='pill'
                            href='#pills-billing'
                            className='btn btn-light swipe-to-top cta'
                          >
                            {t('Back.1')}
                          </a>
                          <a
                            data-toggle='pill'
                            href='#pills-order'
                            // href={tabChanged ? '#pills-order' : '#pills-method'}
                            className='btn btn-secondary swipe-to-top cta'
                            onClick={() => addShippingMethod()}
                          >
                            {t('Continue.1')}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='pills-order'
                      role='tabpanel'
                      aria-labelledby='pills-order-tab'
                    >
                      <table className='table top-table'>
                        <thead>
                          <tr className='d-flex'>
                            <th className='col-12 col-md-2'>
                              {t('ITEM(S).1')}
                            </th>
                            <th className='col-12 col-md-4' />
                            <th className='col-12 col-md-2'>{t('PRICE.1')}</th>
                            <th className='col-12 col-md-2'>{t('QTY.1')}</th>
                            <th className='col-12 col-md-2'>
                              {t('SUBTOTAL.1')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartproducts !== undefined
                            ? cartproducts.map((item, i) => (
                                <tr className='d-flex' key={i}>
                                  <td className='col-12 col-md-2'>
                                    <img
                                      className='img-fluid'
                                      src={item.products_image}
                                      alt='Product'
                                    />
                                  </td>
                                  <td className='col-12 col-md-4'>
                                    <div className='item-detail'>
                                      <span className='pro-info'>
                                        {t(`${item.products_name}.1`)}
                                      </span>
                                      <h2 className='pro-title'>
                                        <Link to='/#'>
                                          {t(
                                            `${item.categories[0].categories_name}.1`
                                          )}
                                        </Link>
                                      </h2>
                                      <div className='item-attributes' />
                                      <div className='item-controls'>
                                        <button type='button' className='btn'>
                                          <span className='fas fa-pencil-alt' />
                                        </button>
                                        <button
                                          type='button'
                                          className='btn'
                                          onClick={() => confirmDelete(i, item)}
                                        >
                                          <span className='fas fa-times' />
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='item-price col-12 col-md-2'>
                                    {currencyDetail.symbolLeft}
                                    {(
                                      item.products_price * currencyDetail.value
                                    ).toFixed(2)}
                                  </td>
                                  <td className='col-12 col-md-2'>
                                    <div className='input-group'>
                                      {item.quantity}
                                    </div>
                                  </td>
                                  <td className='align-middle item-total col-12 col-md-2'>
                                    {currencyDetail.symbolLeft}
                                    {(
                                      item.quantity *
                                      item.products_price *
                                      currencyDetail.value
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                      <div className='col-12 col-sm-12'>
                        <div className='row'>
                          <div className='heading'>
                            <h4>{t('Order Notes & Summary.1')}</h4>
                          </div>
                          <div
                            className='form-group'
                            style={{ width: '100%', padding: 0 }}
                          >
                            <label htmlFor='exampleFormControlTextarea1'>
                              {t('Please write notes of your order.1')}
                            </label>
                            <textarea
                              className='form-control'
                              id='exampleFormControlTextarea1'
                              rows={3}
                              defaultValue={''}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-sm-12 '>
                        <div className='row'>
                          <div className='heading'>
                            <h4>{t('Payment Methods.1')}</h4>
                          </div>
                          <div
                            className='form-group'
                            style={{ width: '100%', padding: 0 }}
                          >
                            <label
                              htmlFor='exampleFormControlTextarea1'
                              style={{ width: '100%', marginBottom: 30 }}
                            >
                              {t(
                                'Please select a prefered payment method to use on this order.1'
                              )}
                            </label>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='inlineCheckbox1'
                                defaultValue='option1'
                                value='paypal'
                                name='payment_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='inlineCheckbox1'
                              >
                                <img
                                  src='assets/images/miscellaneous/paypal.png'
                                  alt='paypal'
                                />
                              </label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='inlineCheckbox2'
                                defaultValue='option2'
                                value='braintree'
                                name='payment_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='inlineCheckbox2'
                              >
                                <img
                                  src='assets/images/miscellaneous/braintree.png'
                                  alt='braintree'
                                />
                              </label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='inlineCheckbox3'
                                defaultValue='option3'
                                value='stripe'
                                name='payment_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='inlineCheckbox3'
                              >
                                <img
                                  src='assets/images/miscellaneous/stripe.png'
                                  alt='stripe'
                                />
                              </label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='inlineCheckbox4'
                                defaultValue='option4'
                                value='cod'
                                name='payment_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='inlineCheckbox4'
                              >
                                <img
                                  src='assets/images/miscellaneous/cod.png'
                                  alt='cod'
                                />
                              </label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='inlineCheckbox5'
                                defaultValue='option5'
                                value='instamojo'
                                name='payment_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='inlineCheckbox5'
                              >
                                <img
                                  src='assets/images/miscellaneous/instamojo.png'
                                  alt='instamojo'
                                />
                              </label>
                            </div>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='radio'
                                id='inlineCheckbox6'
                                defaultValue='option6'
                                value='hyperpay'
                                name='payment_method'
                                onChange={e => onChange(e)}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='inlineCheckbox6'
                              >
                                <img
                                  src='assets/images/miscellaneous/hyperpay.png'
                                  alt='hyperpay'
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-sm-12'>
                        <div className='row'>
                          <a
                            data-toggle='pill'
                            href='#pills-method'
                            className='btn btn-light swipe-to-top cta'
                          >
                            {t('Back.1')}
                          </a>
                          <Link
                            to='/thankyou'
                            className='btn btn-secondary swipe-to-top'
                            onClick={() => showOrderData()}
                          >
                            {t('Continue.1')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-3'>
              <table className='table right-table'>
                <thead>
                  <tr>
                    <th scope='col' colSpan={2}>
                      {t('Order Summary.1')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope='row'>{t('Subtotal.1')}</th>
                    <td>
                      {currencyDetail.symbolLeft}
                      {(total * currencyDetail.value).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>{t('Coupon Code.1')}</th>
                    <td>$00.00</td>
                  </tr>
                  <tr>
                    <th scope='row'>{t('TAX.1')}</th>
                    <td>$00.00</td>
                  </tr>
                  {shipping_method === '0' ||
                  shipping_method === null ||
                  shipping_method === 0 ? null : (
                    <tr>
                      {/* // <th scope='row'>{t('FLat Rate Shipping.1')}</th> */}
                      <th scope='row'>
                        {shipping_method == 11
                          ? t(`FLat Rate Shipping.1`)
                          : t(`UPS Shipping Shipping.1`)}
                      </th>
                      <td>
                        {currencyDetail.symbolLeft}
                        {(shipping_method * currencyDetail.value).toFixed(2)}
                      </td>
                      {/* <td>$00.00</td>  */}
                    </tr>
                  )}

                  <tr className='item-price'>
                    <th scope='row'>{t('Total.1')}</th>
                    <td>
                      {currencyDetail.symbolLeft}
                      {(totalWithShippingMethod * currencyDetail.value).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Checkout;
