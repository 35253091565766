import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Links from '../../../../components/navbar_links';
import { useSelector, useDispatch } from 'react-redux';
import OfferBar from '../../../../components/offer_Container';
import { removeItemFromCart, addToCart } from '../../../../actions/cart';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

const MainHeaderTwo = props => {
  // for language excahnger
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // get langauge from localStorage and change the direction if language is arabic
    const langauge = localStorage.getItem('i18nextLng');

    if (langauge === 'ar') {
      document.body.classList.add('bodyrtl');
    } else {
      document.body.classList.remove('bodyrtl');
    }
  }, []);

  // select product from reducer cart
  const products = useSelector(state => state.cart.products);
  const total = useSelector(state => state.cart.total);
  // for dispatch the function
  const dispatch = useDispatch();
  // length of product in cart
  const length = products.length;
  // for show alert
  const alert = useAlert();
  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);
  // get the length of the wishlist product
  const wishlistProductLenght = WishlistProducts.length;

  // for switch language
  const handleClick = lang => {
    i18n.changeLanguage(lang);
    // change the direction to rtl if lang is arabic
    if (lang === 'ar') {
      document.body.classList.add('bodyrtl');
    } else {
      document.body.classList.remove('bodyrtl');
    }
  };

  // for delete the product form cart or list
  const confirmDelete = (index, item) => {
    dispatch(removeItemFromCart(index, item));
    dispatch(addToCart());
    alert.error('Delected successfully!');
  };

  const language = localStorage.getItem('i18nextLng');
  return (
    <Fragment>
      <header id='headerTwo' className='header-area header-two header-desktop'>
        {/* offer bar component here */}
        <OfferBar />
        <div className='header-mini bg-top-bar'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-4 '>
                <h4>
                  {t('Call us.1')}: <strong>888 - 963 - 600</strong>
                </h4>
              </div>
              <div className='col-12 col-md-8'>
                <div className='pro-header-options'>
                  <div className='dropdown'>
                    <Link
                      href='javascript:void(0);'
                      className='dropdown-toggle'
                    >
                      {t('My Account.1')}
                    </Link>
                    <div className='dropdown-menu'>
                      <Link className='dropdown-item' to='/profile'>
                        {t('Profile.1')}
                      </Link>
                      <Link className='dropdown-item' to='/wishlist'>
                        {t('Wishlist.1')}&nbsp;({wishlistProductLenght})
                      </Link>
                      <Link className='dropdown-item' to='/compare'>
                        {t('Compare.1')}&nbsp;(2)
                      </Link>
                      <Link className='dropdown-item' to='/orders'>
                        {t('Orders.1')}
                      </Link>
                      <Link className='dropdown-item' to='/shipping-address'>
                        {t('Shipping Address.1')}
                      </Link>
                      <Link className='dropdown-item' to='#'>
                        {t('Logout.1')}
                      </Link>
                    </div>
                  </div>
                  <div className='dropdown'>
                    <Link
                      href='javascript:void(0);'
                      className='dropdown-toggle'
                    >
                      {language === 'en' ? 'English' : 'عربى'}
                    </Link>
                    <div className='dropdown-menu'>
                      <a
                        className='dropdown-item'
                        href='#'
                        onClick={() => handleClick('en')}
                      >
                        English
                      </a>
                      <a
                        className='dropdown-item'
                        href='#'
                        onClick={() => handleClick('ar')}
                      >
                        {t('Arabic.1')}
                      </a>
                    </div>
                  </div>
                  <div className='dropdown'>
                    <Link
                      href='javascript:void(0);'
                      className='dropdown-toggle'
                    >
                      {props.currencyDetails.code}
                    </Link>
                    <div className='dropdown-menu'>
                      <a
                        className='dropdown-item'
                        href='#'
                        onClick={() => props.onCurrencyChanged(0)}
                      >
                        $&nbsp;USD
                      </a>
                      <a
                        className='dropdown-item'
                        href='#'
                        onClick={() => props.onCurrencyChanged(1)}
                      >
                        €&nbsp;EURO
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='header-maxi bg-header-bar'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-sm-12 col-lg-2'>
                <Link
                  to='/'
                  className='logo'
                  data-toggle='tooltip'
                  data-placement='bottom'
                  title='logo'
                >
                  <img
                    className='img-fluid'
                    src='assets/images/logo/logo.png'
                    alt='logo here'
                  />
                </Link>
              </div>
              <div className='col-12 col-sm-8 nav-start'>
                <Links />
              </div>
              <div className='col-6 col-sm-6 col-md-4 col-lg-2'>
                <ul className='pro-header-right-options'>
                  <li className='dropdown'>
                    <button
                      type='button'
                      id='dropdownSearch2'
                      className='btn dropdown-toggle'
                      aria-haspopup='true'
                      aria-expanded='false'
                      data-toggle='dropdown'
                    >
                      <i className='fas fa-search' />
                    </button>
                    <div
                      className='dropdown-menu dropdown-menu-right'
                      aria-labelledby='dropdownSearch2'
                    >
                      <form>
                        <div className='pt-col'>
                          <input
                            type='text'
                            className='pt-search-input'
                            placeholder='Search products...'
                          />
                          <button className='btn pt-btn-search' type='submit'>
                            <i className='fas fa-search' />
                          </button>
                        </div>
                      </form>
                    </div>
                  </li>
                  <li>
                    <Link
                      to='/wishlist'
                      className='btn'
                      data-toggle='tooltip'
                      data-placement='bottom'
                      title='Wishlist'
                    >
                      <i className='far fa-heart' />
                      <span className='badge badge-secondary'>
                        {wishlistProductLenght}
                      </span>
                    </Link>
                  </li>
                  <li
                    className='cart-header dropdown head-cart-content'
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='cart'
                  >
                    <button
                      type='button'
                      id='dropdownCart'
                      className='btn dropdown-toggle'
                      aria-haspopup='true'
                      aria-expanded='false'
                      data-toggle='dropdown'
                    >
                      <i className='fas fa-shopping-cart' />
                      <span className='badge badge-secondary'>{length}</span>
                    </button>
                    <div
                      className='dropdown-menu dropdown-menu-right'
                      aria-labelledby='dropdownCart'
                    >
                      {length === 0 ||
                      length === null ||
                      length === undefined ? (
                        <ul>
                        <li>
                          {t('your cart is empty please please add product.1')}!
                        </li>
                      </ul>
                      ) : (
                        <ul className='shopping-cart-items'>
                          {products !== undefined && products !== null
                            ? products.map((item, index) => (
                                <li>
                                  <div className='item-thumb'>
                                    <div className='image'>
                                      <img
                                        className='img-fluid'
                                        src={item.products_image}
                                        alt='Product'
                                      />
                                    </div>
                                  </div>
                                  <div className='item-detail'>
                                    <h2>{t(`${item.products_name}.1`)}</h2>
                                    <div className='item-s'>
                                      {item.quantity} x{' '}
                                      {props.currencyDetails.symbolLeft}
                                      {(
                                        item.products_price *
                                        props.currencyDetails.value
                                      ).toFixed(2)}
                                      <i
                                        className='fas fa-trash'
                                        onClick={() =>
                                          confirmDelete(index, item)
                                        }
                                      />
                                    </div>
                                  </div>
                                </li>
                              ))
                            : null}
                          <li>
                            <span className='item-summary'>
                              {t('Total.1')}&nbsp;:&nbsp;
                              <span>
                                {props.currencyDetails.symbolLeft}
                                {(total * props.currencyDetails.value).toFixed(
                                  2
                                )}
                              </span>
                            </span>
                          </li>
                          <li>
                            <Link
                              className='btn  btn-link btn-block '
                              to='/cart-page1'
                            >
                              {t('View Cart.1')}
                            </Link>
                            <Link
                              className='btn btn-secondary btn-block  swipe-to-top'
                              to='/checkout'
                            >
                              {t('Checkout.1')}
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default MainHeaderTwo;
