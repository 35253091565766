import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import OfferBar from '../../../../components/offer_Container';
import { removeItemFromCart, addToCart } from '../../../../actions/cart';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { MobileMenu } from '../../../../components/utils/LoadScript';

const MobileHeader = props => {
  useEffect(() => {
    // for mobile load
    MobileMenu();
  }, []);
  // for language excahnger
  const { t, i18n } = useTranslation();
  // state for currency changed or not
  const [changeCurrency, setChangeCurrency] = useState(true);
  
  
  // select product from reducer cart
  const products = useSelector(state => state.cart.products);
  const total = useSelector(state => state.cart.total);
  // for dispatch the function
  const dispatch = useDispatch();
  // length of product in cart
  const length = products.length;
  // for show alert
  const alert = useAlert();

  // state to check that currency change state is present or not
  const [isCurrencyPresent, setCurrencyPresent] = useState(true);

  // for switch language
  const handleClick = lang => {
    i18n.changeLanguage(lang);
  };

  // for delete the product form cart or list
  const confirmDelete = (index, item) => {
    dispatch(removeItemFromCart(index, item));
    dispatch(addToCart());
    alert.error('Delected successfully!');
  };

  const language = localStorage.getItem('i18nextLng');

  return (
    <header id='headerMobile' className='header-area header-mobile'>
      <OfferBar />
      <div className='header-mini bg-top-bar'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12'>
              <div className='navbar-lang'>
                <div className='dropdown'>
                  <a href='javascript:void(0);' className='dropdown-toggle'>
                    {language === 'en' ? 'English' : 'عربى'}
                  </a>
                  <div className='dropdown-menu'>
                    <a
                      className='dropdown-item'
                      href='#'
                      onClick={() => handleClick('en')}
                    >
                      {t('English.1')}
                    </a>
                    <a
                      className='dropdown-item'
                      href='#'
                      onClick={() => handleClick('ar')}
                    >
                      {t('Arabic.1')}
                    </a>
                  </div>
                </div>
                <div className='dropdown'>
                  <a href='javascript:void(0);' className='dropdown-toggle'>
                    {props.currencyDetails.code}
                  </a>
                  <div className='dropdown-menu'>
                    <a
                      className='dropdown-item'
                      href='#'
                      onClick={() => props.onCurrencyChanged(0)}
                    >
                      $&nbsp;{t('USD.1')}
                    </a>
                    <a
                      className='dropdown-item'
                      href='#'
                      onClick={() => props.onCurrencyChanged(1)}
                    >
                      €&nbsp;{t('EUR.1')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='header-maxi bg-header-bar '>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-2 pr-0'>
              <div className='navigation-mobile-container'>
                <a
                  href='javascript:void(0)'
                  className='navigation-mobile-toggler'
                >
                  <span className='fas fa-bars' />
                </a>
                <nav id='navigation-mobile'>
                  <div className='logout-main'>
                    <div className='welcome'>
                      <span>{t('Welcome & Guest.1')} !</span>
                    </div>
                    <div className='logout'>
                      <Link to='#'>{t('Logout.1')}</Link>
                    </div>
                  </div>
                  <a
                    className='main-manu btn'
                    data-toggle='collapse'
                    href='#homepages'
                    role='button'
                    aria-expanded='false'
                    aria-controls='shoppages'
                  >
                    {t('Home.1')}
                  </a>
                  <div
                    className='sub-manu collapse multi-collapse'
                    id='homepages'
                  >
                    <ul className='unorder-list'>
                      <li>
                        <Link to='/' className='btn main-manu'>
                          {t('Home Page 1.1')}
                        </Link>
                        <Link to='/index-2' className='btn main-manu'>
                          {t('Home Page 2.1')}
                        </Link>
                        <Link to='/index-3' className='btn main-manu'>
                          {t('Home Page 3.1')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <a
                    className='main-manu btn'
                    data-toggle='collapse'
                    href='#shoppages'
                    role='button'
                    aria-expanded='false'
                    aria-controls='shoppages'
                  >
                    {t('Shop.1')}
                  </a>
                  <div
                    className='sub-manu collapse multi-collapse'
                    id='shoppages'
                  >
                    <ul className='unorder-list'>
                      <li>
                        <Link to='/shop-page1' className='btn main-manu'>
                          {t('Top Bar.1')}
                        </Link>
                        <Link to='/shop-page2' className='btn main-manu'>
                          {t('Right Sidebar.1')}
                        </Link>
                        <Link to='/shop-page3' className='btn main-manu'>
                          {t('Top and Right Sidebar.1')}
                        </Link>
                        <Link to='/shop-page4' className='btn main-manu'>
                          {t('Left Sidebar.1')}
                        </Link>
                        <Link to='/shop-page5' className='btn main-manu'>
                          {t('Top and Left Sidebar.1')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <a
                    className=' main-manu btn'
                    data-toggle='collapse'
                    href='#productpages'
                    role='button'
                    aria-expanded='false'
                    aria-controls='productpages'
                  >
                    {t('Product.1')}
                  </a>
                  <div
                    className='sub-manu collapse multi-collapse'
                    id='productpages'
                  >
                    <ul className='unorder-list'>
                      <li>
                        <Link to='/product-page1' className='btn main-manu'>
                          {t('Left Carousel.1')}
                        </Link>
                        <Link to='/product-page2' className='btn main-manu'>
                          {t('Right Carousel.1')}
                        </Link>
                        <Link to='/product-page3' className='btn main-manu'>
                          {t('Vertical Thumbnail Carousel.1')}
                        </Link>
                        <Link to='/product-page4' className='btn main-manu'>
                          {t('Right Banner.1')}
                        </Link>
                        <Link to='/product-page5' className='btn main-manu'>
                          {t('Best Seller.1')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <a
                    className=' main-manu btn'
                    data-toggle='collapse'
                    href='#staticpages'
                    role='button'
                    aria-expanded='false'
                    aria-controls='staticpages'
                  >
                    {t('Pages.1')}
                  </a>
                  <div
                    className='sub-manu collapse multi-collapse'
                    id='staticpages'
                  >
                    <ul className='unorder-list'>
                      <li>
                        <a
                          className='main-manu btn '
                          data-toggle='collapse'
                          href='#staticabout'
                          role='button'
                          aria-expanded='false'
                          aria-controls='staticabout'
                        >
                          {t('About Us.1')}
                        </a>
                        <div
                          className='sub-manu1 collapse multi-collapse'
                          id='staticabout'
                        >
                          <ul className='unorder-list'>
                            <li>
                              <Link to='/about-page1' className='btn main-manu'>
                                {t('About Us Page 1.1')}
                              </Link>
                              <Link to='/about-page2' className='btn main-manu'>
                                {t('About Us Page 2.1')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <a
                          className='main-manu btn '
                          data-toggle='collapse'
                          href='#staticcontact'
                          role='button'
                          aria-expanded='false'
                          aria-controls='staticcontact'
                        >
                          {t('Contact Us.1')}
                        </a>
                        <div
                          className='sub-manu1 collapse multi-collapse'
                          id='staticcontact'
                        >
                          <ul className='unorder-list'>
                            <li>
                              <Link
                                to='/contact-page1'
                                className='btn main-manu'
                              >
                                {t('Contact Us Page 1.1')}
                              </Link>
                              <Link
                                to='/contact-page2'
                                className='btn main-manu'
                              >
                                {t('Contact Us Page 2.1')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <a
                          className='main-manu btn'
                          data-toggle='collapse'
                          href='#staticlogin'
                          role='button'
                          aria-expanded='false'
                          aria-controls='staticlogin'
                        >
                          {t('Login.1')}
                        </a>
                        <div
                          className='sub-manu1 collapse multi-collapse'
                          id='staticlogin'
                        >
                          <ul className='unorder-list'>
                            <li>
                              <Link to='/login-page1' className='btn main-manu'>
                                {t('Login Page 1.1')}
                              </Link>
                              <Link to='/login-page2' className='btn main-manu'>
                                {t('Login Page 2.1')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <a
                          className='main-manu btn '
                          data-toggle='collapse'
                          href='#staticblog'
                          role='button'
                          aria-expanded='false'
                          aria-controls='staticblog'
                        >
                          {t('Blogs.1')}
                        </a>
                        <div
                          className='sub-manu1 collapse multi-collapse'
                          id='staticblog'
                        >
                          <ul className='unorder-list'>
                            <li>
                              <Link to='/blog-page1' className='btn main-manu'>
                                {t('Blog Page 1.1')}
                              </Link>
                              <Link to='/blog-page2' className='btn main-manu'>
                                {t('Blog Page 2.1')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <a
                          className='main-manu btn '
                          data-toggle='collapse'
                          href='#staticcart'
                          role='button'
                          aria-expanded='false'
                          aria-controls='staticcart'
                        >
                          {t('Shopping Cart.1')}
                        </a>
                        <div
                          className='sub-manu1 collapse multi-collapse'
                          id='staticcart'
                        >
                          <ul className='unorder-list'>
                            <li>
                              <Link to='/cart-page1' className='btn main-manu'>
                                {t('Cart Page 1.1')}
                              </Link>
                              <Link to='/cart-page2' className='btn main-manu'>
                                {t('Cart Page 2.1')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <Link to='/checkout' className='main-manu btn '>
                          {t('Checkout.1')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <Link to='/profile' className='main-manu btn '>
                    {t('Profile.1')}
                  </Link>
                  <Link to='/wishlist' className='main-manu btn '>
                    {t('Wishlist.1')} (8)
                  </Link>
                  <Link to='/compare' className='main-manu btn '>
                    {t('Compare.1')}
                  </Link>
                  <Link to='/orders' className='main-manu btn '>
                    {t('Orders.1')}
                  </Link>
                  <Link to='/shipping-address' className='main-manu btn '>
                    {t('Shipping Address.1')}
                  </Link>
                </nav>
              </div>
            </div>
            <div className='col-8'>
              <Link
                to='/'
                className='logo'
                data-toggle='tooltip'
                data-placement='bottom'
                title='logo'
                data-original-title='logo'
              >
                <img
                  className='img-fluid'
                  src='assets/images/logo/logo.png'
                  alt='logo here'
                />
              </Link>
            </div>
            <div className='col-2 pl-0'>
              <div className='cart-dropdown dropdown'>
                <a
                  className='cart-dropdown-toggle'
                  href='#'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <i className='fas fa-shopping-cart' aria-hidden='true' />
                  <span className='badge badge-secondary'>{length}</span>
                </a>
                <div
                  className='dropdown-menu dropdown-menu-right'
                  aria-labelledby='headerOneCartButton'
                >
                  {length === 0 || length === null || length === undefined ? (
                    <ul>
                    <li>
                      {t('your cart is empty please please add product.1')}!
                    </li>
                  </ul>
                  ) : (
                    <ul className='shopping-cart-items'>
                      {products !== undefined && products !== null
                        ? products.map((item, index) => (
                            <li key={index}>
                              <div className='item-thumb'>
                                <div className='image'>
                                  <img
                                    className='img-fluid'
                                    src={item.products_image}
                                    alt='Product'
                                  />
                                </div>
                              </div>
                              <div className='item-detail'>
                                <h2>{t(`${item.products_name}.1`)}</h2>
                                <div className='item-s'>
                                  {item.quantity} x{' '}
                                  {props.currencyDetails.symbolLeft}
                                  {(
                                    item.products_price *
                                    props.currencyDetails.value
                                  ).toFixed(2)}
                                  <i
                                    className='fas fa-trash'
                                    onClick={() => confirmDelete(index, item)}
                                  />
                                </div>
                              </div>
                            </li>
                          ))
                        : null}
                      <li>
                        <span className='item-summary'>
                          {t('Total.1')}&nbsp;:&nbsp;
                          <span>
                            {props.currencyDetails.symbolLeft}
                            {(total * props.currencyDetails.value).toFixed(2)}
                          </span>
                        </span>
                      </li>
                      <li>
                        <Link
                          className='btn btn-link btn-block '
                          to='/cart-page1'
                        >
                          {t('View Cart.1')}
                        </Link>
                        <Link
                          className='btn btn-secondary btn-block  swipe-to-top'
                          to='/checkout'
                        >
                          {t('Checkout.1')}
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='header-navbar bg-menu-bar'>
        <div className='container'>
          <form className='form-inline'>
            <div className='search-field-module'>
              <div className='search-field-select'>
                <select className='form-control'>
                  <option>{t('All Categories.1')}</option>
                  <option value={1}>{t('Rings.1')} &nbsp;</option>
                  <option value={1}>{t('Earrings.1')}&nbsp;</option>
                  <option value={1}>{t('Crowns.1')}&nbsp;</option>
                  <option value={1}>{t('Necklace.1')}&nbsp;</option>
                  <option value={1}>{t('Bracelet.1')}&nbsp;</option>
                  <option value={1}>{t('Anklet.1')}&nbsp;</option>
                  <option value={1}>{t('Hairpin.1')}&nbsp;</option>
                  <option value={2}>{t('Bangle.1')}&nbsp;</option>
                </select>
              </div>
              <div className='search-field-wrap'>
                <input
                  type='search'
                  placeholder='Search Products...'
                  data-toggle='tooltip'
                  data-placement='bottom'
                  title='search item'
                />
                <button
                  className='btn btn-secondary swipe-to-top'
                  data-toggle='tooltip'
                  data-placement='bottom'
                  title='Search Products'
                >
                  <i className='fa fa-search' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
