export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const LOGOUT = 'LOGOUT';
export const CHANGEPASSWORD_SUCCESS = 'CHANGEPASSWORD_SUCCESS';
export const CHANGEPASSWORD_FAIL = 'CHANGEPASSWORD_FAIL';
export const UPDATEUSERINFO_SUCCESS = 'UPDATEUSERINFO_SUCCESS';
export const UPDATEUSERINFO_FAIL = 'UPDATEUSERINFO_FAIL';
export const ADDSHIPPINGADDRESS_SUCCESS = 'ADDSHIPPINGADDRESS_SUCCESS';
export const ADDSHIPPINGADDRESS_FAIL = 'ADDSHIPPINGADDRESS_FAIL';
export const UPDATESHIPPINGADDRESS_SUCCESS = 'UPDATESHIPPINGADDRESS_SUCCESS';
export const UPDATESHIPPINGADDRESS_FAIL = 'UPDATESHIPPINGADDRESS_FAIL';
export const CONTACTUS_SUCCESS = 'UPDATESHIPPINGADDRESS_SUCCESS';
export const CONTACTUS_FAIL = 'UPDATESHIPPINGADDRESS_FAIL';
export const FORGOTPASSWORD_FAIL = 'FORGOTPASSWORD_FAIL';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';
export const GETALLPRODUCTS_SUCCESS = 'GETALLPRODUCTS_SUCCESS';
export const GETALLPRODUCTS_FAIL = 'GETALLPRODUCTS_FAIL';
export const GETPRODUCTBYID_SUCCESS = 'GETPRODUCTBYID_SUCCESS';
export const GETPRODUCTBYID_FAIL = 'GETPRODUCTBYID_FAIL';
export const ADDTOCART_SUCCESS = 'ADDTOCART_SUCCESS';
export const ADDTOCART_FAIL = 'ADDTOCART_FAIL';
export const ADDTOCART = 'ADDTOCART';
export const ADDORDER_SUCCESS = 'ADDORDER_SUCCESS';
export const ADDORDER_FAIL = 'ADDORDER_FAIL';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const INCREASE_ITEM = 'INCREASE_ITEM';
export const DECREASE_ITEM = 'DECREASE_ITEM';
export const CLEAR_CART = 'CLEAR_CART';
export const DETIAL_ITEM = 'DETIAL_ITEM';
export const DETIAL_INCREASE_ITEM = 'DETIAL_INCREASE_ITEM';
export const DETIAL_DECREASE_ITEM = 'DETIAL_DECREASE_ITEM';
export const ADD_WISHLIST_PRODUCT = 'ADD_WISHLIST_PRODUCT';
export const INCREASE_WISHLIST_PRODUCT = 'INCREASE_WISHLIST_PRODUCT';
export const DECREASE_WISHLIST_PRODCUT = 'DECREASE_WISHLIST_PRODCUT';
export const WISHLIST_REMOVE_ITEM = 'WISHLIST_REMOVE_ITEM';
export const ADD_MODAL_PRODUCT = 'ADD_MODAL_PRODUCT';
export const REMOVE_MODAL_PRODUCT = 'REMOVE_MODAL_PRODUCT';
export const MODAL_INCREASE_ITEM = 'MODAL_INCREASE_ITEM';
export const MODAL_DECREASE_ITEM = 'MODAL_DECREASE_ITEM';
