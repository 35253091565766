import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import MainHeader from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ProductModel from '../../../components/products/product_model';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import RelatedProduct from '../../../components/sliders/related_product_slider';
import { useTranslation } from 'react-i18next';
import { DetailedProduct } from '../../../data_api/products';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  increaseItemQuantity,
  DetaildecreaseItemQuantity,
  addItemToCart,
  addToCart,
  DetailedProducts,
  DetailincreaseItemQuantity
} from '../../../actions/cart';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {productDetailPage} from '../../../components/utils/LoadScript';

const Product_Page2 = props => {
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    // load script for slick crouser
    productDetailPage();
    
    // for insert data into detailproduct in redux
    props.DetailedProducts(DetailedProduct.product_data[0])

    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  // for show alert
  const alert = useAlert();
  // get all product from redux store
  const products = useSelector(state => state.cart.Detail_Products);
  // get cart product from the store 
  const cartProduct = useSelector(state => state.cart.products);

  console.log('detailed products is', products)
  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    console.log('itemQty is', itemQty);
    cartProduct.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      props.increaseItemQuantity(
        productIndex,
        product,
        itemQty + 1
      );
      props.DetailincreaseItemQuantity(
        productIndex,
        product,
        itemQty + 1
      );
    } else {
      props.addItemToCart(product);
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage
    props.addToCart();
  };
  const [formData, setFormData] = useState({
    quantity: 0,
    products: products,
    cart: [],
    subTotal: 0
  });

  const increaseQty = (index, product, quantity) => {
    props.DetailincreaseItemQuantity(index, product, quantity);
    //props.addToCart();
  };

  const decreaseQty = (index, product, quantity) => {
    props.DetaildecreaseItemQuantity(index, product, quantity);
    // props.addToCart();
  };


  return (
    <Fragment>

      {/* //Header Style One */}
      <MainHeader onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail} />
      {/* //Sticky Header */}
      <StickyHeader onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail} />
      {/* //Mobile Header */}
      <MobileHeader onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail} />
      <div className="container-fuild">
        <nav aria-label="breadcrumb">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="#">{t('Home.1')}</Link></li>
              <li className="breadcrumb-item active" aria-current="page">{t('Product.1')}</li>
            </ol>
          </div>
        </nav>
      </div>
      <section className="pro-content product-content">
        <div className="container">
          <div className="product-detail-info">
            <div className="row">
              {products !== undefined &&
                products.length > 0
                ? products.map((product, index) => {
                  return (

                    <div className="col-12 col-sm-12">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <div className="pro-tags">
                                <div className="pro-tag bg-success">{t('New.1')}</div>
                                <div className="pro-tag bg-primary">{t('Featured.1')}</div>
                                <div className="pro-tag">{t('Sale.1')}</div>
                              </div>
                              <h1 className="pro-title">{t(`${product.products_name}.1`)}</h1>
                              <div className="pro-price">
                                <del>{currencyDetail.symbolLeft}
                                  {(
                                    450 *
                                    currencyDetail.value
                                  ).toFixed(2)}</del><ins>{currencyDetail.symbolLeft}
                                  {(
                                    product.products_price *
                                    currencyDetail.value
                                  ).toFixed(2)}</ins>
                              </div>
                              <div className="pro-rating">
                                <i className="fas fa-star" />
                                <i className="fas fa-star" />
                                <i className="fas fa-star" />
                                <i className="fas fa-star" />
                                <i className="fas fa-star-half-alt" />
                                <Link to="/#review" className="btn-link">2 {t('reviews.1')}</Link>
                              </div>
                              <div className="pro-infos">
                                <div className="pro-single-info"><b>{t('Product ID.1')} :</b>1004</div>
                                <div className="pro-single-info"><b>{t('Categroy.1')} :</b><Link to="#">{t('Rings.1')}</Link></div>
                                <div className="pro-single-info">
                                  <b>{t('Tags.1')} :</b>
                                  <ul>
                                    <li><Link to="#">{t('bracelets.1')}</Link></li>
                                    <li><Link to="#">{t('diamond.1')}</Link></li>
                                    <li><Link to="#">{t('ring.1')}</Link></li> </ul>
                                </div>
                                <div className="pro-single-info"><b>{t('Available.1')} :</b><span className="text-secondary">{t('InStock.1')}</span></div>
                                <div className="pro-single-info"><b>{t('Min Order Limit.1')} :</b><Link to="#">5</Link></div>
                              </div>
                              <div className="pro-options">
                                <div className="color-selection">
                                  <h4><b>{t('Color.1')} : </b>{t('Silver.1')}</h4>
                                  <ul>
                                    <li className="active"><Link className="green " href="javascript:void(0);" /></li>
                                    <li><Link className="red " href="javascript:void(0);" /></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="pro-counter">
                                <div className="input-group item-quantity">
                                  <input type="text" id="quantity1" name="quantity" className="form-control " value={product.quantity} disabled />
                                  <span className="input-group-btn">
                                    <button type="button" value="quantity1" className="quantity-plus btn" data-type="plus" data-field onClick={() =>
                                      //dispatch(
                                      increaseQty(
                                        index,
                                        product,
                                        product.quantity + 1
                                      )
                                    }>
                                      <i className="fas fa-plus" />
                                    </button>
                                    {product.quantity > 1 ? (
                                      <button type="button" value="quantity1" className="quantity-minus btn" data-type="minus" data-field onClick={() =>
                                        decreaseQty(
                                          index,
                                          product,
                                          product.quantity - 1
                                        )
                                      }>
                                        <i className="fas fa-minus" />
                                      </button>
                                    ) : null}
                                  </span>
                                </div>
                                <button type="button" className="btn btn-secondary btn-lg swipe-to-top" onClick={() =>
                                  addProductToCart(product)
                                }>{t('Add to Cart.1')}</button>
                              </div>
                              <div className="pro-sub-buttons">
                                <div className="buttons">
                                  <button type="button" className="btn  btn-link" onclick="notificationWishlist();"><i className="fas fa-heart" />{t('Add to Wishlist.1')}</button>
                                  <button type="button" className="btn btn-link" onclick="notificationCompare();"><i className="fas fa-align-right" />{t('Add to Compare.1')}</button>
                                </div>
                                {/* AddToAny BEGIN */}
                                <div className="a2a_kit a2a_kit_size_32 a2a_default_style">
                                  <Link className="a2a_dd" to="/https://www.addtoany.com/share" />
                                  <Link className="a2a_button_facebook" />
                                  <Link className="a2a_button_twitter" />
                                  <Link className="a2a_button_email" />
                                </div>
                                {/* AddToAny END */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <div className="nav nav-pills" role="tablist">
                                <a className="nav-link nav-item  active" href="#description" id="description-tab" data-toggle="pill" role="tab">{t('Description.1')}</a>
                                <a className="nav-link nav-item " href="#additionalInfo" id="additional-info-tab" data-toggle="pill" role="tab">{t('Additional information.1')}</a>
                                <a className="nav-link nav-item" href="#review" id="review-tab" data-toggle="pill" role="tab">{t('Reviews.1')}</a></div>
                              <div className="tab-content">
                                <div role="tabpanel" className="tab-pane fade active show" id="description" aria-labelledby="description-tab">
                                  <p>{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Ut enim ad minim veniam, quis nostrud exercitation.1')}</p> <ul>
                                    <li>{t('Pellentesque consectetur lorem gravida lacinia mollis.1')}</li>
                                    <li>{t('Phasellus vel nisl consequat, sagittis mi nec, dignissim turpis.1')}</li>
                                    <li>{t('Morbi ornare lorem non lacus ultricies fermentum.1')}</li>
                                    <li>{t('Mauris aliquam turpis ut orci egestas luctus.1')}</li>                     </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="additionalInfo" aria-labelledby="additional-info-tab">
                                  <table className="table table-striped table-borderless">
                                    <tbody>
                                      <tr>
                                        <th scope="row">{t('Brand Name.1')}:</th>
                                        <td>{t('lindoMetals.1')}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">{t('Metals Type.1')}:</th>
                                        <td>{t('Silver.1')}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">{t('Main Stone.1')}:</th>
                                        <td>{t('Pearl.1')}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">{t('Style.1')}:</th>
                                        <td>{t('Trendy.1')}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div role="tabpanel" className="tab-pane fade " id="review" aria-labelledby="review-tab">
                                  <div className="reviews">
                                    <div className="review-bubbles">
                                      <h2>
                                        {t('Customer Reviews.1')}
                                      </h2>
                                      <div className="review-bubble-single">
                                        <div className="review-bubble-bg">
                                          <div className="pro-rating">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star-half-alt" />
                                          </div>
                                          <h4>{t('Good.1')}</h4>
                                          <span>{t('Sep 20, 2019.1')}</span>
                                          <p>{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, Ut enim ad minim veniam, quis nostrud exercitation.1')}</p>
                                        </div>
                                      </div>
                                      <div className="review-bubble-single">
                                        <div className="review-bubble-bg">
                                          <div className="pro-rating">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star-half-alt" />
                                          </div>
                                          <h4>{t('NICE WORK.1')}!!!</h4>
                                          <span>{t('Sep 20, 2019.1')}</span>
                                          <p>{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.1')}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="write-review">
                                      <h2>{t('Write a Review.1')}</h2>
                                      <div className="write-review-box">
                                        <div className="from-group row mb-3">
                                          <div className="col-12"> <label htmlFor="inlineFormInputGroup0">{t('Name.1')}</label></div>
                                          <div className="input-group col-12">
                                            <input type="text" className="form-control" id="inlineFormInputGroup0" placeholder="Enter Your Name" />
                                          </div>
                                        </div>
                                        <div className="from-group row mb-3">
                                          <div className="col-12"> <label htmlFor="inlineFormInputGroup1">{t('Email Address.1')}</label></div>
                                          <div className="input-group col-12">
                                            <input type="text" className="form-control" id="inlineFormInputGroup1" placeholder="Enter Your Email" />
                                          </div>
                                        </div>
                                        <div className="from-group row mb-3">
                                          <div className="col-12"> <label htmlFor="inlineFormInputGroup2">{t('Rating.1')}</label></div>
                                          <div className="pro-rating  col-12">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star-half-alt" />
                                          </div>
                                        </div>
                                        <div className="from-group row mb-3">
                                          <div className="col-12"> <label htmlFor="inlineFormInputGroup2">{t('Review Title.1')}</label></div>
                                          <div className="input-group col-12">
                                            <input type="text" className="form-control" id="inlineFormInputGroup2" placeholder="Title of Review" />
                                          </div>
                                        </div>
                                        <div className="from-group row mb-3">
                                          <div className="col-12"> <label htmlFor="inlineFormInputGroup3">{t('Review Body.1')}</label></div>
                                          <div className="input-group col-12">
                                            <textarea className="form-control" id="inlineFormInputGroup3" placeholder="Write Your Review" defaultValue={""} />
                                          </div>
                                        </div>
                                        <div className="from-group">
                                          <button type="button" className="btn btn-secondary swipe-to-top">{t('Submit.1')}</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="slider-wrapper">
                            <div className="slider-for">
                              <a className="slider-for__item ex1 fancybox-button" href="assets/images/gallery/preview/Product_image_01.jpg" data-fancybox-group="fancybox-button" title="Lorem ipsum dolor sit amet">
                                <img src="assets/images/gallery/preview/Product_image_01.jpg" alt="Zoom" />
                              </a>
                              <a className="slider-for__item ex1 fancybox-button" href="assets/images/gallery/preview/Product_image_02.jpg" data-fancybox-group="fancybox-button" title="Lorem ipsum dolor sit amet">
                                <img src="assets/images/gallery/preview/Product_image_02.jpg" alt="Zoom" />
                              </a>
                              <a className="slider-for__item ex1 fancybox-button" href="assets/images/gallery/preview/Product_image_03.jpg" data-fancybox-group="fancybox-button" title="Lorem ipsum dolor sit amet">
                                <img src="assets/images/gallery/preview/Product_image_03.jpg" alt="Zoom" />
                              </a>
                              <a className="slider-for__item ex1 fancybox-button" href="assets/images/gallery/preview/Product_image_04.jpg" data-fancybox-group="fancybox-button" title="Lorem ipsum dolor sit amet">
                                <img src="assets/images/gallery/preview/Product_image_04.jpg" alt="Zoom" />
                              </a>
                              <a className="slider-for__item ex1 fancybox-button" href="assets/images/gallery/preview/Product_image_05.jpg" data-fancybox-group="fancybox-button" title="Lorem ipsum dolor sit amet">
                                <img src="assets/images/gallery/preview/Product_image_05.jpg" alt="Zoom" />
                              </a>
                            </div>
                            <div className="slider-nav">
                              <div className="slider-nav__item">
                                <img src="assets/images/gallery/thumbs/Product_image_01.jpg" alt="Zoom" />
                              </div>
                              <div className="slider-nav__item">
                                <img src="assets/images/gallery/thumbs/Product_image_02.jpg" alt="Zoom" />
                              </div>
                              <div className="slider-nav__item">
                                <img src="assets/images/gallery/thumbs/Product_image_03.jpg" alt="Zoom" />
                              </div>
                              <div className="slider-nav__item">
                                <img src="assets/images/gallery/thumbs/Product_image_04.jpg" alt="Zoom" />
                              </div>
                              <div className="slider-nav__item">
                                <img src="assets/images/gallery/thumbs/Product_image_05.jpg" alt="Zoom" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                : 'null'}
            </div>
          </div>
          {/* related product slider */}

          <RelatedProduct currencyDetails={currencyDetail} />
        </div>
      </section>
      {/* Product Modal */}
      <ProductModel currencyDetails={currencyDetail} />
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  )
}



Product_Page2.propTypes = {
  //setAlert: PropTypes.func.isRequired,
  DetaildecreaseItemQuantity: PropTypes.func.isRequired,
  DetailincreaseItemQuantity: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  DetailedProducts: PropTypes.func.isRequired
};


export default connect(null, {
  DetaildecreaseItemQuantity,
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  DetailedProducts,
  DetailincreaseItemQuantity
})(Product_Page2);

