import {
  ADD_MODAL_PRODUCT,
  MODAL_DECREASE_ITEM,
  MODAL_INCREASE_ITEM
} from './types';

// FOR ADD ITEM TO MODAL
export const addItemToModal = product => {
  return {
    type: ADD_MODAL_PRODUCT,
    product
  };
};

// FOR DECREASE THE QUANTITY OF MODAL PRODUCT
export const ModalProductdecreaseItemQuantity = (index, product, quantity) => {
  return {
    type: MODAL_DECREASE_ITEM,
    index,
    product,
    quantity
  };
};

// FOR INCREASE THE QUANTITY OF MODAL PRODUCT
export const ModalProductincreaseItemQuantity = (index, product, quantity) => {
  return {
    type: MODAL_INCREASE_ITEM,
    index,
    product,
    quantity
  };
};
