import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t, i18n } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* Profile Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Profile.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content profile-content'>
        <div className='container '>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Profile Page.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='media'>
                <img
                  src='assets/images/miscellaneous/avatar.jpg'
                  alt='avatar'
                />
                <div className='media-body'>
                  <h5 className='mt-0'>{t('User Name.1')}</h5>
                  <p>
                    {t('E-mail.1')}:<Link to='#'>info@yourcompanyname.com</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row '>
            <div className=' col-12 col-lg-3'>
              <ul className='list-group mb-4'>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/profile'>
                    <i className='fas fa-user' />
                    {t('Profile.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/wishlist'>
                    <i className='fas fa-heart' />
                    {t('Wishlist.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/orders'>
                    <i className='fas fa-shopping-cart' />
                    {t('Orders.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/shipping-address'>
                    <i className='fas fa-map-marker-alt' />
                    {t('Shipping Address.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='#'>
                    <i className='fas fa-power-off' />
                    {t('Logout.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/change-password'>
                    <i className='fas fa-unlock-alt' />
                    {t('Change Password.1')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-12 col-lg-9 '>
              <form action='/' name='general-form'>
                <div className='row'>
                  <div className='col-12 col-md-7'>
                    <h5>{t('Update Profile.1')}</h5>
                    <div className='from-group row mb-4 pt-3'>
                      <div className='input-group col-12'>
                        <input
                          type='text'
                          name='firstname'
                          className='form-control'
                          id='inlineFormInputGroup0'
                          placeholder='Enter Your First Name'
                        />
                      </div>
                    </div>
                    <div className='from-group row mb-4'>
                      <div className='input-group col-12'>
                        <input
                          type='text'
                          className='form-control'
                          id='inlineFormInputGroup1'
                          placeholder='Enter Your Last Name'
                        />
                      </div>
                    </div>
                    <div className='from-group row mb-4'>
                      <div className='input-group col-12'>
                        <input
                          type='text'
                          name='email'
                          className='form-control'
                          id='inlineFormInputGroup2'
                          placeholder='Enter Your Email'
                        />
                      </div>
                    </div>
                    <div className='from-group row mb-4'>
                      <div className='input-group col-12'>
                        <select
                          className='form-control'
                          id='inlineFormInputGroup3'
                        >
                          <option>{t('Gender.1')}</option>
                          <option>{t('Male.1')}</option>
                          <option>{t('Female.1')}</option>
                        </select>
                      </div>
                    </div>
                    <div className='from-group row mb-4'>
                      <div className='input-group col-12'>
                        <input
                          type='text'
                          name='phone'
                          className='form-control'
                          placeholder='Enter your phone number'
                          id='inlineFormInputGroup4'
                        />
                      </div>
                    </div>
                    <button
                      type='submit'
                      className='btn btn-secondary mt-3 swipe-to-top'
                    >
                      {t('Update.1')}
                    </button>
                  </div>
                  <div className='col-12 col-md-5'>
                    <ul>
                      <li>
                        {t(
                          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.1'
                        )}
                      </li>
                      <li>
                        {t(
                          'Aliquam ac mi ultricies, congue ex vel, aliquam nisi.1'
                        )}
                      </li>
                      <li>
                        {t(
                          'Cras ultrices felis at elit luctus, eget venenatis lacus luctus, Cras vel nisl non ligula euismod elementum.1'
                        )}
                      </li>
                      <li>
                        {t(
                          'Curabitur volutpat risus ornare justo eleifend, id imperdiet neque iaculis, Etiam sagittis eros id rhoncus bibendum, Phasellus et arcu in magna congue fermentum.1'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
              {/* ............the end..... */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Profile;
