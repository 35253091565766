import React, { Fragment, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AllProduct } from '../../../data_api/products';
import { useSelector } from 'react-redux';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts
} from '../../../actions/cart';
import { addItemToModal } from '../../../actions/modalProduct';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

const Product_slider = props => {
  const { t, i18n } = useTranslation();
  // for show alert
  const alert = useAlert();

  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);
  // get all product from redux store
  const products = useSelector(state => state.cart.products);
  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      props.increaseItemQuantity(
        productIndex,
        product,
        (itemQty = itemQty + 1)
      );
    } else {
      props.addItemToCart(product);
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    props.addToCart();
  };
  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      props.AddWishlistProducts(product);

      alert.success('Successfully added to Wishlist!');
    }
  };

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    if (productExists) {
      props.addItemToModal(alreadyModalProduct);
    } else {
      props.addItemToModal(product);
    }
  };

  // setting of slick carouser
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Fragment>
      <section className='pro-content pro-tab-content'>
        <div className='container'>
          <div className='products-area'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-12'>
                <div className='pro-heading-title'>
                  <h1>{t('Welcome to Store.1')}</h1>
                </div>
              </div>
            </div>
            {/* ..........tabs start ......... */}
            <div className='row '>
              <div className='col-md-12 p-0'>
                <div className='nav' role='tablist' id='tabCarousel'>
                  <a
                    className='nav-link  active'
                    data-toggle='tab'
                    href='#featured'
                    role='tab'
                  >
                    {t('TOP SALES.1')}
                  </a>
                  <a
                    className='nav-link'
                    data-toggle='tab'
                    href='#special'
                    role='tab'
                    aria-controls='special'
                    aria-selected='true'
                  >
                    {t('TRENDING.1')}
                  </a>
                  <a
                    className='nav-link'
                    data-toggle='tab'
                    href='#liked'
                    role='tab'
                    aria-controls='liked'
                    aria-selected='true'
                  >
                    {t('winter sale.1')}
                  </a>
                </div>
                {/* Tab panes */}
                <div className='tab-content'>
                  <div
                    role='tabpanel'
                    className='tab-pane fade active show'
                    id='featured'
                  >
                    <Slider className='carousel' {...settings}>
                      {AllProduct.product_data !== undefined &&
                      AllProduct.product_data.length > 0
                        ? AllProduct.product_data

                            .slice(0, 8)

                            .map((product, index) => {
                              return (
                                <div className='slide' key={index}>
                                  <div className='product'>
                                    <article>
                                      <div className='pro-icons mobile-pro-icons d-lg-none d-xl-none'>
                                        <div
                                          className='icon active swipe-to-top'
                                          onClick={() => AddToWishList(product)}
                                        >
                                          <i className='fas fa-heart' />
                                        </div>
                                        <div
                                          className='icon swipe-to-top'
                                          data-toggle='modal'
                                          data-target='#quickViewModal'
                                          onClick={() =>
                                            ShowProductInModal(product)
                                          }
                                        >
                                          <i className='fas fa-eye' />
                                        </div>
                                        <Link
                                          to='/compare'
                                          className='icon swipe-to-top'
                                        >
                                          <i
                                            className='fas fa-align-right'
                                            data-fa-transform='rotate-90'
                                          />
                                        </Link>
                                      </div>
                                      <div className='pro-thumb '>
                                        <div className='pro-tag bg-primary'>
                                          {t('Featured.1')}
                                        </div>
                                        <Link to='/product-page1'>
                                          <span className='pro-image'>
                                            <img
                                              className='img-fluid'
                                              src={product.products_image}
                                              alt='Product'
                                            />
                                          </span>
                                          <span className='pro-image-hover swipe-to-top  d-none d-lg-block d-xl-block'>
                                            <img
                                              className='img-fluid'
                                              src={product.images[0]}
                                              alt='Product'
                                            />
                                          </span>
                                        </Link>
                                        <div className='pro-buttons d-none d-lg-block d-xl-block'>
                                          <div className='pro-icons'>
                                            <div
                                              className='icon active swipe-to-top'
                                              onClick={() =>
                                                AddToWishList(product)
                                              }
                                            >
                                              <i className='fas fa-heart' />
                                            </div>
                                            <div
                                              className='icon swipe-to-top'
                                              data-toggle='modal'
                                              data-target='#quickViewModal'
                                              onClick={() =>
                                                ShowProductInModal(product)
                                              }
                                            >
                                              <i className='fas fa-eye' />
                                            </div>
                                            <Link
                                              to='/compare'
                                              className='icon swipe-to-top'
                                            >
                                              <i
                                                className='fas fa-align-right'
                                                data-fa-transform='rotate-90'
                                              />
                                            </Link>
                                          </div>
                                          <button
                                            type='button'
                                            className='btn btn-secondary btn-block swipe-to-top'
                                            // if user click on add to cart on existing product then update the quantiy of cart
                                            onClick={() =>
                                              addAndUpdatenTheCart(product)
                                            }
                                          >
                                            {t('Add to Cart.1')}
                                          </button>
                                        </div>
                                      </div>
                                      <div className='pro-description'>
                                        <span className='pro-info'>
                                          {t(
                                            `${product.categories[0].categories_name}.1`
                                          )}
                                        </span>
                                        <h2 className='pro-title'>
                                          <Link to='/product-page1'>
                                            {t(`${product.products_name}.1`)}
                                          </Link>
                                        </h2>
                                        <div className='pro-price'>
                                          <ins>
                                            {props.currencyDetails.symbolLeft}
                                            {(
                                              product.products_price *
                                              props.currencyDetails.value
                                            ).toFixed(2)}
                                          </ins>
                                        </div>
                                        <div className='pro-mobile-buttons d-lg-none d-xl-none'>
                                          <button
                                            type='button'
                                            className='btn btn-secondary btn-block swipe-to-top'
                                            onClick={() =>
                                              addAndUpdatenTheCart(product)
                                            }
                                          >
                                            {t('Add to Cart.1')}
                                          </button>
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                </div>
                              );
                            })
                        : 'null'}

                      {/* <!-- 1st tab -->  */}
                    </Slider>

                    {/* 2nd tab */}
                  </div>
                  <div role='tabpanel' className='tab-pane fade' id='special'>
                    <Slider className='carousel' {...settings}>
                      {AllProduct.product_data !== undefined &&
                      AllProduct.product_data.length > 0
                        ? AllProduct.product_data
                            .slice(0, 8)
                            .reverse()
                            .map((product, index) => {
                              return (
                                <div className='slide' key={index}>
                                  <div className='product'>
                                    <article>
                                      <div className='pro-icons mobile-pro-icons d-lg-none d-xl-none'>
                                        <div
                                          className='icon active swipe-to-top'
                                          onClick={() => AddToWishList(product)}
                                        >
                                          <i className='fas fa-heart' />
                                        </div>
                                        <div
                                          className='icon swipe-to-top'
                                          data-toggle='modal'
                                          data-target='#quickViewModal'
                                          onClick={() =>
                                            ShowProductInModal(product)
                                          }
                                        >
                                          <i className='fas fa-eye' />
                                        </div>
                                        <Link
                                          to='/compare'
                                          className='icon swipe-to-top'
                                        >
                                          <i
                                            className='fas fa-align-right'
                                            data-fa-transform='rotate-90'
                                          />
                                        </Link>
                                      </div>
                                      <div className='pro-thumb '>
                                        <div className='pro-tag bg-primary'>
                                          {t('Featured.1')}
                                        </div>
                                        <Link to='/product-page1'>
                                          <span className='pro-image'>
                                            <img
                                              className='img-fluid'
                                              src={product.products_image}
                                              alt='Product'
                                            />
                                          </span>
                                          <span className='pro-image-hover swipe-to-top  d-none d-lg-block d-xl-block'>
                                            <img
                                              className='img-fluid'
                                              src={product.images[0]}
                                              alt='Product'
                                            />
                                          </span>
                                        </Link>
                                        <div className='pro-buttons d-none d-lg-block d-xl-block'>
                                          <div className='pro-icons'>
                                            <div
                                              className='icon active swipe-to-top'
                                              onClick={() =>
                                                AddToWishList(product)
                                              }
                                            >
                                              <i className='fas fa-heart' />
                                            </div>
                                            <div
                                              className='icon swipe-to-top'
                                              data-toggle='modal'
                                              data-target='#quickViewModal'
                                              onClick={() =>
                                                ShowProductInModal(product)
                                              }
                                            >
                                              <i className='fas fa-eye' />
                                            </div>
                                            <Link
                                              to='/compare'
                                              className='icon swipe-to-top'
                                            >
                                              <i
                                                className='fas fa-align-right'
                                                data-fa-transform='rotate-90'
                                              />
                                            </Link>
                                          </div>
                                          <button
                                            type='button'
                                            className='btn btn-secondary btn-block swipe-to-top'
                                            // if user click on add to cart on existing product then update the quantiy of cart
                                            onClick={() =>
                                              addAndUpdatenTheCart(product)
                                            }
                                          >
                                            {t('Add to Cart.1')}
                                          </button>
                                        </div>
                                      </div>
                                      <div className='pro-description'>
                                        <span className='pro-info'>
                                          {t(
                                            `${product.categories[0].categories_name}.1`
                                          )}
                                        </span>
                                        <h2 className='pro-title'>
                                          <Link to='/product-page1'>
                                            {t(`${product.products_name}.1`)}
                                          </Link>
                                        </h2>
                                        <div className='pro-price'>
                                          <ins>
                                            {props.currencyDetails.symbolLeft}
                                            {(
                                              product.products_price *
                                              props.currencyDetails.value
                                            ).toFixed(2)}
                                          </ins>
                                        </div>
                                        <div className='pro-mobile-buttons d-lg-none d-xl-none'>
                                          <button
                                            type='button'
                                            className='btn btn-secondary btn-block swipe-to-top'
                                            onClick={() =>
                                              addAndUpdatenTheCart(product)
                                            }
                                          >
                                            {t('Add to Cart.1')}
                                          </button>
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                </div>
                              );
                            })
                        : 'null'}

                      {/* <!-- 2nd tab -->  */}
                    </Slider>
                  </div>
                  {/* 3rd tab */}

                  <div role='tabpanel' className='tab-pane fade' id='liked'>
                    <Slider className='carousel' {...settings}>
                      {AllProduct.product_data !== undefined &&
                      AllProduct.product_data.length > 0
                        ? AllProduct.product_data
                            .slice(0, 8)
                            .map((product, index) => {
                              return (
                                <div className='slide' key={index}>
                                  <div className='product'>
                                    <article>
                                      <div className='pro-icons mobile-pro-icons d-lg-none d-xl-none'>
                                        <div
                                          className='icon active swipe-to-top'
                                          onClick={() => AddToWishList(product)}
                                        >
                                          <i className='fas fa-heart' />
                                        </div>
                                        <div
                                          className='icon swipe-to-top'
                                          data-toggle='modal'
                                          data-target='#quickViewModal'
                                          onClick={() =>
                                            ShowProductInModal(product)
                                          }
                                        >
                                          <i className='fas fa-eye' />
                                        </div>
                                        <Link
                                          to='/compare'
                                          className='icon swipe-to-top'
                                        >
                                          <i
                                            className='fas fa-align-right'
                                            data-fa-transform='rotate-90'
                                          />
                                        </Link>
                                      </div>
                                      <div className='pro-thumb '>
                                        <div className='pro-tag bg-primary'>
                                          {t('Featured.1')}
                                        </div>
                                        <Link to='/product-page1'>
                                          <span className='pro-image'>
                                            <img
                                              className='img-fluid'
                                              src={product.products_image}
                                              alt='Product'
                                            />
                                          </span>
                                          <span className='pro-image-hover swipe-to-top  d-none d-lg-block d-xl-block'>
                                            <img
                                              className='img-fluid'
                                              src={product.images[0]}
                                              alt='Product'
                                            />
                                          </span>
                                        </Link>
                                        <div className='pro-buttons d-none d-lg-block d-xl-block'>
                                          <div className='pro-icons'>
                                            <div
                                              className='icon active swipe-to-top'
                                              onClick={() =>
                                                AddToWishList(product)
                                              }
                                            >
                                              <i className='fas fa-heart' />
                                            </div>
                                            <div
                                              className='icon swipe-to-top'
                                              data-toggle='modal'
                                              data-target='#quickViewModal'
                                              onClick={() =>
                                                ShowProductInModal(product)
                                              }
                                            >
                                              <i className='fas fa-eye' />
                                            </div>
                                            <Link
                                              to='/compare'
                                              className='icon swipe-to-top'
                                            >
                                              <i
                                                className='fas fa-align-right'
                                                data-fa-transform='rotate-90'
                                              />
                                            </Link>
                                          </div>
                                          <button
                                            type='button'
                                            className='btn btn-secondary btn-block swipe-to-top'
                                            // if user click on add to cart on existing product then update the quantiy of cart
                                            onClick={() =>
                                              addAndUpdatenTheCart(product)
                                            }
                                          >
                                            {t('Add to Cart.1')}
                                          </button>
                                        </div>
                                      </div>
                                      <div className='pro-description'>
                                        <span className='pro-info'>
                                          {t(
                                            `${product.categories[0].categories_name}.1`
                                          )}
                                        </span>
                                        <h2 className='pro-title'>
                                          <Link to='/product-page1'>
                                            {t(`${product.products_name}.1`)}
                                          </Link>
                                        </h2>
                                        <div className='pro-price'>
                                          <ins>
                                            {props.currencyDetails.symbolLeft}
                                            {(
                                              product.products_price *
                                              props.currencyDetails.value
                                            ).toFixed(2)}
                                          </ins>
                                        </div>
                                        <div className='pro-mobile-buttons d-lg-none d-xl-none'>
                                          <button
                                            type='button'
                                            className='btn btn-secondary btn-block swipe-to-top'
                                            onClick={() =>
                                              addAndUpdatenTheCart(product)
                                            }
                                          >
                                            {t('Add to Cart.1')}
                                          </button>
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                </div>
                              );
                            })
                        : 'null'}

                      {/* <!-- 3rd tab -->  */}
                    </Slider>
                    {/* 3rd tab */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Product_slider.propTypes = {
  increaseItemQuantity: PropTypes.func.isRequired,
  AddWishlistProducts: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  addItemToModal: PropTypes.func.isRequired
};

export default withRouter(
  connect(null, {
    increaseItemQuantity,
    addItemToCart,
    addToCart,
    AddWishlistProducts,
    addItemToModal
  })(Product_slider)
);
