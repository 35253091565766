import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AllProduct } from '../../../data_api/products';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  increaseItemQuantity,
  addItemToCart,
  addToCart,
  AddWishlistProducts
} from '../../../actions/cart';
import { addItemToModal } from '../../../actions/modalProduct';
import Slider from 'react-slick';

const New_Arrival = props => {
  const { t } = useTranslation();
  // for use dispatach action from store
  const dispatch = useDispatch();

  // for show alert
  const alert = useAlert();
  // get modal product from store
  const ModalProduct = useSelector(state => state.cart.ModalProducts);

  // get all wishlist product from redux
  const WishlistProducts = useSelector(state => state.cart.Wishlist_Products);
  /// add porduct to wishlist
  const AddToWishList = item => {
    const product = Object.assign({}, item, { quantity: 1 });
    let productExists = false;
    let productIndex = -1;
    WishlistProducts.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      alert.success(`Already in Wishlist!`);
    } else {
      dispatch(AddWishlistProducts(product));

      alert.success('Successfully added to Wishlist!');
    }
  };

  // get all product from redux store
  const products = useSelector(state => state.cart.products);

  // add to cart function
  const addProductToCart = item => {
    const product = item;
    let itemQty = product.quantity;
    let productExists = false;
    let productIndex = -1;
    products.forEach((p, idx) => {
      if (product.id === p.id) {
        productExists = true;
        productIndex = idx;
      }
    });
    if (productExists) {
      if (itemQty === undefined) {
        itemQty = 1;
      } else {
        itemQty = product.quantity;
      }
      alert.success(`Already in cart!`);
      dispatch(
        increaseItemQuantity(productIndex, product, (itemQty = itemQty + 1))
      );
    } else {
      dispatch(addItemToCart(product));
      alert.success('Successfully added to cart!');
    }
    // to add the product in localstorage

    dispatch(addToCart());
  };

  // add and update the cart button
  const addAndUpdatenTheCart = item => {
    let product = item;
    let productExists = false;
    products.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        // assign product from redux cart
        product = p;
      }
    });
    if (productExists) {
      addProductToCart(product);
    } else {
      addProductToCart(product);
    }
  };

  // for show Single product in modal
  const ShowProductInModal = item => {
    let product = item;
    let productExists = false;
    let alreadyModalProduct = '';
    ModalProduct.forEach((p, idx) => {
      if (product.products_id === p.products_id) {
        productExists = true;
        alreadyModalProduct = p;
      }
    });

    if (productExists) {
      dispatch(addItemToModal(alreadyModalProduct));
    } else {
      dispatch(addItemToModal(product));
    }
  };

  // for slider config
  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <section className='pro-content pro-pl-content'>
        <div className='container'>
          <div className='products-area'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-12'>
                <div className='pro-heading-title'>
                  <h2> {t('New Arrivals.1')}</h2>
                  <p>
                    {t(
                      'Vitae posuere urna blandit sed Praesent ut dignissim risus.1'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 p-0'>
                <Slider className='carousel' {...settings}>
                  {AllProduct.product_data !== undefined &&
                  AllProduct.product_data.length > 0
                    ? AllProduct.product_data
                        .slice(0, 4)
                        .map((product, index) => {
                          return (
                            <div className='slide' key={index}>
                              <div className='popular-product'>
                                <article>
                                  <div className='pro-thumb'>
                                    <div className='pro-icons mobile-pro-icons d-lg-none d-xl-none'>
                                      <div
                                        to='/wishlist'
                                        className='icon active swipe-to-top'
                                        onClick={() => AddToWishList(product)}
                                      >
                                        <i className='fas fa-heart' />
                                      </div>
                                      <div
                                        className='icon swipe-to-top'
                                        data-toggle='modal'
                                        data-target='#quickViewModal'
                                        onClick={() =>
                                          ShowProductInModal(product)
                                        }
                                      >
                                        <i className='fas fa-eye' />
                                      </div>
                                      <Link
                                        to='/compare'
                                        className='icon swipe-to-top'
                                      >
                                        <i
                                          className='fas fa-align-right'
                                          data-fa-transform='rotate-90'
                                        />
                                      </Link>
                                    </div>
                                    <Link
                                      to={
                                        '/product-page1/' + product.products_id
                                      }
                                    >
                                      <span className='pro-image'>
                                        <img
                                          className='img-fluid'
                                          src={product.products_image}
                                          alt='Product'
                                        />
                                      </span>
                                      <span className='pro-image-hover swipe-to-top'>
                                        <img
                                          className='img-fluid'
                                          src={product.images[0]}
                                          alt='Product'
                                        />
                                      </span>
                                    </Link>
                                    <div className='pro-buttons d-none d-lg-block d-xl-block'>
                                      <div className='pro-icons'>
                                        <div
                                          onClick={() => AddToWishList(product)}
                                          className='icon active swipe-to-top'
                                        >
                                          <i className='fas fa-heart' />
                                        </div>
                                        <div
                                          className='icon swipe-to-top'
                                          data-toggle='modal'
                                          data-target='#quickViewModal'
                                          onClick={() =>
                                            ShowProductInModal(product)
                                          }
                                        >
                                          <i className='fas fa-eye' />
                                        </div>
                                        <Link
                                          to='/compare'
                                          className='icon swipe-to-top'
                                        >
                                          <i
                                            className='fas fa-align-right'
                                            data-fa-transform='rotate-90'
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className='pro-tag pro-success'>
                                      {t('NEW.1')}
                                    </div>
                                  </div>
                                  <div className='pro-description'>
                                    <div>
                                      <span className='pro-info'>
                                        {t(
                                          `${product.categories[0].categories_name}.1`
                                        )}
                                      </span>
                                      <h2 className='pro-title'>
                                        <Link to='/product-page1'>
                                          {t(`${product.products_name}.1`)}
                                        </Link>
                                      </h2>
                                      <div className='pro-price'>
                                        <del>
                                          {props.currencyDetails.symbolLeft}
                                          {(
                                            400 * props.currencyDetails.value
                                          ).toFixed(2)}
                                        </del>
                                        <ins>
                                          {props.currencyDetails.symbolLeft}
                                          {(
                                            product.products_price *
                                            props.currencyDetails.value
                                          ).toFixed(2)}
                                        </ins>
                                      </div>
                                      <div className='pro-options'>
                                        <div className='color-selection'>
                                          <h4>
                                            <b>{t('Color.1')}&nbsp;:</b>&nbsp;
                                            {t('Silver.1')}
                                          </h4>
                                          <ul>
                                            <li className='active'>
                                              <a
                                                className='green '
                                                href='#'
                                              ></a>
                                            </li>
                                            <li>
                                              <a className='red ' href='#'></a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <button
                                        type='button'
                                        className='btn btn-secondary btn-block swipe-to-top'
                                        //onclick='notificationCart();'
                                        onClick={() =>
                                          addAndUpdatenTheCart(product)
                                        }
                                      >
                                        {t('Add to Cart.1')}
                                      </button>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                    : 'null'}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default New_Arrival;
