import React, { Fragment, useState, useEffect } from 'react';
import { loadHomeScript } from '../../../components/utils/LoadScript';
import MainHeader from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import MobileFooter from '../../../layouts/footer/mobile-footer';
import MainFooter from '../../../layouts/footer/main-footer';
import ArrowBackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetterModal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import OurTeam from '../../../components/sliders/our-team-slider';
import { useTranslation } from 'react-i18next';

const About_Page2 = () => {
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    loadHomeScript();
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <MainHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* About-us Content */}
      <section className='pro-content aboutus-content '>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='pro-heading-title'>
              <h1>{t('Welcome To Katora.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-7'>
              <p className='peragraph'>
                {t(
                  'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}
              </p>
              <p className='peragraph'>
                {t(
                  'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}
              </p>
            </div>
            <div className='col-12 col-md-5'>
              <video width={320} height={240} controls>
                <source src='*.mp4' type='video/mp4' />
                <source src='*.ogg' type='video/ogg' />
                {t('Your browser does not support the video tag.1')}
              </video>
            </div>
          </div>
        </div>
        <div className='container-fluid accordion-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='pro-heading-title'>
                  <h1>{t('Our Vision.1')}</h1>
                </div>
                <section className='accordions'>
                  {/*Accordion wrapper*/}
                  <div
                    className='accordion md-accordion'
                    id='accordionEx'
                    role='tablist'
                    aria-multiselectable='true'
                  >
                    {/* Accordion card */}
                    <div className='card'>
                      {/* Card header */}
                      <div className='card-header' role='tab' id='headingOne1'>
                        <a
                          data-toggle='collapse'
                          data-parent='#accordionEx'
                          href='#collapseOne1'
                          aria-expanded='true'
                          aria-controls='collapseOne1'
                        >
                          <h5 className='mb-0'>{t('Who We Are.1')}</h5>
                        </a>
                      </div>
                      {/* Card body */}
                      <div
                        id='collapseOne1'
                        className='collapse show'
                        role='tabpanel'
                        aria-labelledby='headingOne1'
                        data-parent='#accordionEx'
                      >
                        <div className='card-body'>
                          {t(
                            'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Accordion card */}
                    <div className='card'>
                      {/* Card header */}
                      <div className='card-header' role='tab' id='headingTwo2'>
                        <a
                          className='collapsed'
                          data-toggle='collapse'
                          data-parent='#accordionEx'
                          href='#collapseTwo2'
                          aria-expanded='false'
                          aria-controls='collapseTwo2'
                        >
                          <h5 className='mb-0'>{t('What We Want.1')}</h5>
                        </a>
                      </div>
                      {/* Card body */}
                      <div
                        id='collapseTwo2'
                        className='collapse'
                        role='tabpanel'
                        aria-labelledby='headingTwo2'
                        data-parent='#accordionEx'
                      >
                        <div className='card-body'>
                          {t(
                            'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Accordion card */}
                    <div className='card'>
                      {/* Card header */}
                      <div
                        className='card-header'
                        role='tab'
                        id='headingThree3'
                      >
                        <a
                          className='collapsed'
                          data-toggle='collapse'
                          data-parent='#accordionEx'
                          href='#collapseThree3'
                          aria-expanded='false'
                          aria-controls='collapseThree3'
                        >
                          <h5 className='mb-0'>{t('What We Can Give.1')}</h5>
                        </a>
                      </div>
                      {/* Card body */}
                      <div
                        id='collapseThree3'
                        className='collapse'
                        role='tabpanel'
                        aria-labelledby='headingThree3'
                        data-parent='#accordionEx'
                      >
                        <div className='card-body'>
                          {t(
                            'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Accordion card */}
                  </div>
                  {/* Accordion wrapper */}
                </section>
              </div>
              <div className='col-12 col-md-6'>
                <div className='pro-heading-title'>
                  <h1>{t('Our Offer.1')}</h1>
                </div>
                <p className='peragraph peragraph2'>
                  {t(
                    'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                  )}
                </p>
                <p className='peragraph'>
                  {t(
                    'In a turpis sed tortor semper imperdiet, Duis eu interdum enim, Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <OurTeam />
      </section>
      {/* Footer Mobile */}
      <MobileFooter />
      {/* //footer style three */}
      <MainFooter />
      {/* arrow back to top */}
      <ArrowBackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetterModal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default About_Page2;
