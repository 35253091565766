import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Banner_Categories = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className='pro-content categories-content'>
        <div className='container-fuild'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <div className='banner-category'>
                <img
                  className='img-fluid'
                  src='assets/images/banners_demo_3/banner_1.jpg'
                  alt='Product'
                />
                <Link to='#' className='btn btn-secondary btn-lg swipe-to-top'>
                  {t('New.1')}
                </Link>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='banner-category '>
                <img
                  className='img-fluid'
                  src='assets/images/banners_demo_3/banner_2.jpg'
                  alt='Product'
                />
                <Link to='#' className='btn btn-secondary btn-lg swipe-to-top'>
                  {t('Trending.1')}
                </Link>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='banner-category last'>
                <img
                  className='img-fluid'
                  src='assets/images/banners_demo_3/banner_3.jpg'
                  alt='Product'
                />
                <Link to='#' className='btn btn-secondary btn-lg swipe-to-top '>
                  {t('Sale.1')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner_Categories;
