import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';

const Orders = () => {
  const { t } = useTranslation();

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/*My Order Content */}
      <div className='container-fuild'>
        <nav aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='#'>{t('Home.1')}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {t('Orders.1')}
              </li>
            </ol>
          </div>
        </nav>
      </div>
      <section className='pro-content order-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Order Page.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-3   d-block d-xl-block'>
              <ul className='list-group mb-4'>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/profile'>
                    <i className='fas fa-user' />
                    {t('Profile.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/wishlist'>
                    <i className='fas fa-heart' />
                    {t('Wishlist.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/orders'>
                    <i className='fas fa-shopping-cart' />
                    {t('Orders.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/shipping-address'>
                    <i className='fas fa-map-marker-alt' />
                    {t('Shipping Address.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='#'>
                    <i className='fas fa-power-off' />
                    {t('Logout.1')}
                  </Link>
                </li>
                <li className='list-group-item'>
                  <Link className='nav-link' to='/change-password'>
                    <i className='fas fa-unlock-alt' />
                    {t('Change Password.1')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-12 col-lg-9 '>
              <table className='table order-table'>
                <thead>
                  <tr className='d-flex'>
                    <th className='col-12 d-block d-md-none' />
                    <th className='col-12 col-md-3'>{t('Order ID.1')}</th>
                    <th className='col-12 col-md-3'>{t('Order Date.1')}</th>
                    <th className='col-12 col-md-3'>{t('Total Price.1')}</th>
                    <th className='col-12 col-md-3'>{t('Order Status.1')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='d-flex'>
                    <td className='col-12 d-block d-md-none'>
                      <span>{t('order.1')}# 1</span>
                    </td>
                    <td className='col-12 col-md-3'>
                      <Link to='/order-detail'>354365G4</Link>
                    </td>
                    <td className='col-12 col-md-3'>
                      {t('25th April 2019.1')}
                    </td>
                    <td className='col-12 col-md-3'>$890.00</td>
                    <td className='col-12 col-md-3'>
                      <div className='text-secondary'>{t('Pending.1')}</div>
                    </td>
                  </tr>
                  <tr className='d-flex'>
                    <td className='col-12 d-block d-md-none'>
                      <span>{t('order.1')}#2</span>
                    </td>
                    <td className='col-12 col-md-3'>
                      <Link to='/order-detail'>845s5112</Link>
                    </td>
                    <td className='col-12 col-md-3'>{t('25th Dec 2010.1')}</td>
                    <td className='col-12 col-md-3'>$896.00</td>
                    <td className='col-12 col-md-3'>
                      <div className='text-success'>{t('Confirm.1')}</div>
                    </td>
                  </tr>
                  <tr className='d-flex'>
                    <td className='col-12 d-block d-md-none'>
                      <span>{t('order.1')}#3</span>
                    </td>
                    <td className='col-12 col-md-3'>
                      <Link to='/order-detail'>se874142</Link>
                    </td>
                    <td className='col-12 col-md-3'>{t('25th May 2009.1')}</td>
                    <td className='col-12 col-md-3'>$8580.00</td>
                    <td className='col-12 col-md-3'>
                      <div className='text-danger'>{t('Cancel.1')}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='pagination justify-content-between '>
                <label className='col-form-label'>
                  {t('Showing.1')} 1–<span className='showing_record'>1</span>
                  &nbsp;{t('of.1')}&nbsp;
                  <span className='showing_total_record'>23</span>&nbsp;
                  {t('results.1')}.
                </label>
                <div className='col-12 col-sm-6'>
                  <ol className='loader-page'>
                    <li className='loader-page-item'>
                      <Link to='#'>
                        <i
                          className='fa fa-angle-double-left'
                          style={{ fontSize: 12 }}
                        />
                      </Link>
                    </li>
                    <li className='loader-page-item'>
                      <Link to='#'>1</Link>
                    </li>
                    <li className='loader-page-item'>
                      <Link to='#'>2</Link>
                    </li>
                    <li className='loader-page-item'>
                      <Link to='#'>3</Link>
                    </li>
                    <li className='loader-page-item'>
                      <Link to='#'>4</Link>
                    </li>
                    <li className='loader-page-item'>
                      <Link to='#'>
                        <i
                          className='fa fa-angle-double-right'
                          style={{ fontSize: 12 }}
                        />
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
              {/* ............the end..... */}
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Orders;
