import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main_Header from '../../../layouts/header/main-header/header-one';
import { currency } from '../../../components/utils/currency_array';
import StickyHeader from '../../../layouts/header/sticky-header/header-one';
import MobileHeader from '../../../layouts/header/mobile-header/header-one';
import Mobile_Footer from '../../../layouts/footer/mobile-footer';
import Main_Footer from '../../../layouts/footer/main-footer';
import Arrow_BackToTop from '../../../components/arrow-backToTop';
import Switcher from '../../../components/switcher';
import NewsLetter_Modal from '../../../components/newslatter-modal';
import Cookies from '../../../components/cookies';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Contact_Page1 = () => {
  const { t} = useTranslation();

  // for map
  const [mapState, setMapState] = useState({
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  });

  const { center, zoom } = mapState;

  // save currency state from localStorage
  const [currencyDetail, setCurrencyDetail] = useState('');
  useEffect(() => {
    ///////////////// ===== save currency varible in localStroge for currency changed
    //=== get currency from storage
    let LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));

    if (LocalCurrencyDetail === null || LocalCurrencyDetail === undefined) {
      localStorage.setItem('currency', JSON.stringify(currency[0]));
      LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    }
    setCurrencyDetail(LocalCurrencyDetail);
  }, []);

  const onChangeCurrencies = i => {
    // ===== save currency varible in localStroge for currency changed
    localStorage.setItem('currency', JSON.stringify(currency[i]));
    const LocalCurrencyDetail = JSON.parse(localStorage.getItem('currency'));
    setCurrencyDetail(LocalCurrencyDetail);
  };
  /////////////////////////////////////

  return (
    <Fragment>
      {/* //Header Style One */}
      <Main_Header
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Sticky Header */}
      <StickyHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* //Mobile Header */}
      <MobileHeader
        onCurrencyChanged={onChangeCurrencies}
        currencyDetails={currencyDetail}
      />
      {/* contact Content */}
      <div className='container-fuild'>
        <div style={{ height: 400, margin: '0 auto' }}>
          <GoogleMapReact
            // bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            <AnyReactComponent lat={30.3753} lng={69.3451} text='My Marker' />
          </GoogleMapReact>
        </div>
      </div>
      <section className='pro-content contact-content'>
        <div className='container'>
          <div className='row'>
            <div className='pro-heading-title'>
              <h1>{t('Contact.1')}</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-9'>
              <p>
                {t(
                  'Sed convallis magna in tortor fermentum, sit amet accumsan diam malesuada, Aliquam urna turpis, sodales et tempor nec, dignissim eu odio, Vivamus et mattis urna, Praesent ullamcorper ut est non lobortis, Praesent dignissim et erat a luctus, Pellentesque imperdiet, lacus quis euismod elementum, est quam sagittis tellus, sit amet ullamcorper erat nibh a dolor, Proin tincidunt eu sapien quis sollicitudin, In a turpis sed tortor semper imperdiet, Duis eu interdum enim. Donec rutrum nunc eros, Vivamus eu massa dignissim, interdum dolor ac, commodo nibh, Phasellus ipsum justo, ullamcorper nec fringilla ut, condimentum vel elit, Mauris tincidunt dui eu velit dapibus, tempor blandit massa accumsan, Sed vitae ante ultricies, dapibus diam at, congue quam, Aliquam at eros turpis, Sed molestie feugiat tristique, Duis molestie eu tortor ut pretium, Duis risus felis, pretium ut porta id, mattis a tellus, Quisque convallis urna in pharetra cursus, Maecenas vitae vulputate lorem, Vivamus vestibulum imperdiet lacus, sit amet vehicula justo placerat non, Maecenas cursus lorem nibh, a efficitur nulla facilisis in.1'
                )}
              </p>
              <form action='#' name='contact' id='theForm' method='POST'>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      name='name'
                      className='form-control'
                      placeholder='Name'
                    />
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      name='email'
                      className='form-control'
                      placeholder='Email'
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      name='phone'
                      className='form-control'
                      placeholder='Phone'
                    />
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      name='subject'
                      className='form-control'
                      placeholder='Subject'
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <textarea
                      className='form-control'
                      name='msg'
                      placeholder='Message'
                      rows={5}
                      cols={56}
                      defaultValue={''}
                    />
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-secondary swipe-to-top'
                >
                  {t('Send.1')}
                </button>
                <div
                  id='alert-box'
                  className='alert alert-success alert-dismissible'
                  role='alert'
                >
                  <div id='alert-msg' />
                  <button
                    type='button'
                    className='close'
                    data-dismiss='alert'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
              </form>
            </div>
            <div className='col-12 col-lg-3'>
              <ul className='contact-info pl-0 mb-0'>
                <li>
                  <i className='fas fa-map-marker' />
                  <span>
                    Katora
                    <br />
                    {t('Demo Store 3654123.1')}
                  </span>
                </li>
                <li>
                  <i className='fas fa-envelope' />
                  <span>
                    Support@example.com
                    <br />
                    info@example.com
                  </span>
                </li>
                <li>
                  <i className='fas fa-mobile-alt' />
                  <span>
                    888-9636-6000
                    <br /> 888-9636-6000
                  </span>
                </li>
              </ul>
              <div className='pro-socials'>
                <h4>{t('Follow Us.1')}</h4>
                <ul>
                  <li>
                    <Link to='#' className='fab fb fa-facebook-square' />
                  </li>
                  <li>
                    <Link to='#' className='fab tw fa-twitter-square' />
                  </li>
                  <li>
                    <Link to='#' className='fab sk fa-skype' />
                  </li>
                  <li>
                    <Link to='#' className='fab ln fa-linkedin' />
                  </li>
                  <li>
                    <Link to='#' className='fab ig fa-instagram' />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Mobile */}
      <Mobile_Footer />
      {/* //footer style three */}
      <Main_Footer />
      {/* arrow back to top */}
      <Arrow_BackToTop />
      {/* change color switcher */}
      <Switcher />
      {/* Newsletter Modal */}
      <NewsLetter_Modal />
      {/* cookies section */}
      <Cookies />
    </Fragment>
  );
};

export default Contact_Page1;
