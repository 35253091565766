import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const Papular_Categories_Slider = () => {
  const { t } = useTranslation();

  const settings = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <div className='pro-content categories-content'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-12'>
              <div className='pro-heading-title'>
                <h1> {t('Popular Categories.1')}</h1>
                <p>
                  {t(
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.1'
                  )}
                </p>
              </div>
            </div>
          </div>
          {/* Categories */}
          <Slider className='carousel' {...settings}>
            <div className='slide'>
              <div className='pro-Categories'>
                <div className='pro-Categories-thumb swipe-to-top'>
                  <div className='pro-Categories-title'>
                    <Link to='#'>{t('Mobiles and Tablets.1')}</Link>
                  </div>
                  <img
                    className='img-fluid '
                    src='assets/images/categories/category_image_1.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-Categories-detail'>
                  <h2>
                    <Link to='#'>{t('Mobiles and Tablets.1')}</Link>
                  </h2>
                  <ul className='pro-Categories-meta'>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Apple.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Samsung.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('PowerBank.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/shop-page1' tabIndex={0}>
                        {t('See all categories.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='pro-Categories'>
                <div className='pro-Categories-thumb'>
                  <div className='pro-Categories-title'>
                    <Link to='#'>
                      {t('Computers.1')} &amp; {t('Accessories.1')}
                    </Link>
                  </div>
                  <img
                    className='img-fluid swipe-to-top'
                    src='assets/images/categories/category_image_2.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-Categories-detail'>
                  <h2>
                    <Link to='#'>
                      {t('Computers.1')} &amp; {t('Accessories.1')}
                    </Link>
                  </h2>
                  <ul className='pro-Categories-meta'>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Computers.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Laptops.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Monitors.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/shop-page1' tabIndex={0}>
                        {t('See all categories.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='pro-Categories'>
                <div className='pro-Categories-thumb'>
                  <div className='pro-Categories-title'>
                    <Link to='#'>
                      {t('Cameras.1')}, {t('Audio.1')} &amp; {t('Video.1')}
                    </Link>
                  </div>
                  <img
                    className='img-fluid swipe-to-top'
                    src='assets/images/categories/category_image_3.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-Categories-detail'>
                  <h2>
                    <Link to='#'>
                      {t('Cameras.1')}, {t('Audio.1')} &amp; {t('Video.1')}
                    </Link>
                  </h2>
                  <ul className='pro-Categories-meta'>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Audio.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Video.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Headphones.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/shop-page1' tabIndex={0}>
                        {t('See all categories.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='pro-Categories'>
                <div className='pro-Categories-thumb'>
                  <div className='pro-Categories-title'>
                    <Link to='#'>{t('Home appliances.1')}</Link>
                  </div>
                  <img
                    className='img-fluid swipe-to-top'
                    src='assets/images/categories/category_image_4.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-Categories-detail'>
                  <h2>
                    <Link to='#'>{t('Home appliances.1')}</Link>
                  </h2>
                  <ul className='pro-Categories-meta'>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('TV Accessories.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Air Conditioner.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Dryer.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/shop-page1' tabIndex={0}>
                        {t('See all categories.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='pro-Categories'>
                <div className='pro-Categories-thumb'>
                  <div className='pro-Categories-title'>
                    <Link to='#'>{t('Kitchen Appliances.1')}</Link>
                  </div>
                  <img
                    className='img-fluid swipe-to-top'
                    src='assets/images/categories/category_image_5.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-Categories-detail'>
                  <h2>
                    <Link to='#'>{t('Kitchen Appliances.1')}</Link>
                  </h2>
                  <ul className='pro-Categories-meta'>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Bakeware.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Cookware.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Gas Stoves.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/shop-page1' tabIndex={0}>
                        {t('See all categories.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='slide'>
              <div className='pro-Categories'>
                <div className='pro-Categories-thumb'>
                  <div className='pro-Categories-title'>
                    <Link to='#'>{t('Music and video games.1')}</Link>
                  </div>
                  <img
                    className='img-fluid swipe-to-top'
                    src='assets/images/categories/category_image_6.jpg'
                    alt='Category'
                  />
                </div>
                <div className='pro-Categories-detail'>
                  <h2>
                    <Link to='#'>{t('Music and video games.1')}</Link>
                  </h2>
                  <ul className='pro-Categories-meta'>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('TV.1')} &amp; {t('Video Devices.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('TV Accessories.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='#' tabIndex={0}>
                        {t('Air Conditioner.1')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/shop-page1' tabIndex={0}>
                        {t('See all categories.1')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default Papular_Categories_Slider;
