import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const Blog_Slider = () => {
  const { t } = useTranslation();

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <section className='pro-content blog-content'>
        <div className='container'>
          {/* heading */}
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-12 '>
              <div className='pro-heading-title'>
                <h2> {t('Latest Blog.1')}</h2>
                <p>
                  {t(
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.1'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 p-0'>
              {/* <div className='blog-carousel-js row'> */}
              <Slider className='carousel' {...settings}>
                <div className='slide pro-blog'>
                  <div className='pro-thumb'>
                    <div className='blog-date'>
                      30
                      <small>{t('Dec.1')}</small>
                    </div>
                    <img
                      className='img-fluid swipe-to-top'
                      src='assets/images/blogs/blog_post_1.jpg'
                      alt='Blog'
                    />
                  </div>
                  <div className='pro-detail'>
                    <h2>
                      <Link to='/blog-page1'>
                        {t('Woman wearing Silver-color ring necklaces.1')}
                      </Link>
                    </h2>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, eiusmod tempor incdidunt ut labore et aliqua.1'
                      )}
                    </p>
                    <Link
                      to='/blog-page1'
                      className='pro-readmore'
                      tabIndex={0}
                    >
                      {t('Read more.1')}
                    </Link>
                  </div>
                </div>
                <div className='slide pro-blog'>
                  <div className='pro-thumb'>
                    <div className='blog-date'>
                      18
                      <small>{t('aug.1')}</small>
                    </div>
                    <img
                      className='img-fluid'
                      src='assets/images/blogs/blog_post_2.jpg'
                      alt='Blog'
                    />
                  </div>
                  <div className='pro-detail'>
                    <h2>
                      <Link to='/blog-page1'>
                        {t('White gold engagement rings for couples.1')}
                      </Link>
                    </h2>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, tempor incdidunt ut labore et dolore.1'
                      )}
                    </p>
                    <Link
                      to='/blog-page1'
                      className='pro-readmore'
                      tabIndex={0}
                    >
                      {t('Read more.1')}
                    </Link>
                  </div>
                </div>
                <div className='slide pro-blog'>
                  <div className='pro-thumb'>
                    <div className='blog-date'>
                      01
                      <small>{t('Sep.1')}</small>
                    </div>
                    <img
                      className='img-fluid'
                      src='assets/images/blogs/blog_post_3.jpg'
                      alt='Blog'
                    />
                  </div>
                  <div className='pro-detail'>
                    <h2>
                      <Link to='/blog-page1'>
                        {t('Shallow focus photo of gold-colored ring.1')}
                      </Link>
                    </h2>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, eiusmod tempor incdidunt ut dolor magna aliqua.1'
                      )}
                    </p>
                    <Link
                      to='/blog-page1'
                      className='pro-readmore'
                      tabIndex={0}
                    >
                      {t('Read more.1')}
                    </Link>
                  </div>
                </div>
                <div className='slide pro-blog'>
                  <div className='pro-thumb'>
                    <div className='blog-date'>
                      22
                      <small>{t('Oct.1')}</small>
                    </div>
                    <img
                      className='img-fluid'
                      src='assets/images/blogs/blog_post_4.jpg'
                      alt='Blog'
                    />
                  </div>
                  <div className='pro-detail'>
                    <h2>
                      <Link to='/blog-page1'>
                        {t('Pearl necklace jewelry treasure box.1')}
                      </Link>
                    </h2>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, eiusmod tempor labore et dolore magna aliqua.1'
                      )}
                    </p>
                    <Link
                      to='/blog-page1'
                      className='pro-readmore'
                      tabIndex={0}
                    >
                      {t('Read more.1')}
                    </Link>
                  </div>
                </div>
                <div className='slide pro-blog'>
                  <div className='pro-thumb'>
                    <div className='blog-date'>
                      22
                      <small>{t('Jan.1')}</small>
                    </div>
                    <img
                      className='img-fluid'
                      src='assets/images/blogs/blog_post_5.jpg'
                      alt='Blog'
                    />
                  </div>
                  <div className='pro-detail'>
                    <h2>
                      <Link to='/blog-page1'>
                        {t('Woman wearing silver colore jewelery.1')}
                      </Link>
                    </h2>
                    <p>
                      {t(
                        'Lorem ipsum, sed do eiusmod tempor incdidunt ut labore et dolore.1'
                      )}
                    </p>
                    <Link
                      to='/blog-page1'
                      className='pro-readmore'
                      tabIndex={0}
                    >
                      {t('Read more.1')}
                    </Link>
                  </div>
                </div>
                <div className='slide pro-blog'>
                  <div className='pro-thumb'>
                    <div className='blog-date'>
                      12
                      <small>{t('Mar.1')}</small>
                    </div>
                    <img
                      className='img-fluid'
                      src='assets/images/blogs/blog_post_6.jpg'
                      alt='Blog'
                    />
                  </div>
                  <div className='pro-detail'>
                    <h2>
                      <Link to='/blog-page1'>
                        {t('Gold bangle is top of the engagement band.1')}
                      </Link>
                    </h2>
                    <p>
                      {t(
                        'Lorem ipsum dolor sit amet, sed do eiusmod tempor incdidun dolore magna aliqua.1'
                      )}
                    </p>
                    <Link
                      to='/blog-page1'
                      className='pro-readmore'
                      tabIndex={0}
                    >
                      {t('Read more.1')}
                    </Link>
                  </div>
                </div>
              </Slider>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Blog_Slider;
